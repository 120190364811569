import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Enrollment } from '../models/enrollment';
import { EnrollmentStatus } from '../models/enrollment-status';
import { PaymentResponse } from '../models/payment-response';
import { PaymentRequest, PaymentRequestSepaDirectDebit } from '../models/payment-request';
import { EnrollmentInfo } from '../models/enrollment-info';

@Injectable()
export class RegistrationService {
    ENROLLMENT_KEY = 'tempEnrollment';

    constructor(private http: HttpClient) {}

    EnrollUser(enrollment: Enrollment): Observable<EnrollmentStatus> {
        this.Log('Start enrollUser', enrollment.toJson());

        if (enrollment instanceof EnrollmentInfo) {
            enrollment = enrollment.toEnrollment();
        }

        const url = environment.apiRoot + 'v1/customer/new';

        return this.http.post<EnrollmentStatus>(url, enrollment);
    }

    EditEnrollUser(enrollment: Enrollment, enrollmentStatus: EnrollmentStatus): Observable<Object> {
        this.Log('Start editEnrollUser', enrollment.toJson());

        if (enrollment instanceof EnrollmentInfo) {
            enrollment = enrollment.toEnrollment();
        }

        const url =
            environment.apiRoot +
            'v1/customer/edit' +
            '?customerId=' +
            enrollmentStatus.customerId +
            '&hash	=' +
            enrollmentStatus.hash;
        return this.http.post<Enrollment>(url, enrollment);
    }

    CreatePaymentUrl(request: PaymentRequest): Observable<PaymentResponse> {
        this.Log('CreatePaymentUrl', request.ToJson());
        const url = environment.apiRoot + 'v1/buckaroo/createpaymenturl';

        return this.http.post<PaymentResponse>(url, request);
    }

    PaySepaDirectDebit(request: PaymentRequestSepaDirectDebit, options?: object): Observable<object> {
        this.Log('PaySepaDirectDebit', request.ToJson());
        const url = environment.apiRoot + 'v1/buckaroo/PaySepa';

        return this.http.post<PaymentResponse>(url, request, options);
    }

    GetEnrollment(customerId: string, hash: string): Observable<EnrollmentInfo> {
        const url = environment.apiRoot + 'v1/customer/GetEnrollment' + '?customerId=' + customerId + '&hash=' + hash;

        return this.http.get<EnrollmentInfo>(url);
    }

    private Log(...params: any[]) {
        if (!environment.production && console.log) {
            let args = Array.prototype.slice.call(params);
            args.unshift('[REGISTRATION_SERVICE]');
            console.log.apply(console, args);
        }
    }
}

import { PaymentMethod } from './payment-method';
export class PaymentRequest {
    customerId: string;
    hash: string;
    paymentMethod: PaymentMethod;
    issuerBIC: string = null;

    constructor(customerId: string, hash: string, paymentmethod: PaymentMethod, issuerBIC: string) {
        this.customerId = customerId;
        this.hash = hash;
        this.paymentMethod = paymentmethod;
        this.issuerBIC = issuerBIC;
    }

    ToJson(): string {
        return JSON.stringify(this);
    }
}

export class PaymentRequestSepaDirectDebit {
    customerId: string;
    hash: string;
    subscriptionId: string;
    iban: string;
    name: string;

    constructor(data: Partial<PaymentRequestSepaDirectDebit>) {
        Object.assign(this, data);
    }

    ToJson(): string {
        return JSON.stringify(this);
    }
}

<div class="appointment-box">
    <h3 class="appointment-box__title mb-3">{{ 'enroll.delivery.choose_pickup_time' | translate }}</h3>
    <h3 class="day-selector__months" *ngIf="days.length">{{ monthNames }} {{ days[days.length - 1].getFullYear() }}</h3>
    <div class="day-selector mb-4">
        <div
            class="day-selector__prev-button"
            (click)="previousWeek()"
            [ngClass]="{ 'day-selector__prev-button--disabled': !canGoBack() }"
        ></div>
        <div class="day-selector__container" (touchstart)="onTouchStart($event)" (touchend)="onTouchEnd($event)">
            <div
                class="day-selector__mover"
                [@dayContainerScroll]="animationState"
                (@dayContainerScroll.done)="onAnimationComplete($event)"
            >
                <div
                    class="day-selector__day"
                    *ngFor="let day of days"
                    (click)="selectDay(day)"
                    [ngClass]="{ 'day-selector__day--active': day.getTime() == selectedDate.getTime() }"
                >
                    {{ 'weekDaysShort.' + day.getDay() | translate }}
                    <span>{{ day.getDate() }}</span>
                </div>
            </div>
        </div>
        <div class="day-selector__next-button" (click)="nextWeek()"></div>
    </div>
    <div class="timeslot-selector">
        <div class="timeslot-selector__container" *ngIf="swapblocks !== null" @fadeLoad>
            <!-- <div class="timeslot-selector__item timeslot-selector__item--now-in-store" *ngIf="store.isOpen && selectedDate.getTime() == today.getTime()"
        [ngClass]="{ 'selected': selectedSwapblock === true }" (click)="selectSwapBlock(true)" data-met="Signup - Delivery details - Now In Store">
        {{ 'enroll.delivery.in_store_now' | translate }}
      </div> -->

            <p class="store-text" *ngIf="swapblocks.length">{{ 'enroll.delivery.ill_come_at' | translate }}:</p>
            <div class="timeslot-selector__slots" *ngIf="swapblocks.length">
                <div
                    class="timeslot-selector__item"
                    *ngFor="let swapblock of swapblocks"
                    (click)="selectSwapBlock(swapblock)"
                    [ngClass]="{ selected: swapblock === selectedSwapblock }"
                >
                    {{ swapblock.startTimeString }} - {{ swapblock.endTimeString }}
                    <span class="timeslot-selector__item-busyness">{{
                        'enroll.delivery.busyness.' + swapblock.workLoad | translate
                    }}</span>
                </div>
            </div>

            <div class="timeslot-selector__message" *ngIf="!swapblocks.length && !closed">
                <div class="timeslot-selector__message-img">
                    <img class="timeslot-selector__message-img-svg" src="/assets/icons/sign.svg" />
                    <p class="timeslot-selector__message-img-copy">{{ 'enroll.delivery.busy' | translate }}</p>
                </div>
                <p class="timeslot-selector__message-copy">{{ 'enroll.delivery.busy_in_store' | translate }}</p>
            </div>

            <div class="timeslot-selector__message" *ngIf="!swapblocks.length && closed">
                <div class="timeslot-selector__message-img">
                    <img class="timeslot-selector__message-img-svg" src="/assets/icons/sign.svg" />
                    <p class="timeslot-selector__message-img-copy">{{ 'enroll.delivery.closed' | translate }}</p>
                </div>
                <p class="timeslot-selector__message-copy">{{ 'enroll.delivery.store_is_closed' | translate }}</p>
            </div>
        </div>

        <div class="timeslot-selector__spinner" *ngIf="swapblocks === null" @fadeLoad>
            <i class="fa fa-spinner fa-spin"></i>
        </div>
    </div>
</div>

import { Directive } from '@angular/core';
import { NG_VALIDATORS, AbstractControl } from '@angular/forms';
import { IbanService } from '../../registration/shared/iban-service/iban.service';

@Directive({
    selector: '[validateIban]',
    providers: [{ provide: NG_VALIDATORS, useExisting: IbanValidatorDirective, multi: true }],
})
export class IbanValidatorDirective {
    constructor(private ibanService: IbanService) {}

    validate(control: AbstractControl): { [key: string]: any } | null {
        return this.ibanService.isValidIBAN(control.value) ? null : { validateIban: { valid: false } };
    }
}

<div class="container-fluid margin-extralarge">
    <div class="row">
        <div class="col-12 col-sm-8 offset-sm-2 col-lg-12 offset-lg-0">
            <h1>{{ 'account.password.change_password' | translate }}</h1>
            <hr class="d-lg-none" />
            <form>
                <fieldset class="margin-extralarge">
                    <form-field-container>
                        <input
                            form-field
                            type="password"
                            [(ngModel)]="oldPassword"
                            name="oldPassword"
                            placeholder="{{ 'account.password.old_password' | translate }}"
                            autocomplete="section-changepassword current-password"
                        />
                    </form-field-container>
                    <app-password-requirement></app-password-requirement>
                </fieldset>
            </form>
            <div class="row">
                <div class="col-12">
                    <button
                        class="btn btn-primary"
                        (click)="changePassword()"
                        [disabled]="!passwordRequirementComponent.isValid() || !oldPassword || loading"
                        data-met="Password reset  - Password changed - CTA"
                    >
                        <span [hidden]="loading">{{ 'account.password.change_password' | translate }}</span>
                        <span [hidden]="!loading"><i class="fa fa-spin fa-spinner"></i></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

import { EnrollmentInfo } from '../models/enrollment-info';
import { EnrollmentStatus } from '../models/enrollment-status';

@Injectable()
export class EnrollmentService {
    ENROLLMENT_KEY = 'tempEnrollment';
    ENROLLMENTSTATUS_KEY = 'enrollmentStatus';

    enrollmentStatus: EnrollmentStatus;

    constructor() {}

    SaveEnrollmentInfo(enrollment: EnrollmentInfo) {
        this.Log('SaveEnrollment');
        localStorage.setItem(this.ENROLLMENT_KEY, JSON.stringify(enrollment));
    }

    LoadEnrollmentInfo(businessUnitCode: string = '', subscriptionCode: string = ''): EnrollmentInfo {
        this.Log('LoadEnrollment', businessUnitCode, subscriptionCode);
        let json = localStorage.getItem(this.ENROLLMENT_KEY);

        if (json) {
            try {
                let savedEnrollment = EnrollmentInfo.fromJson(json);

                if (
                    (subscriptionCode != '' || businessUnitCode != '') &&
                    (subscriptionCode != savedEnrollment.subscriptionTypeCode ||
                        businessUnitCode != savedEnrollment.businessUnitCode)
                ) {
                    localStorage.removeItem('label');
                    this.ClearEnrollment();

                    return new EnrollmentInfo({
                        businessUnitCode: businessUnitCode,
                        subscriptionTypeCode: subscriptionCode,
                    });
                }

                this.Log('Loaded enrollment');
                return savedEnrollment;
            } catch (error) {
                this.Log('Error loading enrollment from storage %s', error);
                localStorage.removeItem(this.ENROLLMENT_KEY);
            }
        }

        return new EnrollmentInfo({
            businessUnitCode: businessUnitCode,
            subscriptionTypeCode: subscriptionCode,
        });
    }

    ClearEnrollment() {
        localStorage.removeItem(this.ENROLLMENT_KEY);
        localStorage.removeItem(this.ENROLLMENTSTATUS_KEY);
        this.enrollmentStatus = null;
    }

    getEnrollmentStatus() {
        if (this.enrollmentStatus) {
            return this.enrollmentStatus;
        }

        let json = localStorage.getItem(this.ENROLLMENTSTATUS_KEY);
        if (!json) {
            return null;
        }

        this.enrollmentStatus = EnrollmentStatus.fromJson(json);
        return this.enrollmentStatus;
    }

    setEnrollmentStatus(enrollmentStatus: EnrollmentStatus) {
        this.Log('SetEnrollmentStatus');
        this.enrollmentStatus = enrollmentStatus;
        localStorage.setItem(this.ENROLLMENTSTATUS_KEY, JSON.stringify(enrollmentStatus));
    }

    private Log(...params: any[]) {
        if (!environment.production && console.log) {
            let args = Array.prototype.slice.call(params);
            args.unshift('[ENROLLMENT_SERVICE]');
            console.log.apply(console, args);
        }
    }
}

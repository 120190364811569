<div class="container-fluid margin-extralarge">
    <div class="row">
        <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">
            <h1 class="d-block d-lg-none">{{ 'account.profile.title' | translate }}</h1>
            <hr class="d-lg-none" />
            <form (ngSubmit)="updateUser(personalDetailsForm)" #personalDetailsForm="ngForm">
                <fieldset class="margin-extralarge">
                    <form-field-container class="account-form">
                        <label>{{ 'enroll.form.streetname' | translate }}</label>
                        <input
                            form-field
                            type="text"
                            placeholder="{{ 'enroll.form.streetname' | translate }}"
                            [(ngModel)]="userDetails.street"
                            name="street"
                            required
                            disabled
                        />
                    </form-field-container>
                    <form-field-container class="account-form" #zipCodeContainer>
                        <label>{{ 'enroll.form.zipcode' | translate }}</label>
                        <input
                            form-field
                            type="text"
                            placeholder="{{ 'enroll.form.zipcode' | translate }}"
                            [(ngModel)]="userDetails.postalCode"
                            name="zipCode"
                            required
                            disabled
                        />
                    </form-field-container>
                    <form-field-container class="account-form">
                        <label>{{ 'enroll.form.housenumber' | translate }}</label>
                        <input
                            form-field
                            type="text"
                            placeholder="{{ 'enroll.form.housenumber' | translate }}"
                            [(ngModel)]="userDetails.houseNumber"
                            name="houseNumber"
                            required
                            disabled
                        />
                    </form-field-container>
                    <form-field-container class="account-form">
                        <label>{{ 'enroll.form.housenumbersuffix' | translate }}</label>
                        <input
                            form-field
                            type="text"
                            [(ngModel)]="userDetails.houseNumberSuffix"
                            name="houseNumberSuffix"
                            disabled
                        />
                    </form-field-container>
                    <form-field-container class="account-form">
                        <label>{{ 'enroll.form.city' | translate }}</label>
                        <input
                            form-field
                            type="text"
                            placeholder="{{ 'enroll.form.city' | translate }}"
                            [(ngModel)]="userDetails.city"
                            name="city"
                            required
                            disabled
                        />
                    </form-field-container>
                    <form-field-container class="account-form">
                        <label>{{ 'account.profile.language' | translate }}</label>
                        <div class="select-container">
                            <select
                                (change)="personalDetailsForm.onSubmit()"
                                name="locale"
                                [(ngModel)]="userDetails.locale"
                                required
                            >
                                <option *ngFor="let locale of locales" value="{{ locale.key }}">
                                    {{ locale.name }}
                                </option>
                            </select>
                        </div>
                    </form-field-container>
                    <form-field-container class="account-form">
                        <label>{{ 'account.profile.country' | translate }}</label>
                        <div class="select-container">
                            <select name="country" disabled [(ngModel)]="userDetails.countryCode" required [disabled]>
                                <option *ngFor="let country of countries" value="{{ country.key }}">
                                    {{ 'countries.' + country.key | translate }}
                                </option>
                            </select>
                        </div>
                    </form-field-container>
                    <form-field-container class="account-form">
                        <label>{{ 'enroll.form.emailaddress' | translate }}</label>
                        <input
                            form-field
                            type="text"
                            placeholder="{{ 'enroll.form.emailaddress' | translate }}"
                            [(ngModel)]="userDetails.email"
                            name="email"
                            disabled
                        />
                    </form-field-container>
                    <form-field-container class="account-form">
                        <label>{{ 'enroll.form.phonenumber' | translate }}</label>
                        <input
                            form-field
                            type="text"
                            placeholder="{{ 'enroll.form.phonenumber' | translate }}"
                            [(ngModel)]="userDetails.phoneNumber"
                            name="phoneNumber"
                            disabled
                        />
                    </form-field-container>
                </fieldset>

                <div *ngIf="futureAddress">
                    <h3>{{ 'relocation.new_address_date_header' | translate }}</h3>
                    <p><span>{{ 'relocation.new_address_date' | translate }}</span> {{ futureAddress.relocationDate | date: 'dd MMMM' }}</p>
                </div>

                <div *ngIf="futureAddress">
                    <h3>{{ 'relocation.new_address' | translate }}</h3>
                    <p>{{ futureAddress.address.street }} {{ futureAddress.address.houseNumber }}</p>
                    <p>{{ futureAddress.address.postalCode }}</p>
                    <p>{{ futureAddress.address.city }}</p>
                </div>
                <div class="row flex-row-reverse no-gutters" *ngIf="!saving">
                    <div class="col-6 col-lg-auto" *ngIf="!futureAddress">
                        <a routerLink="/relocation/address" class="btn btn-primary">
                            {{ 'relocation.cta' | translate }}</a
                        >
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { relocationEvents } from '../../../shared/google-tag-manager/events/relocationGTM';
import {
    AddressDetails,
    RelocationDate,
    RelocationFormDataService,
} from '../../services/subscription-relocation-form-service/subscription-relocation-form-service';

@Component({
    selector: 'app-relocation-confirmation',
    templateUrl: './relocation-confirmation.component.html',
    styleUrls: ['./relocation-confirmation.component.scss'],
})
export class RelocationConfirmationComponent implements OnInit {
    addressDetails: AddressDetails;
    relocationDate: RelocationDate;
    router: Router;
    gtm: RelocationFormDataService['gtm'];

    constructor(relocationFormDataService: RelocationFormDataService, router: Router) {
        this.relocationDate = relocationFormDataService.relocationDate;
        this.addressDetails = relocationFormDataService.addressDetails;
        this.gtm = relocationFormDataService.gtm;
        this.router = router;
    }
    ngOnInit(): void {
        this.gtm(relocationEvents.successStep);
    }

    returnToProfile(): void {
        this.router.navigateByUrl(`profile`);
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AvailableLocale } from '../../shared/models/available-locale';
import { accountTranslateServiceFixer } from '../../shared/translate-service/account-translate-service-fixer';
import { UserDetailsModelService } from '../services/user-details-model-service/user-details-model-service';
import { TranslateService } from '../../shared/translate-service/translate-service';
import { Country, Locale } from '../../shared/models/CountryLanguage';
import { EnvironmentService } from '../../shared/environment-service/environment.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
    subscription: Subscription;
    activeLocale: Locale;
    selectedLocale: string;
    links = {
        swapdesk: '',
        privacy: '',
        jobs: '',
        faq: '',
        appLink: '',
        terms: '',
        cookies: '',
        responsibleDisclosure: '',
    };

    constructor(
        private environmentService: EnvironmentService,
        private translateService: TranslateService,
        private userDetailsModelService: UserDetailsModelService
    ) {
        this.subscription = this.translateService.localeChange$.subscribe((locale) => {
            this.activeLocale = locale;
            this.selectedLocale = locale.toString();
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnInit() {
        this.activeLocale = this.translateService.getLocale();
        this.selectedLocale = this.activeLocale.toString();

        this.userDetailsModelService.getModel().subscribe((userDetails) => {
            if (!sessionStorage.getItem('temporaryLocale')) {
                // this is a hack to fix the locale for the account page
                const locale = `${(Locale.fromString(userDetails.locale)?.language ?? 'en')}-${userDetails.country}`
                this.translateService.changeLocale(accountTranslateServiceFixer(locale));
            }
            this.activeLocale = this.translateService.getLocale();
            this.selectedLocale = this.activeLocale.toString();

            if (userDetails.subscriptions.length > 0 && userDetails.subscriptions[0].businessUnitCountryCultureInfo) {
                this.setSelectedCountry(userDetails.subscriptions[0].businessUnitCountryCultureInfo.substring(3, 5));
            } else {
                this.setSelectedCountry(userDetails.country);
            }
            this.updateCountry();
        });
    }

    private updateCountry() {
        this.environmentService.setCountryKey(this.activeLocale.country);
        const country = this.environmentService.getCountry();

        this.links = {
            swapdesk: country.swapdesk,
            privacy: country.privacy.replace('{0}', this.activeLocale.language),
            terms: country.terms.replace('{0}', this.activeLocale.language),
            faq: country.faq,
            appLink: country.appLink,
            cookies: country.website + '/cookies',
            jobs: country.jobs,
            responsibleDisclosure: country.responsibleDisclosure,
        };
    }

    updateLanguage() {
        this.activeLocale = Locale.fromString(this.selectedLocale);
        this.translateService.changeLocale(this.activeLocale, true);
    }

    get locales(): AvailableLocale[] {
        return this.translateService.getLocales(this.activeLocale.country);
    }

    get currentYear() {
        return new Date().getFullYear();
    }

    private setSelectedCountry(str: string) {
        if (Object.keys(Country).indexOf(str) > -1) {
            this.activeLocale.country = Country[str];
        } else {
            this.activeLocale.country = Country[Object.keys(Country)[0]];
        }
    }
}

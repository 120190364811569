<h2 class="heading">{{ 'resignup.heading' | translate }}</h2>
<p>
    {{ 'resignup.text' | translate }}
</p>
<ul class="list">
    <li *ngFor="let usp of usps">
        <img src="assets/icons/checkmark-green.svg" alt="" class="checkmark" />{{ usp | translate }}
    </li>
</ul>
<a href="{{ reSignupUrl }}" class="btn btn-green">{{ 'resignup.link' | translate }}</a>

<div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 offset-lg-3 col-lg-6">
    <div class="row mb-2">
        <div class="col-12 col-sm-10 offset-sm-1">
            <app-scale start-width="340" start-height="155">
                <div class="animation-wrapper">
                    <div #animationBox class="animation-box">
                        <img #animationTop class="animation-top" src="/assets/animations/redeem-animation/top.svg" />
                        <img
                            #animationBottom
                            class="animation-bottom"
                            src="/assets/animations/redeem-animation/bottom.svg"
                        />
                        <div class="bikecontainer">
                            <img
                                #animationBike
                                class="animation-bike"
                                src="/assets/animations/redeem-animation/bike.svg"
                            />
                        </div>
                    </div>
                </div>
            </app-scale>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-12" *ngIf="!redeemedCredit">
            <h1 class="mb-1 voucher__title--card">{{ 'account.voucher_redeem.redeem_your_code' | translate }}</h1>
            <p class="mb-2">
                {{ 'account.voucher_redeem.enter_your_code' | translate }}
            </p>
            <form-field-container>
                <input
                    class="mb-2"
                    form-field
                    [(ngModel)]="vouchercode"
                    form-field
                    type="text"
                    placeholder="Voucher code"
                />
            </form-field-container>
            <p class="text-danger mb-2" *ngIf="invalidvoucher">{{ 'enroll.giftcard.notvalidretry' | translate }}</p>
            <p class="mb-2" *ngIf="validatedCredit">
                {{ 'enroll.giftcard.credittext' | translate }} {{ validatedCredit | money }}
                {{ 'enroll.giftcard.worth' | translate }}
            </p>
            <button class="btn btn-primary mt-1 voucher__confirm__button" (click)="redeem()">
                {{ 'account.voucher_redeem.redeem_code' | translate }}
            </button>
        </div>
        <div class="col-12" *ngIf="redeemedCredit">
            <h1 class="mb-1">{{ 'account.voucher_redeem.code_redeemed' | translate }}</h1>
            <p>
                {{ 'account.voucher_redeem.we_added' | translate }} {{ redeemedCredit | money }}
                {{ 'account.voucher_redeem.to_your_subscription_balance' | translate }}
            </p>
            <p>{{ 'account.voucher_redeem.this_amount_subtracted' | translate }}</p>
        </div>
    </div>
</div>

// Account repo doesn't support all locales, so we need to hack it

import { Locale } from '../models/CountryLanguage';
import { accountSupportedLocales } from './account-supported-locales';

export const accountTranslateServiceFixer = (locales?: unknown) => {
    if (typeof locales !== 'string' || locales === '') {
        return Locale.fromString('en-GB');
    }

    const checkedLocales = Locale.fromString(locales);

    const isSupported = accountSupportedLocales[checkedLocales.country]
        .map((supportedLocal) => supportedLocal.key)
        .includes(checkedLocales.toString());

    if (isSupported) {
        return checkedLocales;
    }

    if (checkedLocales.language === 'en') {
        return Locale.fromString('en-GB');
    }

    if (checkedLocales.language === 'de') {
        return Locale.fromString('de-DE');
    }

    if (checkedLocales.language === 'fr') {
        return Locale.fromString('fr-FR');
    }
};

export const getLocaleLanguageTag = (locale: string) =>
    Locale.fromString(locale).language === 'en' ? 'en-GB' : locale;
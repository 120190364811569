import { animate, animateChild, group, keyframes, query, state, style, transition, trigger } from '@angular/animations';

export const dropdown = trigger('dropdown', [
    transition('0 <=> 1', [group([query('@appearDropdown, @appearDropdownInner', animateChild())])]),
]);

export const appearDropdown = trigger('appearDropdown', [
    state(
        '0',
        style({
            opacity: 0,
            maxHeight: 0,
        })
    ),
    state(
        '1',
        style({
            opacity: 1,
            maxHeight: 'calc(100vh - 100px)',
            overflowY: 'scroll',
            pointerEvents: 'all',
        })
    ),
    transition('0 => 1', [
        animate(
            '0.2s 0.1s ease-in-out',
            keyframes([
                style({
                    opacity: 1,
                }),
                style({
                    maxHeight: 'calc(100vh - 100px)',
                }),
                style({
                    overflowY: 'scroll',
                }),
            ])
        ),
    ]),
    transition('1 => 0', [
        animate(
            '0.2s 0.15s ease-in-out',
            keyframes([
                style({
                    opacity: 1,
                }),
                style({
                    maxHeight: '0',
                    opacity: 1,
                    pointerEvents: 'none',
                }),
                style({
                    opacity: 0,
                }),
            ])
        ),
    ]),
]);

export const appearDropdownInner = trigger('appearDropdownInner', [
    state(
        '0',
        style({
            opacity: 0,
        })
    ),
    state(
        '1',
        style({
            opacity: 1,
        })
    ),
    transition('0 => 1', [animate('0.1s 0.2s ease-in-out')]),
    transition('1 => 0', [animate('0.1s ease-in-out')]),
]);

<ul class="bike-selection" *ngIf="!loading && subscriptions.length > 0">
    <li
        *ngFor="let subscription of subscriptions"
        [ngClass]="{ selected: subscription?.uuid === selectedSubscription?.uuid }"
        (click)="selectSubscription(subscription)"
    >
        <div class="bike__subscription__record">
            <div>
                <p class="bike__subscription__location">{{ subscription.businessUnitName }}</p>
                <p class="bike__subscription__metadata">
                    {{ 'account.subscription.bike' | translate }}: {{ subscription.assets[0]?.code }}
                </p>
            </div>
        </div>
        <img src="/assets/bikes/account/{{ getBikeImageString(subscription) }}.png" alt="" />
    </li>
</ul>

import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class RegistrationComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}

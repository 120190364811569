import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../translate-service/translate-service';

@Pipe({
    name: 'money',
    pure: false,
})
export class MoneyPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(value: string, currency = this.translateService.getCurrencyISOCode()): any {
        if (value == 'undefined' || value == null || value.length == 0) {
            return '';
        }

        let num = +value.toString().replace(',', '.');

        value = num.toLocaleString(this.translateService.getLocale().toString(), {
            style: 'currency',
            currency: currency,
        });

        if (num == Math.floor(num)) {
            return value.replace(/([,.])(00)([^\d,.]*$)/, '$1-$3');
        }

        return value;
    }
}

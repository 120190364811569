export enum Language {
    nl = 'nl',
    de = 'de',
    da = 'da',
    fr = 'fr',
    en = 'en',
    it = 'it',
    es = 'es',
    ca = 'ca',
}

export enum Country {
    NL = 'NL',
    BE = 'BE',
    DE = 'DE',
    DK = 'DK',
    FR = 'FR',
    IT = 'IT',
    GB = 'GB',
    ES = 'ES',
    AT = 'AT',
}

export enum NativeCountryNames {
    NL = 'Nederland',
    BE = 'Belgie',
    EN = 'United States',
    DE = 'Deutschland',
    DK = 'Danmark',
    FR = 'France',
    IT = 'Italia',
    GB = 'United Kingdom',
    ES = 'España',
    AT = 'Österreich',
}

export enum SpokenLanguages {
    nl = 'Nederlands',
    be = 'Nederlands',
    de = 'Deutsch',
    en = 'English',
    da = 'Dansk',
    fr = 'Français',
    it = 'Italiano',
    es = 'Español',
    ca = 'Catalan',
}

export enum CountryFlag {
    NL = '🇳🇱',
    BE = '🇧🇪',
    DE = '🇩🇪',
    DK = '🇩🇰',
    FR = '🇫🇷',
    IT = '🇮🇹',
    GB = '🇬🇧',
    ES = '🇪🇸',
    AT = '🇦🇹',
}

export let CountryLanguages = {
    [Country.NL]: [Language.nl, Language.en],
    [Country.BE]: [Language.nl, Language.fr, Language.en],
    [Country.DE]: [Language.de, Language.en],
    [Country.DK]: [Language.da, Language.en],
    [Country.FR]: [Language.fr, Language.en],
    [Country.IT]: [Language.it, Language.en],
    [Country.GB]: [Language.en],
    [Country.ES]: [Language.es, Language.ca, Language.en],
    [Country.AT]: [Language.de, Language.en],
};

export class Locale {
    country: Country;
    language: Language;

    constructor(data: Partial<Locale>) {
        Object.assign(this, data);
    }

    static fromString(locale?: string): Locale {
        let parts = locale.toUpperCase().split(/[-_]/);
        let language: Language, country: Country;

        if (parts.length == 2) {
            language = <Language>parts[0].toLowerCase();
            country = <Country>parts[1];

            if (Object.keys(Country).indexOf(country) == -1) {
                country = <Country>Object.keys(Country)[0];
            }
            if (Object.keys(Language).indexOf(language) == -1) {
                language = CountryLanguages[country][0];
            }
        } else {
            country = <Country>parts[0];
            if (Object.keys(Country).indexOf(country) == -1) {
                country = <Country>Object.keys(Country)[0];
            }
            language = CountryLanguages[country][0];
        }

        return new Locale({
            language: Language[language],
            country: Country[country],
        });
    }

    public toString(): string {
        return this.language + '-' + this.country;
    }
}

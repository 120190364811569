export enum SubscriptionStatus {
    Unknown = 0,
    New = 1,
    DeliveryScheduled = 2,
    DeliveryComplete = 3,
    PickupScheduled = 4,
    PickedUp = 5,
    WaitingForPayment = 6,
    RequiresPlanning = 7,
    Pickup = 8,
    Relocation = 9,
    RelocationDeliveryScheduled = 10,
    RelocationDeliveryCompleted = 11,
    RelocationPickupCompleted = 12,
    RelocationCompleted = 13,
    NoBike = 14,
}

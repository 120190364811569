import { Component, OnInit } from '@angular/core';
import { Router, Event } from '@angular/router';
import { NavigationEnd } from '@angular/router';
import { CookieConsentService } from './shared/cookie-consent-service/cookie-consent-service';
import { GtmService } from './shared/google-tag-manager/gtm-service';
import { OverlayService } from './ui/services/overlay.service';
import { FacebookPixelService } from './shared/facebook-pixel-service/facebook-pixel.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    title = 'app';

    overlayActive = false;

    constructor(
        private cookieConsentService: CookieConsentService,
        private overlayService: OverlayService,
        private router: Router,
        private gtm: GtmService,
        private fbPixelService: FacebookPixelService
    ) {}

    ngOnInit() {
        this.cookieConsentService.init();
        this.fbPixelService.init();
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.gtm.initialDataLayer();
            }
        });

        this.overlayService.getOverlay$().subscribe((active) => {
            this.overlayActive = active;
        });
    }
}

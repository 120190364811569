<div class="container-fluid">
    <div class="row">
        <div class="col-12 col-sm-6 col-md-4 order-1 margin-extralarge">
            <h2>{{ 'account.footer.contact' | translate }}</h2>
            <p class="contact-links">
                <a href="tel:{{ links.swapdesk }}"
                    ><i class="fa fa-phone" aria-hidden="true"></i> {{ links.swapdesk }}</a
                >
                <a href="https://www.facebook.com/Swapfiets/"
                    ><i class="fa fa-facebook-square" aria-hidden="true"></i> Facebook</a
                >
                <a href="mailto:info@swapfiets.com" class="d-block"
                    ><i class="fa fa-envelope-o" aria-hidden="true"></i> info@swapfiets.com</a
                >
            </p>
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-4 contact margin-extralarge order-3 order-sm-2 order-xl-3">
            <div class="row mb-4">
                <div class="col-12">
                    <h3 class="mb-2">{{ 'enroll_success.download_the_app' | translate }}</h3>
                </div>
                <div class="row">
                    <a class="col-6" href="https://itunes.apple.com/nl/app/swapfiets/id1330923084?mt=8">
                        <img width="120px" class="app-icon" src="/assets/apple-appstore-badge.png" />
                    </a>
                    <a class="col-6" href="https://play.google.com/store/apps/details?id=com.swapfiets&hl=nl_NL">
                        <img width="140px" class="app-icon" src="/assets/googleplay.png" />
                    </a>
                </div>
            </div>
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-4 contact margin-extralarge order-3 order-sm-2 order-xl-3">
            <h2>Swapfiets</h2>
            <div class="row">
                <div class="col-6 col-lg-4">
                    <ul>
                        <li><a href="{{ links.jobs }}">Jobs</a></li>
                    </ul>
                </div>
                <div class="col-6 col-lg-4">
                    <ul>
                        <li><a href="{{ links.faq }}">FAQ</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div
            class="
                col-12 col-sm-6 col-md-4 col-lg-4
                country
                margin-extralarge
                text-left text-lg-right
                order-4 order-sm-3 order-xl-4
            "
        >
            <div class="text-left">
                <h2>{{ 'account.footer.country' | translate }}</h2>
                <p>
                    {{ 'account.footer.speak' | translate }}
                    <select class="language-selector" [(ngModel)]="selectedLocale" (change)="updateLanguage()">
                        <option *ngFor="let locale of locales" value="{{ locale.key }}">{{ locale.name }}</option>
                    </select>
                </p>
            </div>
        </div>

        <div class="col-12 col-sm-6 col-md-2 social-media margin-extralarge order-5">
            <h2 class="d-block d-md-none">Follow Swapfiets</h2>
            <a href="https://www.instagram.com/swapfiets/"><i class="fa fa-instagram" aria-hidden="true"></i></a>
            <a href="https://www.facebook.com/Swapfiets/"><i class="fa fa-facebook-square" aria-hidden="true"></i></a>
            <a href="https://www.youtube.com/channel/UCqgUzocrf2B2PC1WL5f-eog"
                ><i class="fa fa-youtube" aria-hidden="true"></i
            ></a>
            <a href="https://twitter.com/swapfiets"><i class="fa fa-twitter" aria-hidden="true"></i></a>
        </div>
        <div class="col-12 col-md-6 policies margin-small order-6 order-md-7">
            <a [attr.href]="links.terms">{{ 'account.footer.terms_and_conditions' | translate }}</a>
            <a [attr.href]="links.privacy">{{ 'account.footer.privacy' | translate }}</a>
            <a target="_blank" href="{{ links.responsibleDisclosure }}">{{
                'account.footer.responsibleDisclosure' | translate
            }}</a>
            <a [attr.href]="links.cookies">{{ 'account.footer.cookie_policy' | translate }}</a>
        </div>

        <div class="col-12 col-md-4 copyright margin-small text-left text-lg-center order-7 order-md-6">
            &copy; {{ currentYear }} Swapfiets. {{ 'account.footer.all_rights_reserved' | translate }}
        </div>
    </div>
</div>

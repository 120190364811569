import { accountTranslateServiceFixer } from '../translate-service/account-translate-service-fixer';
import { Subscription } from './subscription';

export class UserDetails {
    street: string;
    zipCode: string;
    houseNumber: string;
    houseNumberSuffix: string;
    city: string;
    country: string;
    email: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    lastNamePrefix: string;
    customerUserId: string;
    locale: string;
    subscriptions: Subscription[];

    constructor(data: Partial<UserDetails>) {
        Object.assign(this, data);

        this.locale = accountTranslateServiceFixer(data.locale).toString();

        for (let i = 0; i < this.subscriptions.length; i++) {
            this.subscriptions[i] = new Subscription(this.subscriptions[i]);
        }
    }
}

import { animate, animateChild, group, keyframes, query, state, style, transition, trigger } from '@angular/animations';

const animationTiming = 0.3;
const mainAnimationEasing = `${animationTiming}s ease-in-out`;
const middleBarAnimation = `0.01s ${animationTiming / 2}s ease-in-out`;

/***
 * @description Triggers the multi animation
 */
export const hamburgerAnimation = trigger('hamburger', [
    transition('0 <=> 1', [group([query('@borderTop, @borderBottom, @borderMiddle', animateChild())])]),
]);

/***
 * @description animation top bar in hamburger
 */
export const borderTop = trigger('borderTop', [
    state(
        '0',
        style({
            transform: 'translateY(-6px)',
        })
    ),
    state(
        '1',
        style({
            transform: 'translateY(2px) rotate(45deg)',
            backgroundColor: '#fff',
        })
    ),
    transition(
        '0 => 1',
        animate(
            mainAnimationEasing,
            keyframes([
                style({ transform: 'translateY(-6px)' }),
                style({ transform: 'translateY(2px)', backgroundColor: '#fff' }),
                style({ transform: 'translateY(2px) rotate(45deg)' }),
            ])
        )
    ),
    transition(
        '1 => 0',
        animate(
            mainAnimationEasing,
            keyframes([
                style({ transform: 'translateY(2px) rotate(45deg)' }),
                style({ transform: 'translateY(2px)' }),
                style({ transform: 'translateY(-6px)' }),
            ])
        )
    ),
]);

/***
 * @description animation bottom bar in hamburger
 */
export const borderBottom = trigger('borderBottom', [
    state(
        '0',
        style({
            transform: 'translateY(6px)',
        })
    ),
    state(
        '1',
        style({
            transform: 'translateY(-2px) rotate(-45deg)',
            backgroundColor: '#fff',
        })
    ),
    transition(
        '0 => 1',
        animate(
            mainAnimationEasing,
            keyframes([
                style({ transform: 'translateY(6px)' }),
                style({ transform: 'translateY(-2px)', backgroundColor: '#fff' }),
                style({ transform: 'translateY(-2px) rotate(-45deg)' }),
            ])
        )
    ),
    transition(
        '1 => 0',
        animate(
            mainAnimationEasing,
            keyframes([
                style({ transform: 'translateY(-2px) rotate(-45deg)' }),
                style({ transform: 'translateY(-2px)' }),
                style({ transform: 'translateY(6px)' }),
            ])
        )
    ),
]);

/***
 * @description animation middle bar in hamburger
 */
export const borderMiddle = trigger('borderMiddle', [
    state(
        '0',
        style({
            opacity: 1,
        })
    ),
    state(
        '1',
        style({
            opacity: 0,
        })
    ),
    transition('0 => 1', animate(middleBarAnimation)),
    transition(
        '1 => 0',
        animate(
            middleBarAnimation,
            keyframes([style({ opacity: 0 }), style({ opacity: 1, backgroundColor: '#fff' }), style({ opacity: 1 })])
        )
    ),
]);

export const SwapType = {
    Unknown: 'Unknown',
    Exchange: 'Exchange',
    OnSiteRepair: 'OnSiteRepair',
    Missing: 'Missing',
    Found: 'Found',
    FirstDelivery: 'FirstDelivery',
    Delivery: 'Delivery',
    Pickup: 'Pickup',
    // LostKey = 9,
    // [Obsolete("Not in use. For ActionTheft, use ActionEntityType=Theft (and SwapType=Unknown) instead of ActionEntityType=Swap")]
    Theft: 'Theft',
    MissingAndEnd: 'MissingAndEnd',
    // Relocation = 12,
    // [Obsolete("Replaced by Pickup (new subscription on relocation)")]
    // RelocationPickup = 13,
    // [Obsolete("Replaced by FirstDelivery (new subscription on relocation)")]
    // RelocationDelivery = 14,
    // [Obsolete("Replaced by FirstDelivery (one bike per subscription)")]
    ExtraDelivery: 'ExtraDelivery',
    NoBike: 'NoBike',
    NoBikeAndEnd: 'NoBikeAndEnd',
    UpgradeDowngrade: 'UpgradeDowngrade',
    Task: 'Task',
} as const;

export type SwapType = typeof SwapType[keyof typeof SwapType];

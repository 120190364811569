import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ComponentFactoryResolver,
    OnDestroy,
    Type,
    ComponentRef,
    ViewContainerRef,
} from '@angular/core';
import { PopupServiceDirective } from '../popup-service/popup-service.directive';
import { AbstractPopupDirective } from '../abstract-popup/abstract-popup.directive';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-popup',
    templateUrl: './popup-host.component.html',
    styleUrls: ['./popup-host.component.scss'],
})
export class PopupHostComponent implements OnInit, OnDestroy {
    display: boolean = false;
    shouldFadeIn: boolean | undefined;
    shouldHideOnOverlayClick: boolean | undefined;
    componentRef: ComponentRef<AbstractPopupDirective>;
    closeDialogSubscription: Subscription;

    @Input() component: Type<AbstractPopupDirective>;
    @ViewChild('popupContainer', { read: ViewContainerRef, static: true }) popupTemplateHost: ViewContainerRef;

    constructor(
        private popupService: PopupServiceDirective,
        private componentFactoryResolver: ComponentFactoryResolver
    ) {}

    ngOnInit(): void {
        this.popupService.show.subscribe((arg) => {
            if (this.display) {
                this.hide();
            }
            if (arg.config) {
                this.shouldFadeIn = arg.config.shouldFadeIn;
                this.shouldHideOnOverlayClick = arg.config.shouldHideOnOverlayClick;
            }
            this.loadComponent(arg.componentType, arg.params);
            this.show();
        });
    }

    ngOnDestroy() {
        this.hide();
    }

    show() {
        this.display = true;
    }

    onOverlayClick() {
        if (this.shouldHideOnOverlayClick) {
            this.hide();
        }
    }

    hide() {
        this.display = false;
        this.closeDialogSubscription.unsubscribe();
        this.componentRef.destroy();
    }

    loadComponent(componentType: Type<AbstractPopupDirective>, params: any) {
        let componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
        let viewContainerRef = this.popupTemplateHost;
        viewContainerRef.clear();

        this.componentRef = viewContainerRef.createComponent(componentFactory);
        this.componentRef.instance.params = params;
        this.closeDialogSubscription = this.componentRef.instance.closeDialog.subscribe((event) => {
            this.hide();
        });
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from '../../../../shared/models/subscription';
import { Router } from '@angular/router';

interface AccountMenuItem {
    label: string;
    id: string;
    link: string[];
}

@Component({
    selector: 'app-subscription-menu',
    templateUrl: './subscription-menu.component.html',
    styleUrls: ['./subscription-menu.component.scss'],
})
export class SubscriptionMenuComponent implements OnInit {
    @Input() active: string;

    @Input() subscription: Subscription;

    menu: AccountMenuItem[];

    constructor(private router: Router) {}

    ngOnInit() {
        if (this.subscription) {
            this.menu = [
                {
                    label: 'account.subscription.bike',
                    id: 'bike',
                    link: ['subscriptions', this.subscription.uuid],
                },
                {
                    label: 'account.subscription.change_payment',
                    id: 'changepayment',
                    link: ['subscriptions', this.subscription.uuid, 'changepayment'],
                },
                {
                    label: 'account.voucher_redeem.redeem_gift_card',
                    id: 'voucher',
                    link: ['subscriptions', this.subscription.uuid, 'voucher'],
                },
            ];
        }
    }

    navigate(link) {
        this.router.navigate(link);
    }
}

export const availabilityState = {
    waitingList: 'WaitingList',
    preOrder: 'PreOrder',
    availableForSubscription: 'AvailableForSubscription',
    unavailable: 'Unavailable',
} as const;

export type AvailabilityStateValueType = typeof availabilityState[keyof typeof availabilityState];

export const AvailabilityStateEnum = {
    0: availabilityState.waitingList,
    1: availabilityState.preOrder,
    2: availabilityState.availableForSubscription,
    3: availabilityState.unavailable,
} as const;

export type AvailabilityStateType = keyof typeof AvailabilityStateEnum;

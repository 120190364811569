<section class="navbar navbar--desktop">
    <nav class="container-fluid container navbar__inner">
        <div class="col-lg-2 navbar__desktop__logo">
            <a class="logo" routerLink="/home" data-met="Menu - Home - CTA">
                <img src="assets/logos/swapfiets_blue.svg" />
            </a>
        </div>
        <div class="col-lg-8 navbar__desktop__menu">
            <ul>
                <li>
                    <a
                        class="arrow-link"
                        routerLink="/profile"
                        routerLinkActive="active"
                        data-met="Menu - Profile - CTA"
                        >{{ 'account.header.profile' | translate }}</a
                    >
                </li>
                <li>
                    <a
                        class="arrow-link"
                        routerLink="/subscriptions"
                        [ngClass]="{ active: subscriptionsActive }"
                        #dropdownSubscriptions
                        data-met="Menu - Subscription - CTA"
                        >{{ 'account.header.subscription' | translate }}</a
                    >
                </li>
                <li>
                    <a
                        class="arrow-link has-icon"
                        routerLink="/referral"
                        routerLinkActive="active"
                        data-met="Menu - Referral - CTA"
                        ><img class="menu-link-icon" src="/assets/icons/gift.svg" alt="" />
                        {{ 'account.header.invitefriends_experiment' | translate }}</a
                    >
                </li>
                <li>
                    <a
                        class="arrow-link"
                        href="javascript:"
                        #dropdownPassword
                        (click)="toggleDropdown('password')"
                        data-met="Menu - Password - CTA"
                        >{{ 'account.header.password' | translate }}</a
                    >
                </li>
                <li>
                    <a [href]="appLink" class="arrow-link" data-met="Menu - DownloadTheApp - CTA">{{
                        'enroll_success.download_the_app' | translate
                    }}</a>
                </li>
            </ul>
        </div>
        <div class="col-lg-2 logout__section">
            <a (click)="signout()" class="logout-btn" data-met="Menu - Log out - CTA">{{
                'account.header.sign_out' | translate
            }}</a>
        </div>
    </nav>
</section>

<section class="navbar navbar--mobile" [ngClass]="{ 'navbar--transparent': animationActive || menuOpen }">
    <nav class="container container-fluid">
        <div class="col-10 offset-1">
            <a class="logo" routerLink="/home" data-met="Menu - Home - CTA">
                <img src="assets/logos/swapfiets_blue.svg" />
            </a>
        </div>
        <div class="col-1 mobile__hamburger__wrapper" (click)="menuOpen = !menuOpen; animationActive = true">
            <app-mobile-hamburger [open]="menuOpen"></app-mobile-hamburger>
        </div>
    </nav>
</section>

<app-mobile-menu [appLink]="appLink" (animationDone)="shiftNavbar()" [open]="menuOpen"></app-mobile-menu>

<app-dropdown
    class="header__dropdown--no-padding"
    [ngClass]="{ 'hide-password-dropdown': !passwordDropdown }"
    [heightOffset]="-16"
    [parentRef]="dropdownPassword"
    [open]="passwordDropdown"
>
    <app-change-password></app-change-password>
</app-dropdown>

<app-dropdown
    class="header__dropdown--no-padding"
    [heightOffset]="-16"
    [parentRef]="dropdownSubscriptions"
    [open]="subscriptionsDropdown"
>
    <app-all-subscriptions-drawer></app-all-subscriptions-drawer>
</app-dropdown>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiModule } from '../../ui/ui.module';
import { SharedModule } from '../../shared.module';

import { RouterModule } from '@angular/router';
import { ProfileComponent } from './profile.component';
import { ProfileComponentNL } from './profile-nl/profile.component';
import { ProfileComponentOld } from './profile-old/profile-old.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [CommonModule, FormsModule, UiModule, SharedModule, RouterModule],
    declarations: [ProfileComponent, ProfileComponentNL, ProfileComponentOld],
    exports: [ProfileComponent, ProfileComponentNL, ProfileComponentOld],
})
export class ProfileModule {}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../../../shared/auth-service/auth.service';
import { EnvironmentService } from '../../../shared/environment-service/environment.service';

@Injectable()
export class SubscriptionCancelService {
    constructor(private http: HttpClient, private authService: AuthService, private env: EnvironmentService) {}

    cancelSubscription(subscriptionId: string, remarks: string = ''): Observable<Object> {
        return this.http.post<Object>(`${this.env.apiRoot}v1/subscription/${subscriptionId}/cancel?remarks=`, {});
    }

    undoCancelledSubscription(id: string): Observable<any> {
        return this.http.post(`${this.env.apiRoot}v1/subscription/${id}/canceltermination`, {});
    }
}

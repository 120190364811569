export enum AssetCategory {
    org = 'org',
    dlu = 'dlu',
    ebike = 'ebike',
    ekick = 'ekick',
    escooter = 'escooter',
    'org-one-plus' = 'org-one-plus',
    'pwr-one' = 'pwr-one',
}

export const IsEProduct = (assetCategory: AssetCategory) =>
    [AssetCategory.ebike, AssetCategory.ekick, AssetCategory.escooter, AssetCategory['pwr-one']].includes(
        assetCategory
    );

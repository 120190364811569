import { Component } from '@angular/core';
import { EnvironmentService } from '../environment-service/environment.service';
import { AbstractPopupDirective } from '../abstract-popup/abstract-popup.directive';

@Component({
    selector: 'app-popup-bookingfailed',
    templateUrl: './popup-bookingfailed.component.html',
    styleUrls: ['./popup-bookingfailed.component.scss'],
})
export class PopupBookingfailedComponent extends AbstractPopupDirective {
    constructor(public environmentService: EnvironmentService) {
        super();
    }
}

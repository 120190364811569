import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Locale, Country, Language } from '../models/CountryLanguage';
import { accountSupportedLocales } from './account-supported-locales';
import { accountTranslateServiceFixer } from './account-translate-service-fixer';
import { AvailableLocale } from '../models/available-locale';

@Injectable({
    providedIn: 'root',
})
export class TranslateService {
    private currentLocale: Locale = null;
    private currentProject: LocaliseProject;
    private translationFile: object;
    private getTranslations$: Observable<boolean>;
    private currencyISOCode: string = 'EUR';

    localeChange$ = new Subject<Locale>();

    constructor(private http: HttpClient) {
        if (sessionStorage.getItem('temporaryLocale')) {
            this.currentLocale = accountTranslateServiceFixer(sessionStorage.getItem('temporaryLocale'));
        } else if (localStorage.getItem('language')) {
            this.currentLocale = accountTranslateServiceFixer(localStorage.getItem('language'));
        } else {
            this.currentLocale = new Locale({
                country: Country.GB,
                language: Language.en,
            });
        }
    }

    /**
     * Download a new translationfile for the locale
     * Emits a localechange event
     */
    public initiateLanguage(locale: Locale, project: LocaliseProject): Observable<boolean> {
        if (this.currentLocale == locale && project == this.currentProject) {
            return this.getTranslations$;
        } else if (locale == null || project == null) {
            return;
        }

        this.currentLocale = locale;
        this.currentProject = project;

        const url =
            environment.apiRoot +
            'v1/Lokalise?project=' +
            this.currentProject +
            '&language=' +
            this.currentLocale.language +
            '_' +
            this.currentLocale.country;

        this.getTranslations$ = this.http.get(url).pipe(
            map((data) => {
                this.translationFile = data;
                this.localeChange$.next(locale);
                return true;
            }),
            shareReplay(1)
        );

        return this.getTranslations$;
    }

    /**
     * Load a supplied object as translation file directly. Intended for loading from cache etc
     */
    public loadTranslationFile(file: object, locale: Locale) {
        this.translationFile = file;
        this.currentLocale = locale;
    }

    public translate(key: string, args?: string[]): string {
        let keys = key.split('.');
        let translation = this.translationFile;
        while (keys.length && (translation = translation[keys.shift()])) {}

        if (typeof translation == 'string') {
            let t = (<string>translation).replace(/\\n/g, '<br />');
            return t.replace(/{(\d+)}/g, (match, index) => args[index] || '');
        }
    }

    public getLocales(countryCode: string): AvailableLocale[] {
        return accountSupportedLocales[countryCode];
    }

    public setCurrencyISOCode(currencyISOCode: string): void {
        if (currencyISOCode && currencyISOCode.length > 0) {
            this.currencyISOCode = currencyISOCode;
        }
    }

    public getCurrencyISOCode(): string {
        return this.currencyISOCode;
    }

    public getLocale(): Locale {
        return this.currentLocale;
    }

    public changeLocale(locale: Locale, temporary: boolean = false): void {
        if (!locale) {
            return;
        }

        if (temporary) {
            sessionStorage.setItem('temporaryLocale', locale.toString());
        } else {
            localStorage.setItem('language', locale.toString());
        }

        this.initiateLanguage(locale, this.currentProject).subscribe();
    }
}

export enum LocaliseProject {
    Account = 'Account',
    B2B = 'B2B',
    Voucher = 'Voucher',
}

<div class="container-fluid margin-extralarge">
    <h1>{{ 'relocation.page.title' | translate }}</h1>
    <p>{{ 'relocation.page.intro' | translate }}</p>
    <form class="mt-4" #typeForm="ngForm" (ngSubmit)="submitForm(typeForm)">
        <h3>{{ 'relocation.type.title' | translate }}</h3>
        <div class="col-12 mb-2">
            <input
                type="radio"
                name="relocationType"
                [(ngModel)]="relocationType.type"
                (change)="onKeepBikeSelection()"
                id="keepBike"
                value="keepBike"
                required
            />
            <label for="keepBike">{{ 'relocation.type.radiobutton.current_bike' | translate }}</label>
        </div>

        <div class="col-12 mb-2">
            <input
                type="radio"
                name="relocationType"
                [(ngModel)]="relocationType.type"
                (change)="onNewBikeSelection()"
                id="replaceBike"
                value="replaceBike"
                required
            />
            <label for="replaceBike">{{ 'relocation.type.radiobutton.new_bike' | translate }}</label>
        </div>

        <div class="row relocationWrapper" *ngIf="relocationType.type === 'replaceBike'">
            <p class="newBikeRequestedTitle">
                {{ 'relocation.type.warning.new_bike' | translate }}
            </p>
            <a target="_blank" rel="noopener noreferrer" href="{{ country.faq }}">{{
                'relocation.type.warning.new_bike_support' | translate
            }}</a>
        </div>
        <div class="row col-6 mt-2">
            <button
                type="submit"
                class="btn btn-green"
                [disabled]="!typeForm.form.valid || relocationType.type === 'replaceBike'"
            >
                {{ 'relocation.button.proceed' | translate }}
            </button>
            <button class="btn btn-secondary" (click)="goBack()">{{ 'relocation.button.back' | translate }}</button>
        </div>
    </form>
</div>

import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-subscription-bike-card',
    templateUrl: './subscription-bike-card.component.html',
    styleUrls: ['./subscription-bike-card.component.scss'],
})
export class SubscriptionBikeCardComponent implements OnInit {
    @Input() header: string;

    constructor() {}

    ngOnInit() {}
}

import { Component, Input, OnInit } from '@angular/core';
import { borderBottom, borderMiddle, borderTop, hamburgerAnimation } from './mobile-hamburger.animation';

@Component({
    selector: 'app-mobile-hamburger',
    templateUrl: './mobile-hamburger.component.html',
    animations: [hamburgerAnimation, borderTop, borderBottom, borderMiddle],
    styleUrls: ['./mobile-hamburger.component.scss'],
})
export class MobileHamburgerComponent implements OnInit {
    @Input() open = false;

    constructor() {}

    ngOnInit() {}
}

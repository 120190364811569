import { Component, Input, OnInit } from '@angular/core';

export enum ShapeType {
    Circle = 'circle',
    Square = 'square',
}

export enum ShapeColor {
    white = 'white',
}

@Component({
    selector: 'app-shape',
    templateUrl: './shape.component.html',
    styleUrls: ['./shape.component.scss'],
})
export class ShapeComponent implements OnInit {
    /***
     * @description provide the shape
     */
    @Input() shape: ShapeType;

    /***
     * @description provide the width
     */
    @Input() width: number;

    /***
     * @description provide the height
     */
    @Input() height: number;

    /***
     * @description provide a background color
     */
    @Input() color: ShapeColor = ShapeColor.white;

    /***
     * @description include an icon inside the shape
     */
    @Input() icon: string;

    constructor() {}

    ngOnInit() {}
}

import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { TranslateService } from '../../../shared/translate-service/translate-service';
import { CodiceFiscaleService } from './codice-fiscale.service';

@Directive({
    selector: '[codiceFiscale]',
    providers: [{ provide: NG_VALIDATORS, useExisting: CodiceFiscaleValidatorDirective, multi: true }],
})
export class CodiceFiscaleValidatorDirective implements OnChanges {
    @Input() codiceFiscaleSurname: string;
    @Input() codiceFiscaleName: string;
    @Input() codiceFiscaleDate: string;
    @Input() codiceFiscaleGender: number;

    onChange: () => void;

    constructor(private codiceFiscaleService: CodiceFiscaleService, private translateService: TranslateService) {}

    ngOnChanges(_: SimpleChanges): void {
        if (this.onChange) {
            this.onChange();
        }
    }

    registerOnValidatorChange(fn: () => void): void {
        this.onChange = fn;
    }

    validate(control: AbstractControl): { [key: string]: any } | null {
        let invalidProperties: string[] = [];
        let result = false;

        if (this.codiceFiscaleGender !== 2) {
            result =
                result ||
                this.codiceFiscaleService.isValidCodiceFiscale(
                    control.value,
                    this.codiceFiscaleSurname,
                    this.codiceFiscaleName,
                    new Date(this.codiceFiscaleDate.replace(/-/g, '/')),
                    'M',
                    invalidProperties
                );
        }
        if (this.codiceFiscaleGender !== 1 && !result) {
            invalidProperties = [];
            result = this.codiceFiscaleService.isValidCodiceFiscale(
                control.value,
                this.codiceFiscaleSurname,
                this.codiceFiscaleName,
                new Date(this.codiceFiscaleDate.replace(/-/g, '/')),
                'F',
                invalidProperties
            );
        }

        return result
            ? null
            : {
                  valid: false,
                  customError: `${this.translateService.translate(
                      'account.fiscalcode.not_matching_values'
                  )}.\n\n- ${invalidProperties.join('\n- ')}`,
              };
    }
}

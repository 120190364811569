import { Component, OnInit } from '@angular/core';
import { PopupServiceDirective } from '../shared/popup-service/popup-service.directive';
import { UserDetailsModelService } from './services/user-details-model-service/user-details-model-service';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
    constructor(
        private popupService: PopupServiceDirective,
        private userDetailsModelService: UserDetailsModelService
    ) {}

    ngOnInit() {
        this.userDetailsModelService.getModel().subscribe((userDetails) => {
            if (userDetails.subscriptions.length === 0) {
                this.popupService.showReSignupInfo({ shouldHideOnOverlayClick: true, shouldFadeIn: true });
            }
        });
    }
}

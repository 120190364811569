import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from '../../../shared/models/subscription';
import { GetBikeImageString } from '../../subscriptions/subscription';

@Component({
    selector: 'app-cancel-confirmed',
    templateUrl: './cancel-confirmed.component.html',
    styleUrls: ['./cancel-confirmed.component.scss'],
})
export class CancelConfirmedComponent implements OnInit {
    @Input() subscription: Subscription;

    constructor() {}

    ngOnInit() {}

    public getBikeImageString() {
        return GetBikeImageString(this.subscription);
    }
}

<div class="container-fluid">
    <div class="row">
        <img class="bike-image margin-large" src="/assets/bikes/account/{{ getBikeImageString() }}.png" />
        <div class="col-12">
            <p class="margin-medium">
                {{ 'account.cancel_subscription.subscription_active_till' | translate }}
                <span class="font-weight-bold">{{ subscription.minimunEndDate | date: 'dd/MM/yyyy' }}</span
                >.
                {{ 'account.subscription.go_to_store' | translate }}
                {{ 'account.cancel_subscription.subscription_ultimatum_condition' | translate }}
                <span class="font-weight-bold">{{ subscription.minimunEndDate | date: 'dd/MM/yyyy' }}</span>
                {{ 'account.cancel_subscription.subscription_ultimatum_consequence' | translate }}
            </p>
            <app-subscription-dropoff-locations [subscription]="subscription"></app-subscription-dropoff-locations>
            <div id="usbl-cancel-confirmed-anchor"></div>
        </div>
    </div>
</div>

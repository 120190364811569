import { LocationWorkload } from './location-workload';

export class LocationSwapblock {
    locationId: string;
    date: Date;
    startTime: string;
    endTime: string;
    workLoad: LocationWorkload;
    key: string;

    constructor(data: Partial<LocationSwapblock>) {
        let date = new Date(data.date);
        data.date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        Object.assign(this, data);
    }

    get startTimeString(): string {
        return this.startTime.slice(0, -3);
    }

    get endTimeString(): string {
        return this.endTime.slice(0, -3);
    }
}

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/auth-service/auth.service';
import { EnvironmentService } from '../../shared/environment-service/environment.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-signout',
    templateUrl: './signout.component.html',
    styleUrls: ['./signout.component.scss'],
})
export class SignoutComponent implements OnInit {
    constructor(
        private authService: AuthService,
        private environmentService: EnvironmentService,
        private router: Router
    ) {}

    ngOnInit() {
        this.authService
            .completeSignOut()
            .then(() => {
                this.authService.removeUser();
            })
            .then(() => {
                this.router.navigateByUrl('/home', { replaceUrl: true });
            });
    }
}

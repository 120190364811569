import { AssetCategory } from './asset-category';
import { AssetColor } from './asset-color';
import { AssetStatus } from './asset-status';

export class Asset {
    code: string;
    assetId: string;
    assetCategory: string;
    assetCategoryCode: AssetCategory;
    assetColor: AssetColor;
    assetSize: string;
    assetStatus: AssetStatus;

    constructor(data: Partial<Asset>) {
        Object.assign(this, data);
    }

    get bikeImageString() {
        if (!this.assetCategoryCode || !this.assetColor || !AssetColorImages[this.assetCategoryCode]) {
            return (AssetCategory.org + '-' + AssetColorImages[AssetCategory.org][0]).toLowerCase();
        }

        if (AssetColorImages[this.assetCategoryCode].indexOf(this.assetColor) == -1) {
            return (this.assetCategoryCode + '-' + AssetColorImages[this.assetCategoryCode][0]).toLowerCase();
        }

        return (this.assetCategoryCode + '-' + this.assetColor).toLowerCase();
    }
}

export const AssetColorImages = {
    [AssetCategory.org]: [AssetColor.Black, AssetColor.Blue, AssetColor.Gray, AssetColor.Orange, AssetColor.Red],
    [AssetCategory.dlu]: [AssetColor.Black, AssetColor.Blue, AssetColor.Green, AssetColor.Gray],
    [AssetCategory.ebike]: [AssetColor.Black, AssetColor.Blue, AssetColor.Green],
    [AssetCategory.ekick]: [AssetColor.Black],
    [AssetCategory.escooter]: [AssetColor.Black, AssetColor.White],
    [AssetCategory['org-one-plus']]: [AssetColor.Black],
    [AssetCategory['pwr-one']]: [AssetColor.Green, AssetColor.Black, AssetColor.Red, AssetColor.White, AssetColor.Gray],
};

<div class="container-fluid margin-extralarge">
    <!-- Main -->
    <section class="referral__main row">
        <div class="col-12 col-sm-12 col-md-8 col-lg-5 col-xl-4 offset-md-1 no-horizontal-padding">
            <img class="referral__header-icon" src="/assets/icons/gift.svg" />
            <h2 class="referral__heading">{{ 'referral.earn.title' | translate }}</h2>
            <h3 class="referral__subheading">{{ 'referral.earn.subtitle' | translate }}</h3>
            <p>
                {{ 'referral.earn.description' | translate }}
                <a href="{{ 'referral.conditions.link' | translate }}" target="_blank" class="referral__link">{{
                    'referral.read_conditions' | translate
                }}</a>
            </p>

            <div class="code__container" *ngIf="!errorGettingCode">
                <span (click)="copyText(referrerCode)">{{ referrerCode }}</span>
                <div class="code__buttons-container">
                    <button (click)="copyText(referrerCode)" class="code__button code__button--copy">
                        {{ 'referral.copy' | translate }}
                    </button>
                    <button (click)="openModal()" class="code__button code__button--email">
                        {{ 'referral.email' | translate }}
                    </button>
                </div>
            </div>
            <ng-container *ngIf="errorGettingCode">
                <p class="error-message">{{ 'referral.generation.error' | translate }}</p>
            </ng-container>

            <p class="referral__text-small">{{ 'referral.copyorshare' | translate }}</p>
        </div>
    </section>

    <!-- Steps -->
    <section class="steps row">
        <div class="steps__content col-12 col-md-10 offset-md-1 no-horizontal-padding">
            <h2 class="referral__heading">{{ 'referral.steps.title' | translate }}</h2>

            <div class="row steps__content-row">
                <div class="steps__step col-sm-7 col-md-3">
                    <div class="steps__number">
                        <span>1</span>
                    </div>
                    <p>
                        <strong>{{ 'referral.steps.one.bold' | translate }}</strong
                        ><br />
                        {{ 'referral.steps.one.reg' | translate }}
                    </p>
                </div>

                <div class="steps__step col-sm-7 col-md-3 offset-md-1">
                    <div class="steps__number">
                        <span>2</span>
                    </div>
                    <p>
                        <strong>{{ 'referral.steps.two.bold' | translate }}</strong
                        ><br />
                        {{ 'referral.steps.two.reg' | translate }}
                    </p>
                </div>

                <div class="steps__step col-sm-7 col-md-3 offset-md-1">
                    <div class="steps__number">
                        <span>3</span>
                    </div>
                    <p>
                        <strong>{{ 'referral.steps.three.bold' | translate }}</strong
                        ><br />
                        {{ 'referral.steps.three.reg' | translate }}
                    </p>
                </div>
            </div>
        </div>
    </section>

    <!-- Conditions -->
    <section class="conditions row" #conditions>
        <div class="no-horizontal-padding col-8 col-sm-6 col-md-4 offset-md-1">
            <h2 class="referral__heading" [innerHTML]="'referral.conditions.title' | translate"></h2>
            <p class="conditions__text">{{ 'referral.conditions.description' | translate }}</p>
        </div>
        <div class="conditions__image-wrapper no-horizontal-padding col-md-5 offset-md-1 offset-lg-2">
            <div class="conditions__image"></div>
        </div>
    </section>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Location } from '../models/location';
import { environment } from '../../../../environments/environment';
import { LocationSwapblock } from '../models/location-swapblock';
import { LocationInfo } from '../models/location-info';
import { EnrollmentStatus } from '../models/enrollment-status';

@Injectable({
    providedIn: 'root',
})
export class LocationSwapblockService {
    private readonly SOURCE_SIGNUP = 'SignUp';
    private readonly SWAPTYPE_FIRSTDELIVERY = 6; // SwapType.FirstDelivery

    constructor(private http: HttpClient) {}

    GetLocationSwapblocks(location: Location, date: Date): Observable<LocationInfo> {
        const url = environment.apiRoot + 'v1/available_swapblocks_location';
        const utcDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 ))
          .toISOString()
          .split("T")[0];
        return this.http
            .post<LocationInfo>(url, {
                locationId: location.locationId,
                type: this.SWAPTYPE_FIRSTDELIVERY,
                date: utcDate,
            })
            .pipe(
                map((data: LocationInfo) => {
                    return new LocationInfo(data);
                })
            );
    }

    BookLocationSwapBlock(enrollmentStatus: EnrollmentStatus, swapblock: LocationSwapblock): Observable<any> {
        return this.http.post<any>(environment.apiRoot + 'v1/book_swapblock_location', {
            customerId: enrollmentStatus.customerId,
            customerHash: enrollmentStatus.hash,
            subscriptionId: enrollmentStatus.subscriptionId,
            locationId: swapblock.locationId,
            type: this.SWAPTYPE_FIRSTDELIVERY,
            source: this.SOURCE_SIGNUP,
            date: swapblock.date.toISOString(),
            startTime: swapblock.startTime,
            key: swapblock.key,
        });
    }

    ClearLocationSwapBlock(enrollmentStatus: EnrollmentStatus): Observable<any> {
        return this.http.post<any>(environment.apiRoot + 'v1/clear_swapblock_location', {
            customerId: enrollmentStatus.customerId,
            customerHash: enrollmentStatus.hash,
            subscriptionId: enrollmentStatus.subscriptionId,
        });
    }
}

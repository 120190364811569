<div class="wrapper">
    <main class="main--registration">
        <topbar></topbar>

        <div class="content">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1 class="add-margin">
                            {{ 'enroll_success.hi' | translate }} {{ (getEnrollment$ | async)?.firstname }},<br />

                            <span *ngIf="isBoolean(isKycRequired$ | async)">
                                {{
                                    ((isKycRequired$ | async)
                                        ? 'enroll_success.kyc_title'
                                        : 'enroll_success.title'
                                    ) | translate
                                }}
                            </span>

                            <i class="animated-check">&nbsp;</i>
                        </h1>
                    </div>
                </div>

                <div *ngIf="isKycRequired$ | async" class="row" id="online-id-veirification">
                    <p class="col-12">
                        <span clas="">{{ 'enroll_success.verifai.subHeader' | translate }}</span>
                        {{ 'enroll_success.verifai.paragraph' | translate }}
                    </p>
                    <div class="col-12">
                        <button (click)="startIdVerification()" class="btn btn-orange">
                            <i *ngIf="kycLoading | async" class="fa fa-spin fa-spinner" aria-hidden="true"></i>
                            <span *ngIf="!(kycLoading | async)">{{
                                'enroll_success.verifai.startButton' | translate
                            }}</span>
                        </button>
                    </div>
                </div>

                <div *ngIf="isKycRequired$ | async" class="row">
                    <div class="col-12">
                        <div class="row-split">&nbsp;</div>
                    </div>
                </div>

                <div class="row subscription-overview" *ngIf="subscriptionType">
                    <h3 class="col-12">
                        {{ 'bikes.' + subscriptionType?.assetCategoryCode | translate }}
                        <span *ngIf="subscriptionType?.isStudentOffer">{{ 'enroll.check.student' | translate }}</span>
                        <span *ngIf="subscriptionType?.hasBasket"
                            ><br />{{ 'enroll.check.withbasket' | translate }}</span
                        >
                    </h3>
                    <div class="col-12">
                        <img src="/assets/bikes/{{ subscriptionType?.assetCategoryCode }}.jpg" />

                        <div>
                            <p [ngClass]="{ 'strike-through': isSpecialOfferSubscription }">
                                {{ subscriptionType?.finalRate | money }} {{ 'enroll.check.permonth' | translate }}
                            </p>
                            <p *ngIf="isSpecialOfferSubscription">
                                {{ subscriptionType?.initialRate | money }} {{ 'enroll.check.permonth' | translate }}*
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="row-split">&nbsp;</div>
                    </div>
                </div>

                <div class="row address" *ngIf="(getEnrollment$ | async)?.appointment">
                    <div class="col-12">
                        <h3 class="flex-item">{{ 'enroll_success.your_appointment' | translate }}</h3>
                    </div>
                    <p class="col-12">
                        {{ (getEnrollment$ | async)?.appointment.address.street }}
                        {{ (getEnrollment$ | async)?.appointment.address.houseNumber }}<br />
                        {{ (getEnrollment$ | async)?.appointment.address.postalCode }}
                        {{ (getEnrollment$ | async)?.appointment.address.city }}<br />
                        {{ 'weekDays.' + (getEnrollment$ | async)?.appointment.swapBlock.date.getUTCDay() | translate }}
                        {{ (getEnrollment$ | async)?.appointment.swapBlock.date.getUTCDate() }}
                        {{
                            'months.' + ((getEnrollment$ | async)?.appointment.swapBlock.date.getUTCMonth() + 1)
                                | translate
                        }}<br />
                        {{ 'enroll.check.between' | translate }}:
                        {{ (getEnrollment$ | async)?.appointment.swapBlock.startTime.slice(0, 5) }} -
                        {{ (getEnrollment$ | async)?.appointment.swapBlock.endTime.slice(0, 5) }}
                    </p>
                </div>

                <div class="row" *ngIf="(getEnrollment$ | async) && !(getEnrollment$ | async).appointment">
                    <div *ngIf="subscriptionType" class="col-12">
                        <p>{{ 'enroll_success.delivery' | translate }}</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="row-split">&nbsp;</div>
                    </div>
                </div>

                <div class="row address">
                    <div class="col-12">
                        <h3 class="flex-item">{{ 'enroll_success.questions' | translate }}</h3>
                    </div>
                    <p class="col-12">
                        {{ 'enroll_success.questions_about_order' | translate }}
                        <a href="{{ getFAQ() }}">{{ 'enroll_success.faq' | translate }}</a>
                        {{ 'enroll_success.or_contact_us_at' | translate }}
                        <a href="tel:{{ getSwapdesk() }}">{{ getSwapdesk() }}</a
                        >.
                    </p>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="row-split">&nbsp;</div>
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col-12">
                        <h3 class="mb-2">{{ 'enroll_success.download_the_app' | translate }}</h3>
                    </div>
                    <a class="col-6" href="https://itunes.apple.com/nl/app/swapfiets/id1330923084?mt=8">
                        <img class="app-icon" src="/assets/appstore.png" />
                    </a>
                    <a class="col-6" href="https://play.google.com/store/apps/details?id=com.swapfiets&hl=nl_NL">
                        <img class="app-icon" src="/assets/googleplay.png" />
                    </a>
                </div>
            </div>

            <app-backbutton (click)="goBack()">{{ 'enroll_success.backtothewebsite' | translate }}</app-backbutton>
        </div>
    </main>

    <aside></aside>
</div>

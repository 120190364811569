import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';

@Component({
    selector: 'app-scale',
    templateUrl: './scale.component.html',
    styleUrls: ['./scale.component.scss'],
})
export class ScaleComponent implements OnInit {
    @ViewChild('container', { static: true }) container: ElementRef;

    startWidth: number;
    startHeight: number;

    constructor(private element: ElementRef) {}

    ngOnInit() {
        this.startWidth = this.element.nativeElement.getAttribute('start-width');
        this.startHeight = this.element.nativeElement.getAttribute('start-height');
        this.container.nativeElement.style.width = this.startWidth + 'px';
        this.container.nativeElement.style.height = this.startHeight + 'px';

        this.resize();
    }

    @HostListener('window:resize')
    private resize() {
        let scale = this.element.nativeElement.offsetWidth / this.startWidth;
        this.container.nativeElement.style.transform = 'scale(' + scale + ')';
        this.element.nativeElement.style.height = this.startHeight * scale + 'px';
    }
}

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, shareReplay } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable({
    providedIn: 'root',
})
export class ReferralService {
    token$ = this.http.get<any>(`${environment.frontendAPIRoot}giftcard/referrer`).pipe(shareReplay(1));

    constructor(private http: HttpClient) {}

    getRefferCode() {
        return this.token$;
    }
}

import { Gender } from './gender';

export class Enrollment {
    postalCode: string = null;
    city: string = null;
    street: string = null;
    houseNumber: string = null;
    houseNumberSuffix: string = null;
    country: string = null;
    firstname: string = null;
    lastname: string = null;
    lastnamePrefix: string = null;
    gender: Gender = null;
    height: number = null;
    fiscalcode: string = null;
    email: string = null;
    telephoneNumber1: string = '';
    telephoneNumber2: string = null;
    dateOfBirth: string = null;
    localeLanguageTag: string = 'nl-NL';
    businessUnitCode: string = null;
    subscriptionTypeCode: string = null;
    requestedDelivery: Date = null;
    deliveryRemarks: string = null;
    customerRemarks: string = null;
    studentNumber: string = null;
    studentStartYear: number = null;
    referral: string = null;
    company: string = null;
    expired: boolean = false;
    completed: boolean = false;
    giftCardCode: string = null;
    hasGivenGdprConsentForMarketing: boolean = true;

    constructor(businessUnit: string = '', subscriptionCode = '') {
        this.businessUnitCode = businessUnit;
        this.subscriptionTypeCode = subscriptionCode;
        this.customerRemarks = '';
    }

    static fromJson(json: string): Enrollment {
        let jsonObject = JSON.parse(json);
        let enrollment = new Enrollment();

        Object.keys(jsonObject).forEach(function (key) {
            enrollment[key] = jsonObject[key];
        });

        return enrollment;
    }

    toJson(): string {
        return JSON.stringify(this);
    }

    // Generates random number of required length, without leading zeros
    private rand(length: number) {
        return Math.floor(
            Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1)) + Math.pow(10, length - 1)
        ).toString();
    }

    autoFillTestData() {
        this.firstname = 'TEST CUSTOMER';
        this.lastnamePrefix = Math.random() > 0.5 ? 'van' : '';
        this.lastname = 'Test';
        this.postalCode = this.rand(4) + 'AA';
        this.city = 'TestTown' + this.rand(3);
        this.street = 'TestStreet' + this.rand(3);
        this.houseNumber = this.rand(2);
        this.houseNumberSuffix = Math.random() > 0.5 ? Math.random().toString(36).slice(4, 5) : '';
        this.country = 'NL';
        this.gender = <Gender>Math.ceil(Math.random() * 3);
        this.height = Math.floor(Math.random() * 50 + 150);
        this.fiscalcode = this.rand(11);
        this.email = this.rand(12) + '@swapfiets.com';
        this.telephoneNumber1 = '+00' + this.rand(13);
        this.telephoneNumber2 = null;
        this.dateOfBirth =
            '19' +
            this.rand(2) +
            '-' +
            Math.ceil(Math.random() * 12).toString() +
            '-' +
            Math.ceil(Math.random() * 28).toString();
    }
}

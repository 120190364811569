<div [ngClass]="{ filled: inputElement?.value }">
    <ng-content></ng-content>
    <span class="floating-label">{{ inputElementRef?.nativeElement.getAttribute('placeholder') }}</span>
    <span class="error-message" *ngIf="errorMessage && !inputElement?.errors?.customError">{{
        'enroll.form.error_incorrect' | translate
    }}</span>
    <span class="missing-message" *ngIf="missingMessage">{{ 'enroll.form.error_unfilled' | translate }}</span>
    <span class="custom-error-message" *ngIf="errorMessage && inputElement?.errors?.customError">{{
        inputElement?.errors?.customError
    }}</span>
</div>

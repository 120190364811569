import { Component, OnInit } from '@angular/core';
import { Subscription } from '../../shared/models/subscription';
import { UserDetailsModelService } from '../services/user-details-model-service/user-details-model-service';

@Component({
    selector: 'app-subscriptions',
    templateUrl: './subscriptions.component.html',
    styleUrls: ['./subscriptions.component.scss'],
})
export class SubscriptionsComponent implements OnInit {
    subscriptions: Subscription[] = [];
    selectedMobile: boolean = false;

    constructor(private userDetailsModelService: UserDetailsModelService) {}

    ngOnInit() {
        this.userDetailsModelService.getModel().subscribe((userDetails) => {
            this.subscriptions = userDetails.subscriptions;
        });
    }
}

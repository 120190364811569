import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { RegistrationService } from '../shared/registration-service/registration.service';
import { GiftCardService } from '../../shared/giftcard-service/giftcard.service';
import { SubscriptionType } from '../shared/models/subscription-type';
import { PaymentMethod } from '../shared/models/payment-method';
import { environment } from '../../../environments/environment';
import { Country } from '../../shared/models/country';
import { PopupServiceDirective } from '../../shared/popup-service/popup-service.directive';
import { PaymentRequest, PaymentRequestSepaDirectDebit } from '../shared/models/payment-request';
import { PaymentMethodService } from '../shared/payment-method-service/payment-method.service';
import { SubscriptionTypeService } from '../shared/subscription-type-service/subscription-type.service';
import { EnvironmentService } from '../../shared/environment-service/environment.service';
import { LocationService } from '../shared/location-service/location.service';
import { Location } from '../shared/models/location';
import { StepsService } from '../shared/steps-service/steps.service';
import { LocationSwapblock } from '../shared/models/location-swapblock';
import { LocationSwapblockService } from '../shared/location-swapblock-service/location-swapblock.service';
import { PaymentResponse } from '../shared/models/payment-response';
import { EnrollmentStatus } from '../shared/models/enrollment-status';
import { AppointmentAddress, EnrollmentAppointment, EnrollmentInfo } from '../shared/models/enrollment-info';
import { EnrollmentService } from '../shared/enrollment-service/enrollment.service';
import { PaymentSelectComponent } from '../../shared/payment-select/payment-select.component';
import { TranslateService } from '../../shared/translate-service/translate-service';
import { AssetCategory, IsEProduct } from '../../shared/models/asset-category';
import { GtmService } from '../../shared/google-tag-manager/gtm-service';
import checkoutStepGTM from '../../shared/google-tag-manager/events/checkoutStepGTM';
import { PhoneNumberService } from '../shared/phone-number-service/phone-number.service';
import { PhonePrefix } from '../shared/models/phone-number';
import { Subject } from 'rxjs';
import { shareReplay, map, switchMap } from 'rxjs/operators';
import { KycService } from '../shared/kyc-service/kyc.service';
import { FacebookPixelService } from '../../shared/facebook-pixel-service/facebook-pixel.service';
import { Country as CountryCode, Language } from '../../shared/models/CountryLanguage';
import { getLocaleLanguageTag } from '../../shared/translate-service/account-translate-service-fixer';

@Component({
    selector: 'app-enroll',
    templateUrl: './enroll.component.html',
    styleUrls: ['./enroll.component.scss'],
    animations: [
        trigger('toggleAnimation', [
            state('hide', style({ height: '0' })),
            state('show', style({ height: '*' })),
            transition('hide => show', [animate('.5s ease', style({ height: '*' }))]),
            transition('show => hide', [animate('.5s ease', style({ height: '0' }))]),
        ]),
        trigger('delayedShow', [
            transition(':enter', [style({ opacity: '0' }), animate('0.3s 0.8s ease', style({ opacity: '1' }))]),
        ]),
        trigger('sizeAlertPopUp', [
            transition(':enter', [style({ opacity: '0' }), animate('0.1s 0.1s ease', style({ opacity: '1' }))]),
        ]),
    ],
})
export class EnrollComponent implements OnInit, OnDestroy {
    @ViewChild('mainRegistration', { static: true }) mainRegistration: ElementRef;
    @ViewChild(PaymentSelectComponent)
    paymentSelectComponent: PaymentSelectComponent;

    validated: boolean = false;
    // These are saved in the StartComponent
    businessUnitCode: string = localStorage.getItem('businessUnit');
    subscriptionTypecode: string = localStorage.getItem('subscriptionCode');

    // Regex matches serverside validation
    numberNotZero = '[1-9]{1}[0-9]*';
    lettersDashAndSpace = '[A-Za-zÀ-ÿ-\\s]*';
    lettersNumbersDashAndWhiteSpace = '[A-Za-zÀ-ÿ0-9-\\s]*';
    lettersNumbersDashQuoteDotAndWhiteSpace = "[A-Za-zÀ-ÿ0-9-.'\\s]*";
    lettersNumbersDashAndWhiteSpaceMax12Length = '[A-Za-zÀ-ÿ0-9-\\s]{1,12}';

    label: string;

    steps: string[] = [];
    currentStep: number = 0;
    loading: boolean = false;
    paymentFailed: boolean = false;
    bookingFailed: boolean = false;
    toolTipOpened: boolean = false;
    giftcardToolTipOpened: boolean = false;
    fiscalCodeToolTipOpened: boolean = false;
    phoneNumberPrefix: string;
    phoneNumberBody: string;
    paymentMethodList: Array<PaymentMethod | PaymentMethod[]>;
    dateOfBirth = { day: '', month: '', year: '' };
    needParentPermission: boolean = false;
    invalidvoucher: boolean = false;
    credit: number = null;
    monthNameType: string = 'months';
    private timeOut: any;

    // These values are used for registration
    subscriptionType: SubscriptionType = null;
    enrollment: EnrollmentInfo;
    acceptTermsPrivacy: boolean = false;
    // That should only exists when we offer special discounts
    acceptWinterOfferTermsPrivacy: boolean = false;
    parentPermission: boolean = false;
    missingFields: Array<any> = [];
    errorFields: Array<any> = [];
    environmentService: EnvironmentService;

    // Store selection
    storeList: Location[];
    selectedStore: number;
    selectedFrame: number = 0;
    selectedSwapblock: LocationSwapblock | boolean;
    currentBooking: EnrollmentAppointment;

    constructor(
        private registrationService: RegistrationService,
        private phoneNumberService: PhoneNumberService,
        private giftCardService: GiftCardService,
        private router: Router,
        private translateService: TranslateService,
        private paymentMethodService: PaymentMethodService,
        private popupService: PopupServiceDirective,
        private subscriptionTypeService: SubscriptionTypeService,
        private enrollmentService: EnrollmentService,
        private _environmentService: EnvironmentService,
        private locationService: LocationService,
        private stepsService: StepsService,
        private locationSwapblockService: LocationSwapblockService,
        private gtmService: GtmService,
        private kycService: KycService,
        private fbPixelService: FacebookPixelService
    ) {
        this.environmentService = _environmentService;
    }

    validateSubscription$ = this.subscriptionTypeService
        .ValidateSubscription({
            businessUnitCode: this.businessUnitCode,
            subscriptionCode: this.subscriptionTypecode,
            checkAvailability: true,
        })
        .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    // KYC stuff
    isKycRequired$ = this.validateSubscription$.pipe(
        switchMap((subscriptionType) =>
            this.kycService
                .isRequiredForCustomer(
                    this.enrollmentService.getEnrollmentStatus().customerId,
                    subscriptionType.assetCategoryCode,
                    subscriptionType.country
                )
                .pipe(map((data) => data.isRequired))
        ),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    ngOnInit() {
        let enrollmentInfo = this.enrollmentService.LoadEnrollmentInfo(
            this.businessUnitCode,
            this.subscriptionTypecode
        );

        this.currentBooking = enrollmentInfo.appointment;
        this.enrollment = enrollmentInfo;

        this.loadDateOfBirth();

        let paymentFailed: boolean = this.enrollmentService.getEnrollmentStatus() != null;
        let paymentResume: boolean = localStorage.getItem('paymentResume') == '1';
        this.paymentFailed = paymentFailed || paymentResume;
        localStorage.removeItem('paymentResume');
        if (this.paymentFailed && this.currentBooking) {
            this.startTimer();
        }

        this.validateSubscription$.subscribe(
            (subscriptionType) => {
                this.subscriptionType = subscriptionType;

                this.submitCountryToDL(this.subscriptionType.country);
                this.submitSubscriptionCodeToDL(this.subscriptionTypecode);

                this.locationService
                    .Get(this.subscriptionType.businessUnitId, this.isIntroweekSubscription)
                    .subscribe((stores) => {
                        this.steps = this.stepsService.updateForSubscriptionBusinessunit({
                            assetCategory: this.subscriptionType.assetCategoryCode,
                            disableDelivery: !stores.length,
                        });

                        if (paymentResume) {
                            this.currentStep = this.stepsService.steps.indexOf(this.stepsService.STEP_DELIVERY);
                            if (this.currentStep == -1) {
                                this.currentStep = this.stepsService.steps.indexOf(this.stepsService.STEP_CHECK);
                            }
                        } else if (paymentFailed) {
                            this.currentStep = this.stepsService.steps.indexOf(this.stepsService.STEP_PAYMENT);
                        }

                        this.storeList = stores;

                        if (this.currentBooking) {
                            this.selectedFrame = 1;
                            this.selectedSwapblock = this.currentBooking.swapBlock;
                            this.selectedStore = this.storeList.findIndex((store: Location) => {
                                return store.locationId === this.currentBooking.swapBlock.locationId;
                            });
                        }

                        this.validated = true;
                        this.gtmService.event(
                            checkoutStepGTM({ subscription: this.subscriptionType, stepNumber: this.currentStep })
                        );
                    });

                this.enrollment.country = this.subscriptionType.country;

                this.enrollment.localeLanguageTag =
                    localStorage.getItem('localeLanguageTag') || getLocaleLanguageTag(this.translateService.getLocale().toString());

                this.translateService.setCurrencyISOCode(this.subscriptionType.currencyISOCode);
                this.environmentService.setCountryKey(subscriptionType.country);

                if (this.subscriptionType.finalRate != this.subscriptionType.initialRate) {
                    this.label = localStorage.getItem('label');
                }

                if (!this.enrollment.telephoneNumber1) {
                    this.prefillPhonePrefix();
                } else {
                    this.loadPhoneNumber();
                }

                this.paymentMethodList = this.paymentMethodService.GetPaymentMethodsByCountry(
                    this.subscriptionType.country
                );

                // Custom Introweek 2020 behaviour, auto select store
                if (this.isIntroweekSubscription) {
                    this.setStoreSelected();
                }
            },
            () => {
                this.router.navigateByUrl('/registration/not-found');
            }
        );
    }

    ngOnDestroy() {
        if (this.timeOut) {
            clearTimeout(this.timeOut);
        }
    }

    submitPayment() {
        this.loading = true;

        // Alternate flow for SepaDirectDebit
        if (this.paymentSelectComponent.selectedPaymentMethod == PaymentMethod.SepaDirectDebit) {
            const sepaPaymentRequest = new PaymentRequestSepaDirectDebit({
                iban: this.paymentSelectComponent.sepaDirectDebitIban,
                name: this.paymentSelectComponent.sepaDirectDebitName,
                customerId: this.enrollmentService.getEnrollmentStatus().customerId,
                hash: this.enrollmentService.getEnrollmentStatus().hash,
            });

            this.registrationService.PaySepaDirectDebit(sepaPaymentRequest).subscribe(
                (response) => {
                    this.loading = false;
                    this.router.navigateByUrl(
                        '/registration/success?customerId=' +
                            this.enrollmentService.getEnrollmentStatus().customerId +
                            '&customerHash=' +
                            this.enrollmentService.getEnrollmentStatus().hash
                    );
                },
                (error) => {
                    console.log('Error getting payment url: ', error);
                    this.popupService.showPopupRegistrationGeneral();
                    this.loading = false;
                }
            );
            return;
        }

        const paymentRequest = new PaymentRequest(
            this.enrollmentService.getEnrollmentStatus().customerId,
            this.enrollmentService.getEnrollmentStatus().hash,
            this.paymentSelectComponent.selectedPaymentMethod,
            this.paymentSelectComponent.issuerBIC
        );

        this.registrationService.CreatePaymentUrl(paymentRequest).subscribe(
            (paymentResponse: PaymentResponse) => {
                this.loading = false;

                if (paymentResponse.paymentError || !paymentResponse.paymentUrl) {
                    this.popupService.showPopupRegistrationGeneral();
                    Sentry.captureException(`Error during payment url creation: ${paymentResponse.paymentError}`);
                } else if (paymentResponse.paymentUrl) {
                    window.location.href = paymentResponse.paymentUrl;
                } else {
                    this.router.navigateByUrl(
                        '/registration/success?customerId=' +
                            this.enrollmentService.getEnrollmentStatus().customerId +
                            '&customerHash=' +
                            this.enrollmentService.getEnrollmentStatus().hash
                    );
                }
            },
            (error) => {
                console.log('Error getting payment url: ', error);
                Sentry.captureException(error);
                this.popupService.showPopupRegistrationGeneral();
                this.loading = false;
            }
        );
    }

    submitDelivery() {
        this.loading = true;

        if (this.selectedSwapblock && this.selectedSwapblock instanceof LocationSwapblock && this.selectedFrame === 1) {
            this.locationSwapblockService
                .BookLocationSwapBlock(this.enrollmentService.getEnrollmentStatus(), this.selectedSwapblock)
                .subscribe(
                    (response) => {
                        this.currentBooking = new EnrollmentAppointment({
                            address: AppointmentAddress.fromLocation(this.storeList[this.selectedStore]),
                            swapBlock: this.selectedSwapblock as LocationSwapblock,
                        });
                        this.submitDeliveryToDL(this.selectedFrame);
                        this.nextStep();
                    },
                    (err) => {
                        console.error(err);
                        this.loading = false;
                        if (err === 400) {
                            this.popupService.showPopupBookingfailed();
                        } else this.popupService.showPopupRegistrationGeneral();
                        this.bookingFailed = true;
                    }
                );
        } else {
            this.submitDeliveryToDL(this.selectedFrame);
            // If this fails, we still want to let the customer continue

            this.locationSwapblockService
                .ClearLocationSwapBlock(this.enrollmentService.getEnrollmentStatus())
                .subscribe(
                    (response) => {
                        this.currentBooking = null;
                        this.nextStep();
                    },
                    (err) => this.nextStep()
                );
        }
    }

    addGiftCard() {
        this.giftCardService
            .AddToSubscription(this.enrollment.giftCardCode.trim(), this.enrollmentService.getEnrollmentStatus())
            .subscribe(
                (data) => {
                    this.credit = data.credit;
                    this.invalidvoucher = false;
                },
                (error) => {
                    this.credit = null;
                    this.invalidvoucher = true;
                }
            );
    }

    get canSubmitForm(): boolean {
        if (this.isEProduct() && this.needParentPermission) {
            return false;
        } else
            return (
                (!this.needParentPermission || this.parentPermission) &&
                // Here we check if there is discount and then we make winter offer terms mandatory
                ((this.acceptTermsPrivacy &&
                    (this.isSpecialOfferSubscription ? this.acceptWinterOfferTermsPrivacy : true)) ||
                    (!this.environmentService.isProduction() && this.enrollment.firstname == 'swaptest'))
            );
    }

    get termsUrl(): string {
        const terms = this.environmentService.getCountry().terms;
        return terms.replace('{0}', this.translateService.getLocale().language);
    }

    get specialOfferTermsUrl(): string {
        const { pedalPromoTerms, powerPromoTerms } = this.environmentService.getCountry();
        const productTermsLink = {
            [AssetCategory['org']]: pedalPromoTerms,
            [AssetCategory['org-one-plus']]: pedalPromoTerms,
            [AssetCategory['dlu']]: pedalPromoTerms,
            [AssetCategory['pwr-one']]: powerPromoTerms,
            [AssetCategory['ebike']]: powerPromoTerms,
        };
        const terms = productTermsLink[this.subscriptionType.assetCategoryCode];

        return terms.replace('{0}', this.translateService.getLocale().language);
    }

    get privacyUrl(): string {
        const privacy = this.environmentService.getCountry().privacy;
        return privacy.replace('{0}', this.translateService.getLocale().language);
    }

    get isPedalPromo(): boolean {
        const country = this.subscriptionType.country as CountryCode;
        const promoCountries = [CountryCode.NL, CountryCode.BE, CountryCode.DK];
        const promoAssets = [AssetCategory['org'], AssetCategory['org-one-plus'], AssetCategory['dlu']];

        return promoCountries.includes(country) && promoAssets.includes(this.subscriptionType.assetCategoryCode);
    }

    get specialOfferTermsByCountry(): string {
        const country = this.subscriptionType.country as CountryCode;

        if (this.isPedalPromo) {
            return 'enroll.check.pedalOfferTerms';
        }

        if (country === CountryCode.GB) {
            return 'enroll.check.powerOfferTerms.uk';
        }

        if (country === CountryCode.DK) {
            return 'enroll.check.powerOfferTerms.dk';
        }

        if (country === CountryCode.DE || country === CountryCode.NL) {
            return 'enroll.check.powerOfferTerms.oneMonthFree';
        }

        return 'enroll.check.powerOfferTerms.all';
    }

    /**
     * Validate form to progress to next step or display missing fields
     * @param form
     */
    submitForm(form: NgForm) {
        if (!this.environmentService.isProduction() && this.enrollment.firstname == 'swaptest') {
            this.enrollment.autoFillTestData();
            this.acceptTermsPrivacy = true;
            this.loadPhoneNumber();
            this.loadDateOfBirth();
            if (this.subscriptionType.isStudentOffer) {
                this.enrollment.studentStartYear = 1995 + Math.floor(Math.random() * 23);
            }
            return;
        }

        this.missingFields = [];
        this.errorFields = [];

        // Reset store selection for users that went back
        this.selectedFrame = 0;
        this.selectedStore = null;

        // Custom Introweek 2020 behaviour, auto select store
        if (this.isIntroweekSubscription) {
            this.setStoreSelected();
        }

        if (form.valid) {
            this.enrollment.postalCode = this.enrollment.postalCode.replace(' ', '');

            this.loading = true;
            this.enrollmentService.SaveEnrollmentInfo(this.enrollment);
            if (this.paymentFailed) {
                this.registrationService
                    .EditEnrollUser(this.enrollment, this.enrollmentService.getEnrollmentStatus())
                    .subscribe(
                        (data) => {
                            this.nextStep();
                        },
                        (error) => {
                            console.log('Error updating user: ', error);
                            Sentry.captureException(error);
                            Sentry.captureMessage(
                                'Error occured after payment failure and trying to update enrolled user'
                            );
                            this.popupService.showPopupRegistrationGeneral();
                            this.loading = false;
                        }
                    );
            } else {
                this.registrationService.EnrollUser(this.enrollment).subscribe(
                    (enrollmentStatus: EnrollmentStatus) => {
                        this.Log('Enrolled user', JSON.stringify(enrollmentStatus));
                        this.enrollmentService.setEnrollmentStatus(enrollmentStatus);
                        this.fbPixelService.sendSubscriptionIdEvent(enrollmentStatus.subscriptionId);
                        this.nextStep();
                    },
                    (error) => {
                        console.log('Error enrolling user: ', error);
                        console.log('ERROR DURING REG');
                        Sentry.captureMessage('Registration: initial enrollment failed');
                        Sentry.captureException(error);
                        if (error === 400) {
                            this.popupService.showPopupRegistrationExists();
                        } else {
                            this.popupService.showPopupRegistrationGeneral();
                        }
                        this.loading = false;
                    }
                );
            }

            return;
        }

        Object.keys(form.controls).forEach((key) => {
            let control = form.controls[key];
            control.markAsTouched();
            if (control.invalid) {
                let element = document.querySelector('[name=' + key + ']');
                if (control.value == null || control.value == '') {
                    this.missingFields.push(element);
                } else {
                    this.errorFields.push(element);
                }
            }
        });
    }

    selectFrame(frame) {
        this.selectedFrame = frame;
        if (this.selectedFrame == 1 && this.storeList.length == 1) {
            // Submit selection
            this.selectedStore = 0;
        } else if (this.selectedFrame == 2) {
            this.selectedStore = null;
        }
    }

    /***
     * @description used to track delivery choice via dataLayer
     * @param frame choice by the user. Number 1 corresponds with the first option (store). Number 2 corresponds with the second option (home)
     */
    submitDeliveryToDL(frame: number) {
        if (!(<any>window).dataLayer) {
            return;
        }
        if (frame === 1) {
            (<any>window).dataLayer.push({
                delivery: 'store',
            });
        } else if (frame === 2) {
            (<any>window).dataLayer.push({
                delivery: 'home',
            });
        }
    }

    /***
     * @description submit the country to the dataLayer for tracking
     * @param country code from the selected subscription
     */
    submitCountryToDL(country: string) {
        if (!(<any>window).dataLayer || !country) {
            return;
        }
        (<any>window).dataLayer.push({
            SignupCountry: country,
        });
    }

    /***
     * @description submit the subscriptipn code to the dataLayer for tracking
     * @param subscription code
     */
    submitSubscriptionCodeToDL(code: string) {
        if (!(<any>window).dataLayer || !code) {
            return;
        }
        (<any>window).dataLayer.push({
            SubscriptionCode: code,
        });
    }

    selectStore(store, $event: MouseEvent) {
        this.selectedStore = store;
        this.selectedSwapblock = null;
        if ($event) {
            $event.stopPropagation();
        }
    }

    goToPaymentPage() {
        if (this.invalidvoucher) {
            this.enrollment.giftCardCode = null;
        }
        this.nextStep();
    }

    isCurrentStep(name: string): boolean {
        return this.stepsService.steps.indexOf(name) == this.currentStep;
    }

    isStoreDelivery(): boolean {
        return this.selectedStore != null;
    }

    isEProduct(): boolean {
        if (!this.subscriptionType) {
            return false;
        }

        return IsEProduct(this.subscriptionType.assetCategoryCode);
    }

    get isDeliverySubtitleVisible(): boolean {
        const allowedCountries = [CountryCode.DE, CountryCode.NL];
        return !this.isEProduct() && allowedCountries.includes(this.subscriptionType.country as CountryCode);
    }

    isDeliveryValid(): boolean {
        return (this.selectedStore != null && this.selectedSwapblock != null) || this.selectedFrame == 2;
    }

    getMetDeliveryChoice(): string {
        return this.selectedFrame == 1 ? 'Signup - Delivery details - Store' : 'Signup - Delivery details - Delivery';
    }

    selectSwapblock($event: LocationSwapblock | boolean) {
        this.selectedSwapblock = $event;
    }

    setStoreSelected() {
        this.selectedFrame = 1;
        this.selectedStore = 0;
    }

    /**
     * Scroll the page to the HTMLElement
     * @param element
     */
    scrollTo(element: HTMLElement) {
        element.scrollIntoView({ behavior: 'smooth' });
    }

    /**
     * Change to new step
     * @param step To what step should we navigate?
     */
    setStep(step: number) {
        this.currentStep = step;
        window.scrollTo(0, 0);
        this.mainRegistration.nativeElement.scrollTo(0, 0);
        this.gtmService.event(checkoutStepGTM({ subscription: this.subscriptionType, stepNumber: this.currentStep }));
    }

    nextStep() {
        this.loading = false;
        this.setStep(this.currentStep + 1);
    }

    /**
     * Edit button next to address
     */
    backToEdit() {
        this.setStep(this.stepsService.steps.indexOf(this.stepsService.STEP_DATA));
    }

    /**
     * Edit button next to delivery info
     */
    backToDelivery() {
        this.setStep(this.stepsService.steps.indexOf(this.stepsService.STEP_DELIVERY));
    }

    goBack() {
        const referrer = localStorage.getItem('referrer');

        if (this.currentStep > 0 && this.currentStep) {
            this.setStep(this.currentStep - 1);
        } else if (referrer) {
            window.history.back();
        } else {
            window.location.href = this.environmentService.getCountry().website;
        }
    }

    heightInputControl$$ = new Subject<number>();
    /**
     *  @description Shows the alert pop up in case it matches the criteria
     */
    onHeightInputChange(event: number) {
        this.heightInputControl$$.next(event);
    }
    /**
     *  @description The height is calculated in centimeters
     */

    shouldShowHeightAlertPopUp$ = this.heightInputControl$$.pipe(
        map((data) => {
            if (this.subscriptionType.country === CountryCode.GB) {
                return data >= 165 && data <= 179 && this.subscriptionType.assetCategoryCode === AssetCategory.ebike;
            }
            return data > 100 && data < 165 && this.subscriptionType.assetCategoryCode === AssetCategory['pwr-one'];
        })
    );

    /**
     * Get regex string for validating postal code
     */
    get postalCodePattern(): string {
        return this.environmentService.getCountry().postalCodeValidator;
    }

    /**
     * All countries
     */
    get countries(): Country[] {
        return environment.countries;
    }

    get phoneNumberPrefixes(): PhonePrefix[] {
        return this.phoneNumberService.getPhonePrefixList();
    }

    /**
     * Can submit button be active
     */
    get canSubmitPayment(): boolean {
        return this.paymentSelectComponent && this.paymentSelectComponent.isValid() && !this.loading;
    }

    get isPrepaid(): boolean {
        if (!this.subscriptionType) {
            return false;
        }

        // We assume all subscriptions with a verificationAmount larger than 1 (euro, DKK) to be prepaid.
        return this.subscriptionType.verificationAmount >= 1;
    }

    get isInvertedRateSubscription(): boolean {
        return this.subscriptionTypeService.IsInvertedRateSubscription(this.subscriptionType.subscriptionCode);
    }

    get isIntroweekSubscription(): boolean {
        return this.subscriptionTypeService.IsIntroweekSubscription(this.subscriptionType.subscriptionCode);
    }

    get isSpecialOfferSubscription(): boolean {
        return !!this.subscriptionType?.isSpecialOffer;
    }

    get isThreeTierPricingSubscription(): boolean {
        // TODO: replace this hack with something more scalable
        return this.subscriptionTypecode.includes('3tier');
    }

    get isBackToSchoolCampaignSubscription(): boolean {
        return this.subscriptionTypeService.isBackToSchoolCampaignSubscription(this.subscriptionType.subscriptionCode);
    }

    prefillPhonePrefix() {
        this.phoneNumberBody = '';
        let country = this.countries.find((c) => c.key == this.subscriptionType.country);
        const phoneNumberPrefixes = this.phoneNumberService.getPhonePrefixList();
        if (country) {
            this.phoneNumberPrefix =
                phoneNumberPrefixes.find((item) => item.isoCode === country.key)?.dialPrefix ?? '+31'; // fallback value in case we add a new subscription country and forget to update account somehow
            this.enrollment.telephoneNumber1 = '';
        }
    }

    updatePhoneNumber() {
        if (this.phoneNumberPrefix == null || this.phoneNumberPrefix == '') {
            this.prefillPhonePrefix();
        }
        this.enrollment.telephoneNumber1 = this.phoneNumberPrefix + this.formatPhoneNumber(this.phoneNumberBody);
    }

    /**
     * @description Perform operations to keep payment system happy. It currently strips 0 from numbers
     */
    formatPhoneNumber(phoneNr) {
        return phoneNr[0] === '0' ? phoneNr.substring(1) : phoneNr;
    }

    updateDateOfBirth() {
        if (this.dateOfBirth.day && this.dateOfBirth.month && this.dateOfBirth.year) {
            this.enrollment.dateOfBirth =
                this.dateOfBirth.year + '-' + this.dateOfBirth.month + '-' + this.dateOfBirth.day;
            let date = new Date(
                Number(this.dateOfBirth.year),
                Number(this.dateOfBirth.month) - 1,
                Number(this.dateOfBirth.day)
            );
            date.setFullYear(date.getFullYear() + 18);
            if (date > new Date()) {
                this.needParentPermission = true;
            } else {
                this.needParentPermission = false;
            }
        } else {
            this.enrollment.dateOfBirth = '';
        }
    }

    loadDateOfBirth() {
        if (this.enrollment && this.enrollment.dateOfBirth && this.enrollment.dateOfBirth.split('-').length == 3) {
            this.dateOfBirth.day = parseInt(this.enrollment.dateOfBirth.split('-')[2].substring(0, 2), 10).toString();
            this.dateOfBirth.month = parseInt(this.enrollment.dateOfBirth.split('-')[1], 10).toString();
            this.dateOfBirth.year = this.enrollment.dateOfBirth.split('-')[0];
        }
    }

    loadPhoneNumber() {
        this.phoneNumberPrefix = this.enrollment.telephoneNumber1.substring(0, 3);
        // NB: ^^ this is some very sloppy code which won't work with numbers that contain prefixes that are bigger or smaller than 2 characters
        this.phoneNumberBody = this.enrollment.telephoneNumber1.substring(3);
    }

    getDays(): number[] {
        return new Array(new Date(+this.dateOfBirth.year || 1970, +this.dateOfBirth.month, 0).getDate() || 31);
    }

    getMonths(): number[] {
        return new Array(12);
    }

    getYears(): number[] {
        let list = [];
        for (let i = new Date().getFullYear() - 10; i >= 1900; i--) {
            list.push(i);
        }
        return list;
    }

    private startTimer() {
        if (this.timeOut) {
            clearTimeout(this.timeOut);
        }

        this.timeOut = setTimeout(() => {
            this.selectedSwapblock = null;
            this.selectedStore = null;
            this.selectedFrame = 0;
            this.popupService.showPopupRegistrationTimeout(() => {
                this.setStep(this.stepsService.steps.indexOf(this.stepsService.STEP_DELIVERY));
            });
        }, 1000 * 60 * 20);
    }

    private Log(...params: any[]) {
        if (!environment.production && console.log) {
            let args = Array.prototype.slice.call(params);
            args.unshift('[ENROLL_COMPONENT]');
            console.log.apply(console, args);
        }
    }
}

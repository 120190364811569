export class Location {
    locationId: string;
    name: string;
    street: string;
    houseNumber: string;
    zipcode: string;
    city: string;
    country: string;
    phoneNumber: string;
    latitude: string;
    longitude: string;
    isOpen: boolean;
    openinghours: OpeningHours;

    constructor(data: Partial<Location>) {
        data.openinghours = new OpeningHours(data.openinghours);
        Object.assign(this, data);
    }

    get addressLine() {
        return this.city + ' ' + this.street + ' ' + this.houseNumber;
    }
}

export class OpeningHours {
    monday_start: string;
    monday_close: string;
    tuesday_start: string;
    tuesday_close: string;
    wednesday_start: string;
    wednesday_close: string;
    thursday_start: string;
    thursday_close: string;
    friday_start: string;
    friday_close: string;
    saturday_start: string;
    saturday_close: string;
    sunday_start: string;
    sunday_close: string;

    constructor(data: Partial<OpeningHours>) {
        Object.assign(this, data);
    }

    getStartTimeByWeekday(weekday: number) {
        return [
            this.sunday_start,
            this.monday_start,
            this.tuesday_start,
            this.wednesday_start,
            this.thursday_start,
            this.friday_start,
            this.saturday_start,
        ][weekday];
    }

    getEndTimeByWeekday(weekday: number) {
        return [
            this.sunday_close,
            this.monday_close,
            this.tuesday_close,
            this.wednesday_close,
            this.thursday_close,
            this.friday_close,
            this.saturday_close,
        ][weekday];
    }
}

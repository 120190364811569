import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { EnvironmentService } from '../environment-service/environment.service';

// extend window for fbq
declare global {
    interface Window {
        fbq: (...arguments: any) => void;
        _fbq: (...arguments: any) => void;
    }
}
@Injectable({
    providedIn: 'root',
})
export class FacebookPixelService {
    private facebookPixelScript: HTMLScriptElement;
    private facebookPixelNoScript: HTMLElement;
    private facebookPixelImage: HTMLImageElement;

    constructor(private cookieService: CookieService, private environmentService: EnvironmentService) {}

    init() {
        this.facebookPixelScript = document.createElement('script');
        this.facebookPixelScript.src = 'https://connect.facebook.net/en_US/fbevents.js';
        this.facebookPixelScript.type = 'text/javascript';
        this.facebookPixelScript.async = true;
        document.head.appendChild(this.facebookPixelScript);
        this.prepareFbFuction();
        this.loadFacebookPixelImage();
    }

    hasMarketingCookies(): boolean {
        return this.cookieService.get('CookieConsent').includes('marketing:true');
    }

    async sendSubscriptionIdEvent(subscriptionId: string) {
        if (this.hasMarketingCookies()) {
            const hashedSubscriptionId = await this.hashMessage(subscriptionId);
            // window.fbq('track', 'Lead');
            window.fbq('init', this.environmentService.facebookPixelId, {
                external_id: hashedSubscriptionId,
            });
        }
    }

    async hashMessage(message: string) {
        const msgUint8 = new TextEncoder().encode(message);
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
        return hashHex;
    }

    prepareFbFuction() {
        if (window.fbq) return;
        const n = (window.fbq = function () {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        }) as any;
        if (!window._fbq) window._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        // window.fbq('init', this.environmentService.facebookPixelId);
        // @ts-ignore
        window.fbq.disablePushState = true;
    }

    loadFacebookPixelImage() {
        this.facebookPixelNoScript = document.createElement('noscript');
        this.facebookPixelImage = document.createElement('img');
        this.facebookPixelImage.src = `https://www.facebook.com/tr?id=${this.environmentService.facebookPixelId}&ev=PageView&noscript=1`;
        this.facebookPixelImage.setAttribute('height', '1');
        this.facebookPixelImage.setAttribute('width', '1');
        this.facebookPixelImage.setAttribute('style', 'display: none');
        this.facebookPixelNoScript.appendChild(this.facebookPixelImage);
        document.head.appendChild(this.facebookPixelNoScript);
    }
}

<div [@dropdown]="open">
    <section
        class="dropdown__wrapper"
        #dropdown
        [ngClass]="{ 'dropdown__wrapper--padding': padding }"
        [@appearDropdown]="open"
        [ngStyle]="{ 'left.px': offsetLeft, 'top.px': offsetTop + 80 }"
    >
        <div class="dropdown__wrapper__inner" [@appearDropdownInner]="open">
            <ng-content></ng-content>
        </div>
    </section>
</div>

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserDetails } from '../../shared/models/userdetails';
import { UserDetailsModelService } from '../services/user-details-model-service/user-details-model-service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from '../../shared/models/subscription';
import { GetBikeImageString } from '../subscriptions/subscription';

@Component({
    selector: 'app-cancel',
    templateUrl: './cancel.component.html',
    styleUrls: ['./cancel.component.scss'],
})
export class CancelComponent implements OnInit {
    @Output() atConfirmation: EventEmitter<boolean> = new EventEmitter();

    userDetails: UserDetails;
    subscriptionId: string;
    subscription: Subscription;
    confirmed = false;

    constructor(
        private userDetailsModelService: UserDetailsModelService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.userDetailsModelService.getModel().subscribe((userDetails: UserDetails) => {
                this.userDetails = userDetails;
                this.subscription = userDetails.subscriptions.find((subscription) => {
                    return subscription.uuid == params['id'];
                });
            });
        });
    }

    goToConfirmation() {
        this.confirmed = true;
        this.atConfirmation.emit(true);

        window.history.pushState({}, '', `${this.router.url}/confirmed`);
    }

    public getBikeImageString() {
        return GetBikeImageString(this.subscription);
    }
}

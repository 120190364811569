<div class="topbar">
    <img class="logo" src="assets/logos/swapfiets_nl.svg" />
</div>

<div class="page">
    <svg #cardSvg viewBox="0 0 477 353" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <circle id="path-1" cx="37" cy="37" r="37"></circle>
            <filter x="-31.8%" y="-18.2%" width="147.3%" height="141.9%" filterUnits="objectBoundingBox" id="filter-2">
                <feMorphology
                    radius="5.5"
                    operator="dilate"
                    in="SourceAlpha"
                    result="shadowSpreadOuter1"
                ></feMorphology>
                <feOffset dx="-8" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
                <feMorphology radius="5.5" operator="erode" in="SourceAlpha" result="shadowInner"></feMorphology>
                <feOffset dx="-8" dy="2" in="shadowInner" result="shadowInner"></feOffset>
                <feComposite
                    in="shadowOffsetOuter1"
                    in2="shadowInner"
                    operator="out"
                    result="shadowOffsetOuter1"
                ></feComposite>
                <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix
                    values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0599524457 0"
                    type="matrix"
                    in="shadowBlurOuter1"
                ></feColorMatrix>
            </filter>
            <circle id="path-3" cx="51.5" cy="51.5" r="51.5"></circle>
            <filter x="-22.8%" y="-13.1%" width="134.0%" height="130.1%" filterUnits="objectBoundingBox" id="filter-4">
                <feMorphology
                    radius="5.5"
                    operator="dilate"
                    in="SourceAlpha"
                    result="shadowSpreadOuter1"
                ></feMorphology>
                <feOffset dx="-8" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
                <feMorphology radius="5.5" operator="erode" in="SourceAlpha" result="shadowInner"></feMorphology>
                <feOffset dx="-8" dy="2" in="shadowInner" result="shadowInner"></feOffset>
                <feComposite
                    in="shadowOffsetOuter1"
                    in2="shadowInner"
                    operator="out"
                    result="shadowOffsetOuter1"
                ></feComposite>
                <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix
                    values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0599524457 0"
                    type="matrix"
                    in="shadowBlurOuter1"
                ></feColorMatrix>
            </filter>
            <circle id="path-5" cx="37" cy="37" r="37"></circle>
            <filter x="-15.5%" y="-18.2%" width="147.3%" height="141.9%" filterUnits="objectBoundingBox" id="filter-6">
                <feMorphology
                    radius="5.5"
                    operator="dilate"
                    in="SourceAlpha"
                    result="shadowSpreadOuter1"
                ></feMorphology>
                <feOffset dx="8" dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
                <feMorphology radius="5.5" operator="erode" in="SourceAlpha" result="shadowInner"></feMorphology>
                <feOffset dx="8" dy="2" in="shadowInner" result="shadowInner"></feOffset>
                <feComposite
                    in="shadowOffsetOuter1"
                    in2="shadowInner"
                    operator="out"
                    result="shadowOffsetOuter1"
                ></feComposite>
                <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix
                    values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0599524457 0"
                    type="matrix"
                    in="shadowBlurOuter1"
                ></feColorMatrix>
            </filter>
            <path
                d="M65.9091679,92.4538197 L292.328581,69.687822 C297.82372,69.1352972 302.726321,73.1420783 303.278846,78.637218 C303.361618,79.4604337 303.341907,80.2907399 303.220165,81.1091005 L279.279308,242.040792 C278.582847,246.722442 274.699548,250.274603 269.974521,250.552138 L40.1407406,264.051957 C34.6273956,264.375796 29.895423,260.16887 29.571584,254.655525 C29.5251841,253.86557 29.5727128,253.0729 29.7131726,252.294149 L57.068397,100.628637 C57.86067,96.236039 61.4680857,92.9003612 65.9091679,92.4538197 Z"
                id="path-7"
            ></path>
            <filter x="-2.6%" y="-2.6%" width="105.1%" height="107.2%" filterUnits="objectBoundingBox" id="filter-8">
                <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix
                    values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
                    type="matrix"
                    in="shadowBlurOuter1"
                ></feColorMatrix>
            </filter>
            <path
                d="M32.9448889,39.9060013 L21.6344024,40.0391835 C16.6676745,40.0391835 12.6413461,35.8030424 12.6413461,30.5774975 L12.6413461,29.1326283 C12.6413461,23.9070834 16.6676745,19.6709423 21.6344024,19.6709423 L32.3194544,20.4351807 L32.9448889,39.9060013 Z"
                id="path-9"
            ></path>
            <rect
                id="path-10"
                x="23.7730785"
                y="21.6611959"
                width="3.15151515"
                height="10.8488689"
                rx="1.57575758"
            ></rect>
            <rect
                id="path-11"
                x="17.9096153"
                y="-6.94473843"
                width="4.11642349"
                height="47.8064135"
                rx="2.05821175"
            ></rect>
            <rect id="path-12" x="9.45043478" y="0.11" width="3.09913043" height="21.78" rx="1.54956522"></rect>
            <rect id="path-13" x="9.45043478" y="0.11" width="3.09913043" height="21.78" rx="1.54956522"></rect>
            <rect id="path-14" x="9.45043478" y="0.11" width="3.09913043" height="21.78" rx="1.54956522"></rect>
            <rect id="path-15" x="9.45043478" y="0.11" width="3.09913043" height="21.78" rx="1.54956522"></rect>
        </defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="MD---768px(708px)---G:30px---P:30px-Copy-10" transform="translate(-282.000000, -110.000000)">
                <g id="move_area" transform="translate(300.000000, 118.000000)">
                    <g id="tire_bottom" class="tire" transform="translate(0.000000, 240.000000)">
                        <g id="blue_circle">
                            <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
                            <use stroke="#00A9E0" stroke-width="11" xlink:href="#path-1"></use>
                        </g>
                        <g class="box">
                            <circle id="right_white_eye" stroke="#E9E9E9" fill="#FFFFFF" cx="54" cy="41" r="9"></circle>
                            <circle class="black_eye" fill="#000000" cx="54" cy="41" r="4.5"></circle>
                        </g>
                        <g class="box">
                            <circle id="left_white_eye" stroke="#E9E9E9" fill="#FFFFFF" cx="25" cy="41" r="9"></circle>
                            <circle class="black_eye" fill="#000000" cx="25" cy="41" r="4.5"></circle>
                        </g>
                    </g>
                    <g id="tire_top" class="tire" transform="translate(14.000000, 0.000000)">
                        <g id="blue_circle">
                            <use fill="black" fill-opacity="1" filter="url(#filter-4)" xlink:href="#path-3"></use>
                            <use stroke="#00A9E0" stroke-width="11" xlink:href="#path-3"></use>
                        </g>
                        <g class="box">
                            <circle id="right_white_eye" stroke="#E9E9E9" fill="#FFFFFF" cx="77" cy="58" r="9"></circle>
                            <circle class="black_eye" fill="#000000" cx="77" cy="58" r="4.5"></circle>
                        </g>
                        <g class="box">
                            <circle id="left_white_eye" stroke="#E9E9E9" fill="#FFFFFF" cx="34" cy="58" r="9"></circle>
                            <circle class="black_eye" fill="#000000" cx="34" cy="58" r="4.5"></circle>
                        </g>
                    </g>
                    <g id="tire_right" class="tire" transform="translate(367.000000, 61.000000)">
                        <g id="blue_circle">
                            <use fill="black" fill-opacity="1" filter="url(#filter-6)" xlink:href="#path-5"></use>
                            <use stroke="#00A9E0" stroke-width="11" xlink:href="#path-5"></use>
                        </g>
                        <g class="box">
                            <circle id="right_white_eye" stroke="#E9E9E9" fill="#FFFFFF" cx="54" cy="41" r="9"></circle>
                            <circle class="black_eye" fill="#000000" cx="54" cy="41" r="4.5"></circle>
                        </g>
                        <g class="box">
                            <circle id="left_white_eye" stroke="#E9E9E9" fill="#FFFFFF" cx="25" cy="41" r="9"></circle>
                            <circle class="black_eye" fill="#000000" cx="25" cy="41" r="4.5"></circle>
                        </g>
                    </g>
                    <g id="card" transform="translate(72.000000, 38.000000)">
                        <g id="Card">
                            <g
                                id="card_layer_bottom"
                                opacity="0.850373641"
                                transform="translate(166.441697, 166.853182) rotate(-317.000000) translate(-166.441697, -166.853182) "
                            >
                                <use fill="black" fill-opacity="1" filter="url(#filter-8)" xlink:href="#path-7"></use>
                                <use fill="#0589B3" fill-rule="evenodd" xlink:href="#path-7"></use>
                            </g>
                            <path
                                d="M65.2073468,89.4259828 L292.225275,68.6355024 C297.725107,68.1318235 302.591914,72.1820058 303.095593,77.6818378 C303.169928,78.4935257 303.144747,79.3112573 303.020609,80.1168334 L278.536759,239.001668 C277.818874,243.660288 273.945276,247.185069 269.239779,247.461458 L39.3628869,260.963808 C33.8495419,261.287647 29.1175693,257.080722 28.7937302,251.567377 C28.7473304,250.777422 28.7948591,249.984751 28.9353189,249.206 L56.2781327,97.6092957 C57.0762563,93.1842611 60.7296494,89.8360539 65.2073468,89.4259828 Z"
                                id="card_layer_top"
                                fill="#00A9E0"
                                transform="translate(165.956895, 164.787249) rotate(-317.000000) translate(-165.956895, -164.787249) "
                            ></path>
                            <polygon
                                id="card_layer_strip"
                                fill="#02688B"
                                points="116.648284 52.5758073 95.4990706 68.6441844 290.877515 222.29804 312.026728 205.827929"
                            ></polygon>
                        </g>
                        <g id="card_mouth" transform="translate(114.000000, 170.000000)">
                            <g
                                id="Rectangle-4"
                                transform="translate(22.793118, 29.855063) rotate(-47.000000) translate(-22.793118, -29.855063) "
                            >
                                <use fill="#00A9E0" fill-rule="evenodd" xlink:href="#path-9"></use>
                                <path
                                    stroke="#02688B"
                                    stroke-width="4"
                                    d="M30.8803905,37.9301723 L30.3783706,22.3014557 L21.5676492,21.671277 C17.7591062,21.7094821 14.6413461,25.0136095 14.6413461,29.1326283 L14.6413461,30.5774975 C14.6413461,34.720556 17.7956046,38.0391835 21.6108538,38.0393221 L30.8803905,37.9301723 Z"
                                ></path>
                            </g>
                            <g
                                id="Rectangle-3"
                                transform="translate(25.348836, 27.085630) rotate(-317.000000) translate(-25.348836, -27.085630) "
                            >
                                <use fill="#02688B" fill-rule="evenodd" xlink:href="#path-10"></use>
                                <rect
                                    stroke="#02688B"
                                    stroke-width="1"
                                    x="24.2730785"
                                    y="22.1611959"
                                    width="2.15151515"
                                    height="9.84886892"
                                    rx="1.07575758"
                                ></rect>
                            </g>
                            <g
                                id="Rectangle-3"
                                transform="translate(19.967827, 16.958468) rotate(-50.000000) translate(-19.967827, -16.958468) "
                            >
                                <use fill="#02688B" fill-rule="evenodd" xlink:href="#path-11"></use>
                                <rect
                                    stroke="#02688B"
                                    stroke-width="1"
                                    x="18.4096153"
                                    y="-6.44473843"
                                    width="3.11642349"
                                    height="46.8064135"
                                    rx="1.55821175"
                                ></rect>
                            </g>
                        </g>
                        <g id="card_left_eye" transform="translate(123.000000, 144.000000)">
                            <g
                                id="Rectangle-3"
                                transform="translate(11.000000, 11.000000) rotate(-2.000000) translate(-11.000000, -11.000000) "
                            >
                                <use fill="#02688B" fill-rule="evenodd" xlink:href="#path-12"></use>
                                <rect
                                    stroke="#02688B"
                                    stroke-width="1"
                                    x="9.95043478"
                                    y="0.61"
                                    width="2.09913043"
                                    height="20.78"
                                    rx="1.04956522"
                                ></rect>
                            </g>
                            <g
                                id="Rectangle-3"
                                transform="translate(11.000000, 11.000000) rotate(-270.000000) translate(-11.000000, -11.000000) "
                            >
                                <use fill="#02688B" fill-rule="evenodd" xlink:href="#path-13"></use>
                                <rect
                                    stroke="#02688B"
                                    stroke-width="1"
                                    x="9.95043478"
                                    y="0.61"
                                    width="2.09913043"
                                    height="20.78"
                                    rx="1.04956522"
                                ></rect>
                            </g>
                        </g>
                        <g id="card_right_eye" transform="translate(164.000000, 177.000000)">
                            <g
                                id="Rectangle-3"
                                transform="translate(11.000000, 11.000000) rotate(-2.000000) translate(-11.000000, -11.000000) "
                            >
                                <use fill="#02688B" fill-rule="evenodd" xlink:href="#path-14"></use>
                                <rect
                                    stroke="#02688B"
                                    stroke-width="1"
                                    x="9.95043478"
                                    y="0.61"
                                    width="2.09913043"
                                    height="20.78"
                                    rx="1.04956522"
                                ></rect>
                            </g>
                            <g
                                id="Rectangle-3"
                                transform="translate(11.000000, 11.000000) rotate(-270.000000) translate(-11.000000, -11.000000) "
                            >
                                <use fill="#02688B" fill-rule="evenodd" xlink:href="#path-15"></use>
                                <rect
                                    stroke="#02688B"
                                    stroke-width="1"
                                    x="9.95043478"
                                    y="0.61"
                                    width="2.09913043"
                                    height="20.78"
                                    rx="1.04956522"
                                ></rect>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    <h1>{{ 'payment_expired.title' | translate }}</h1>
    <p>
        {{ 'payment_expired.body' | translate }}
    </p>
    <button class="btn btn-primary" (click)="navigateToSite()">{{ 'payment_expired.button' | translate }}</button>
</div>

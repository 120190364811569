import { Directive, EventEmitter, Injectable, Output } from '@angular/core';
import { PopupPaymentFailedComponent } from '../popup-payment-failed/popup-payment-failed.component';
import { PopupRegistrationExistsComponent } from '../popup-registration-exists/popup-registration-exists.component';
import { PopupRegistrationGeneralComponent } from '../popup-registration-general/popup-registration-general.component';
import { PopupBookingfailedComponent } from '../popup-bookingfailed/popup-bookingfailed.component';
import { PopupRegistrationTimeoutComponent } from '../popup-registration-timeout/popup-registration-timeout.component';
import { PopupEmailComponent } from '../popup-email/popup-email.component';
import { PopupUndoCancelledSubscriptionComponent } from '../popup-undo-cancelled-subscription/popup-undo-cancelled-subscription.component';
import { Subscription } from '../models/subscription';
import { PopupReSignupComponent } from '../popup-re-signup/popup-re-signup.component';

interface Config {
    shouldFadeIn: boolean;
    shouldHideOnOverlayClick: boolean;
}

@Directive()
@Injectable()
export class PopupServiceDirective {
    @Output() show: EventEmitter<{
        componentType:
            | typeof PopupEmailComponent
            | typeof PopupRegistrationGeneralComponent
            | typeof PopupPaymentFailedComponent
            | typeof PopupRegistrationExistsComponent
            | typeof PopupUndoCancelledSubscriptionComponent
            | typeof PopupBookingfailedComponent
            | typeof PopupRegistrationTimeoutComponent
            | typeof PopupReSignupComponent;
        params: Record<string, any>;
        config?: Config;
    }> = new EventEmitter();

    constructor() {}

    showPopupEmail() {
        this.show.emit({
            componentType: PopupEmailComponent,
            params: {},
        });
    }

    showPopupRegistrationGeneral() {
        this.show.emit({
            componentType: PopupRegistrationGeneralComponent,
            params: {},
        });
    }

    showPopupPaymentFailed(detailCode: string) {
        this.show.emit({
            componentType: PopupPaymentFailedComponent,
            params: {
                detailCode: detailCode,
            },
        });
    }

    showPopupRegistrationExists() {
        this.show.emit({
            componentType: PopupRegistrationExistsComponent,
            params: {},
        });
    }

    showUndoCancelledSubscription(subscription: Subscription) {
        this.show.emit({
            componentType: PopupUndoCancelledSubscriptionComponent,
            params: {
                subscription: subscription,
            },
        });
    }

    showPopupBookingfailed() {
        this.show.emit({
            componentType: PopupBookingfailedComponent,
            params: {},
        });
    }

    showReSignupInfo(config: Config) {
        this.show.emit({
            componentType: PopupReSignupComponent,
            params: {},
            config,
        });
    }

    showPopupRegistrationTimeout(goToDeliveryStepFunction: Function) {
        this.show.emit({
            componentType: PopupRegistrationTimeoutComponent,
            params: {
                goToDeliveryStepFunction: goToDeliveryStepFunction,
            },
        });
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountRoutingModule } from '../account-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared.module';
import { UiModule } from '../../ui/ui.module';
import { MobileMenuComponent } from './mobile/mobile-menu/mobile-menu.component';
import { MobileHamburgerComponent } from './mobile/mobile-hamburger/mobile-hamburger.component';
import { HeaderComponent } from './header.component';
import { SubscriptionsModule } from '../subscriptions/subscriptions.module';
import { ChangePasswordModule } from '../change-password/change-password.module';

@NgModule({
    declarations: [MobileMenuComponent, MobileHamburgerComponent, HeaderComponent],
    imports: [
        CommonModule,
        AccountRoutingModule,
        HttpClientModule,
        FormsModule,
        SharedModule,
        SubscriptionsModule,
        ChangePasswordModule,
        UiModule,
    ],
    exports: [MobileMenuComponent, MobileHamburgerComponent, HeaderComponent],
})
export class HeaderModule {}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Kyc, KycRequired } from '../models/kyc';

@Injectable({
    providedIn: 'root',
})
export class KycService {
    constructor(private http: HttpClient) {}

    getSignUpKycAttempt(customerId: string): Observable<Kyc> {
        const url = environment.apiRoot + 'v1/kyc/SignupKycAttemptForCustomer' + '?customerId=' + customerId;
        return this.http.get<Kyc>(url);
    }

    isRequiredForCustomer(customerId: string, assetCategoryCode: string, countryCode: string): Observable<KycRequired> {
        const url =
            environment.apiRoot +
            'v1/kyc/IsRequiredForCustomer' +
            '?customerId=' +
            customerId +
            '&assetCategoryCode=' +
            assetCategoryCode +
            '&countryCode=' +
            countryCode;
        return this.http.get<KycRequired>(url);
    }
}

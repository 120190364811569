import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { AuthService } from '../../shared/auth-service/auth.service';
import { OverlayService } from '../../ui/services/overlay.service';
import { UserDetailsModelService } from '../services/user-details-model-service/user-details-model-service';
import { Subscription } from '../../shared/models/subscription';
import { UserDetails } from '../../shared/models/userdetails';
import { EnvironmentService } from '../../shared/environment-service/environment.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    subscriptionsActive: boolean;
    subscriptions?: Subscription[] = [];
    userCountry: UserDetails['country'] = '';
    passwordDropdown = false;
    subscriptionsDropdown = false;
    menuOpen = false;
    animationActive = false;
    appLink = '';

    constructor(
        private authService: AuthService,
        private userDetailsModelService: UserDetailsModelService,
        private router: Router,
        private overlayService: OverlayService,
        private environmentService: EnvironmentService
    ) {
        this.routeEvent(this.router);
    }

    ngOnInit() {
        this.overlayService.getOverlay$().subscribe((overlay) => {
            if (!overlay) {
                this.subscriptionsDropdown = false;
                this.passwordDropdown = false;
            }
        });

        this.userDetailsModelService.getModel().subscribe((userDetails) => {
            this.subscriptions = userDetails.subscriptions;
            this.userCountry = userDetails.country;
        });

        this.environmentService.setCountryKey(this.userCountry);
        const country = this.environmentService.getCountry();
        this.appLink = country.appLink;
    }

    routeEvent(router: Router) {
        router.events.subscribe((e) => {
            if (e instanceof NavigationEnd) {
                this.resetMenu();

                this.subscriptionsActive = this.router.url.indexOf('subscriptions') !== -1;
            }
        });
    }

    /***
     * @description function to disable all state from the menu when user navigates away. Cleans up dropdowns, menu states.
     */
    resetMenu() {
        this.passwordDropdown = false;
        this.subscriptionsDropdown = false;
        this.overlayService.disableOverlay();
        this.menuOpen = false;
    }

    signout() {
        this.authService.signOut().then(() => {
            return;
        });
    }

    toggleDropdown(toggle) {
        switch (toggle) {
            case 'password':
                this.subscriptionsDropdown = false;
                this.passwordDropdown = !this.passwordDropdown;
                this.passwordDropdown ? this.overlayService.setOverlay() : this.overlayService.disableOverlay();
                break;
            default:
                return;
        }
    }

    shiftNavbar() {
        this.menuOpen ? (this.animationActive = true) : (this.animationActive = false);
    }
}

import { animate, state, style, transition, trigger } from '@angular/animations';

export const menuAppear = trigger('menuAppear', [
    state(
        '0',
        style({
            opacity: 0,
            transform: 'scaleY(0.8) translateY(-10px)',
        })
    ),
    state(
        '1',
        style({
            opacity: 1,
            pointerEvents: 'all',
            transform: 'scaleY(1) translateY(0px)',
        })
    ),
    transition('0 => 1', [animate('0.1s 0.15s ease-in-out')]),
    transition('1 => 0', [animate('0.3s ease-in-out')]),
]);

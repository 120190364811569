const generalLinks = {
    jobs: 'https://jobs.swapfiets.com/',
    responsibleDisclosure: 'https://swapfiets.com/en/responsible-disclosure',
    website: 'https://staging.website.swap.bike/',
    // Under which path on Member's web app KYC sits
    onlineIdVerificationPath: 'account/kyc',
};

export const defaults = {
    cookieInfo: {
        cbid: '04e908d3-d9b0-4603-af20-d2d2fc2d7fb5',
    },
    countries: {
        NL: {
            ...generalLinks,
            key: 'NL',
            name: 'Netherlands',
            terms: 'https://swapfiets.com/{0}-NL/terms',
            pedalPromoTerms: 'https://swapfiets.com/{0}-NL/pedal-promo-terms',
            powerPromoTerms: 'https://swapfiets.com/{0}-NL/power-promo-terms',
            privacy: 'https://swapfiets.com/{0}-NL/privacy',
            faq: 'https://help.swapfiets.nl/hc',
            appLink: 'https://help.swapfiets.nl/hc/articles/360016890459-Download-the-App-',
            swapdesk: '+31 88 99 11 600',
            languages: ['nl-NL', 'en-NL'],
            phonePrefix: '+31',
            postalCodeValidator: '^[1-9][0-9]{3}\\s?[a-zA-Z]{2}$',
        },
        BE: {
            ...generalLinks,
            key: 'BE',
            name: 'Belgium',
            terms: 'https://swapfiets.com/{0}-BE/terms',
            pedalPromoTerms: 'https://swapfiets.com/{0}-BE/pedal-promo-terms',
            powerPromoTerms: 'https://swapfiets.com/{0}-BE/power-promo-terms',
            privacy: 'https://swapfiets.com/{0}-BE/privacy',
            faq: 'https://help.swapfiets.be/hc',
            appLink: 'https://help.swapfiets.be/hc/articles/4416258224658-Download-the-App-',
            swapdesk: '+32 3 304 55 70',
            languages: ['nl-BE', 'fr-BE', 'en-BE'],
            phonePrefix: '+32',
            postalCodeValidator: '^[0-9]{4}$',
        },
        DE: {
            ...generalLinks,
            key: 'DE',
            name: 'Germany',
            terms: 'https://swapfiets.com/{0}-DE/terms',
            privacy: 'https://swapfiets.com/{0}-DE/privacy',
            faq: 'https://help.swapfiets.de/hc',
            appLink: 'https://help.swapfiets.de/hc/articles/4416275518610-Download-the-App-',
            powerPromoTerms: 'https://swapfiets.com/{0}-DE/power-promo-terms',
            swapdesk: '+49 30 91734100',
            languages: ['de-DE', 'en-DE'],
            phonePrefix: '+49',
            postalCodeValidator: '[0-9]{5}$',
        },
        DK: {
            ...generalLinks,
            key: 'DK',
            name: 'Danmark',
            terms: 'https://swapfiets.com/{0}-DK/terms',
            pedalPromoTerms: 'https://swapfiets.com/{0}-DK/pedal-promo-terms',
            powerPromoTerms: 'https://swapfiets.com/{0}-DK/power-promo-terms',
            privacy: 'https://swapfiets.com/{0}-DK/privacy',
            faq: 'https://help.swapfiets.dk/hc',
            appLink: 'https://help.swapfiets.dk/hc/articles/360021588139-Download-the-App-',
            swapdesk: '+45 78 79 31 58',
            languages: ['da-DK', 'en-DK'],
            phonePrefix: '+45',
            postalCodeValidator: '^[0-9]{4}$',
        },
        FR: {
            ...generalLinks,
            key: 'FR',
            name: 'France',
            terms: 'https://swapfiets.com/{0}-FR/terms',
            privacy: 'https://swapfiets.com/{0}-FR/privacy',
            faq: 'https://help.swapfiets.fr/hc',
            appLink: 'https://help.swapfiets.fr/hc/articles/4416262342546-Téléchargez-l-application-',
            swapdesk: '+33 173 440 919',
            languages: ['fr-FR', 'en-FR'],
            phonePrefix: '+33',
            postalCodeValidator: '[0-9]{5}$',
        },
        IT: {
            ...generalLinks,
            key: 'IT',
            name: 'Italy',
            terms: 'https://swapfiets.com/{0}-IT/terms',
            privacy: 'https://swapfiets.com/{0}-IT/privacy',
            faq: 'https://help.swapfiets.it/hc',
            appLink: 'https://help.swapfiets.it/hc/articles/4416275688338-Scarica-l-app-',
            swapdesk: '+39 028 295 80 65',
            languages: ['it-IT', 'en-IT'],
            phonePrefix: '+39',
            postalCodeValidator: '[0-9]{5}$',
        },
        GB: {
            ...generalLinks,
            key: 'GB',
            name: 'United Kingdom',
            terms: 'https://swapfiets.com/{0}-GB/terms',
            privacy: 'https://swapfiets.com/{0}-GB/privacy',
            faq: 'https://help.swapfiets.co.uk/hc',
            appLink: 'https://help.swapfiets.co.uk/hc/articles/360017232559-Download-the-App-',
            swapdesk: '+442038080005',
            powerPromoTerms: 'https://swapfiets.com/{0}-GB/power-promo-terms',
            languages: ['en-GB'],
            phonePrefix: '+44',
            postalCodeValidator:
                '^(([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z])))) ?[0-9][A-Z]{2}$',
        },
        ES: {
            ...generalLinks,
            key: 'ES',
            name: 'Spain',
            terms: 'https://swapfiets.com/{0}-ES/terms',
            privacy: 'https://swapfiets.com/{0}-ES/privacy',
            faq: 'https://help.swapfiets.es/hc',
            appLink: 'https://help.swapfiets.es/hc/articles/4416249585810-Download-the-App-',
            swapdesk: '+34932202371',
            languages: ['es-ES', 'ca-ES', 'en-ES'],
            phonePrefix: '+34',
            postalCodeValidator: '[0-9]{5}$',
        },
        AT: {
            ...generalLinks,
            key: 'AT',
            name: 'Austria',
            terms: 'https://swapfiets.com/{0}-AT/terms',
            privacy: 'https://swapfiets.com/{0}-AT/privacy',
            faq: 'https://help.swapfiets.at/hc',
            appLink: 'https://help.swapfiets.at/hc/en-gb/articles/4416262067602-Download-the-App-',
            swapdesk: '+43 720 881901',
            languages: ['de-AT', 'en-AT'],
            phonePrefix: '+43',
            postalCodeValidator: '[0-9]{4}$',
        },
    },
};

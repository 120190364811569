<div class="container-fluid margin-extralarge">
    <h1>{{ 'relocation.page.title' | translate }}</h1>
    <p>{{ 'relocation.page.intro' | translate }}</p>
    <form class="mt-4" #addressForm="ngForm" id="addressForm" (ngSubmit)="submitForm(addressForm)">
        <h3>{{ 'relocation.address.new' | translate }}</h3>
        <div class="row">
            <form-field-container class="col-12">
                <input
                    type="text"
                    [(ngModel)]="addressDetails.street"
                    name="street"
                    placeholder="{{ 'enroll.form.streetname' | translate }}"
                    attr.aria-label="{{ 'enroll.form.streetname' | translate }}"
                    required
                    [disabled]="isLoading"
                />
            </form-field-container>
        </div>

        <div class="row">
            <form-field-container class="col-8">
                <input
                    type="text"
                    [(ngModel)]="addressDetails.houseNumber"
                    name="houseNumber"
                    placeholder="{{ 'enroll.form.housenumber' | translate }}"
                    attr.aria-label="{{ 'enroll.form.housenumber' | translate }}"
                    required
                    [disabled]="isLoading"
                />
            </form-field-container>
            <form-field-container class="col-4">
                <input
                    type="text"
                    placeholder="{{ 'enroll.form.housenumbersuffix' | translate }}"
                    attr.aria-label="{{ 'enroll.form.housenumbersuffix' | translate }}"
                    name="houseNumberAddition"
                    [disabled]="isLoading"
                />
            </form-field-container>
        </div>

        <div class="row">
            <form-field-container class="col-4">
                <input
                    type="text"
                    [(ngModel)]="addressDetails.postalCode"
                    name="postalCode"
                    required
                    pattern="{{ postalCodePattern }}"
                    placeholder="{{ 'enroll.form.zipcode' | translate }}"
                    attr.aria-label="{{ 'enroll.form.zipcode' | translate }}"
                    [disabled]="isLoading"
                />
            </form-field-container>
            <form-field-container class="col-8">
                <input
                    type="text"
                    [(ngModel)]="addressDetails.city"
                    name="city"
                    required
                    placeholder="{{ 'enroll.form.city' | translate }}"
                    attr.aria-label="{{ 'enroll.form.city' | translate }}"
                    [disabled]="isLoading"
                />
            </form-field-container>
        </div>

        <div class="row relocationWrapper invalidAddressWarning" *ngIf="hasRequestError">
            {{ 'relocation.error.general' | translate }}
        </div>

        <div class="row relocationWrapper" *ngIf="!hasRequestError && isSubmitted && !isLoading">
            <div class="invalidAddressWarning" *ngIf="!isSubInsideServiceArea && !hasInvalidAddressError">
                {{ 'relocation.error.subscription_not_supported' | translate }}
            </div>
            <div>
                <div class="invalidAddressWarning" *ngIf="hasInvalidAddressError">
                    {{ 'relocation.error.no_address_found' | translate }}
                </div>
                <div *ngIf="!hasInvalidAddressError && isSubInsideServiceArea">
                    <div *ngIf="isInsideServiceArea" class="relocationTitle relocationSuccess">
                        {{ 'relocation.success.inside_service_area' | translate }}
                    </div>
                    <div *ngIf="!isInsideServiceArea">
                        <div class="relocationWarning">
                            <p class="relocationTitle">{{ 'relocation.warning.outside_service_area' | translate }}</p>
                            <p>
                                {{ 'relocation.info.outside_service_area' | translate }}
                                {{ 'relocation.link.outside_service_area' | translate }}
                            </p>
                        </div>
                        <input type="checkbox" required [(ngModel)]="serviceArea" id="serviceArea" name="serviceArea" />
                        <label class="checkbox-spacing" for="serviceArea">
                            {{ 'relocation.disclaimer.outside_service_area' | translate }}
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row col-6">
            <div>
                <button
                    *ngIf="!isAddressChecked || !isSubInsideServiceArea"
                    type="submit"
                    form="addressForm"
                    class="btn btn-primary"
                    [disabled]="!addressForm.form.valid || isLoading"
                >
                    {{ 'relocation.button.check' | translate }}
                    <i *ngIf="isLoading" class="fa fa-spin fa-spinner"></i>
                </button>
            </div>
            <button
                *ngIf="isAddressChecked && isSubInsideServiceArea"
                type="submit"
                form="addressForm"
                class="btn btn-green"
                [disabled]="!addressForm.form.valid || isLoading"
            >
                {{ 'relocation.button.proceed' | translate }}
            </button>
        </div>
    </form>
</div>

<div *ngIf="modalContent === EMAIL_STATES.start" class="modal__content">
    <img class="modal__image" src="/assets/icons/mail.svg" alt="send email" />
    <h2 class="modal__heading">{{ 'referral.invite.title' | translate }}</h2>

    <form [formGroup]="referralForm" class="email__form">
        <div formArrayName="emails" *ngFor="let item of referralForm.get('emails').controls; let i = index">
            <div class="email__input-container" [formGroupName]="i">
                <span *ngIf="i > 0" (click)="removeItem(i)" class="email__remove"
                    ><img src="/assets/icons/cross.svg" alt="close" class="email__remove-icon"
                /></span>
                <input formControlName="email" placeholder="Email" class="email__input" />
            </div>
        </div>

        <span class="email__add-email" (click)="addItem()">
            <img src="/assets/icons/blue-plus.svg" alt="add new email" class="email__add-email-icon" />{{
                'referral.addemail' | translate
            }}
        </span>
    </form>

    <button class="modal__button" [disabled]="isSending" (click)="sendEmails()">
        {{ 'referral.send.button' | translate }}
    </button>
</div>

<div *ngIf="modalContent === EMAIL_STATES.sent" class="modal__content">
    <img class="modal__image" src="/assets/icons/email-sent.svg" alt="email sent" />
    <h2 class="modal__heading">{{ 'referral.sent.title' | translate }}</h2>
    <p class="modal__text">{{ 'referral.sent.description' | translate }}</p>

    <button class="modal__button" (click)="closeModal()">{{ 'referral.sent.button' | translate }}</button>
</div>

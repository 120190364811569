import { Component, OnInit, ElementRef, ViewChildren } from '@angular/core';
import { ToastService } from '../toast-service/toast.service';
import { Toast } from './toast';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
    animations: [
        trigger('toastAnimation', [
            transition(':enter', [
                style({
                    width: '48px',
                    opacity: 0,
                    transform: 'translate(-50%, -50px)',
                }),
                animate('.3s ease-out', style({ opacity: 1, transform: 'translate(-50%, 0)' })),
                animate('.3s ease', style({ width: '298px' })),
            ]),
            transition(':leave', [
                animate('.3s ease', style({ width: '48px', transform: 'translate(-50%, 0)' })),
                animate('.3s ease', style({ opacity: 0, transform: 'translate(-50%, 50px)' })),
                animate('.3s ease', style({ marginBottom: '-50px' })),
            ]),
        ]),
    ],
})
export class ToastComponent implements OnInit {
    @ViewChildren('toasts') toastElements: ElementRef[];

    toasts: Toast[] = [];

    constructor(private toastService: ToastService) {}

    ngOnInit() {
        this.toastService.show.subscribe((toast: Toast) => {
            this.toasts.push(toast);
            setTimeout(() => {
                this.toasts.shift();
            }, toast.time);
        });
    }
}

<span
    tabindex="{{ focusable ? 0 : -1 }}"
    id="{{ identifier }}"
    [style.width]="width + 'px'"
    [style.height]="height + 'px'"
>
    <svg
        [style.width]="width + 'px'"
        [style.height]="height + 'px'"
        *ngIf="name"
        [ngClass]="{ 'icon--margin-left': marginLeft, 'icon--margin-right': marginRight, 'icon--inline': inline }"
    >
        <use attr.href="#{{ name.toLowerCase() }}"></use>
    </svg>
</span>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from '../../../shared/models/subscription';
import { SubscriptionCancelService } from '../../services/subscription-cancel-service/subscription-cancel-service';
import { UserDetailsModelService } from '../../services/user-details-model-service/user-details-model-service';
import { GetBikeImageString } from '../../subscriptions/subscription';
import * as Sentry from '@sentry/angular';
import { UserDetails } from '../../../shared/models/userdetails';
import { usabillaSurveys } from '../../../shared/models/usabilla';

// extend window for usabilla
declare global {
    interface Window {
        usabilla_live?: (key: string, value: string | {}) => void;
    }
}

const nonMovingServiceCountries = ['IT', 'ES', 'GB', 'AT'];
@Component({
    selector: 'app-cancel-confirmation',
    templateUrl: './cancel-confirmation.component.html',
    styleUrls: ['./cancel-confirmation.component.scss'],
})
export class CancelConfirmationComponent implements OnInit {
    @Input() subscription: Subscription;
    @Output() confirmed: EventEmitter<void> = new EventEmitter<void>();

    error: boolean;
    userDetails: UserDetails;

    constructor(
        private subscriptionService: SubscriptionCancelService,
        private userDetailsModelService: UserDetailsModelService
    ) {}

    ngOnInit() {
        this.userDetailsModelService.getModel().subscribe((userDetails: UserDetails) => {
            this.userDetails = userDetails;
        });
    }

    public getBikeImageString() {
        return GetBikeImageString(this.subscription);
    }

    askConfirmation() {
        this.subscriptionService.cancelSubscription(this.subscription.uuid).subscribe(
            (subscription) => {
                this.userDetailsModelService.setSubscription(subscription);
                const subCountry = this.subscription.businessUnitCountryCultureInfo.slice(-2);
                const usabillaCampaign = nonMovingServiceCountries.includes(subCountry)
                    ? usabillaSurveys.churnNonMovingCountries
                    : usabillaSurveys.churn;

                this.error = false;
                if (window.usabilla_live) {
                    window.usabilla_live('data', {
                        custom: {
                            subscriptionId: this.subscription.uuid,
                            bike: this.subscription.assets[0]?.assetCategory ?? 'unknown',
                            subCountry: this.subscription.cultureInfo,
                            customerId: this.userDetails.customerUserId,
                        },
                    });
                    window.usabilla_live('trigger', usabillaCampaign.manualTrigger);
                }
                this.confirmed.emit();
            },
            (error) => {
                Sentry.captureException(error, {
                    tags: {
                        message: 'Something went wrong when cancelling sub',
                    },
                });
                this.confirmed.emit();
                this.error = true;
            }
        );
    }
}

import { Component } from '@angular/core';
import { EnvironmentService } from '../environment-service/environment.service';
import { AbstractPopupDirective } from '../abstract-popup/abstract-popup.directive';

@Component({
    selector: 'app-popup-registration-timeout',
    templateUrl: './popup-registration-timeout.component.html',
    styleUrls: ['./popup-registration-timeout.component.scss'],
})
export class PopupRegistrationTimeoutComponent extends AbstractPopupDirective {
    constructor(public environmentService: EnvironmentService) {
        super();
    }

    goToDeliveryScreen() {
        this.params.goToDeliveryStepFunction();
        this.close();
    }
}

import { Component, OnInit } from '@angular/core';
import { Subscription } from '../../../shared/models/subscription';
import { PaymentInfo } from '../../../shared/models/paymentinfo';

import { ActivatedRoute, Router } from '@angular/router';
import { UserDetailsModelService } from '../../services/user-details-model-service/user-details-model-service';
import { Asset } from '../../../shared/models/asset';
import { SubscriptionStatus } from '../../../shared/models/subscription-status';
import { PaymentStatus } from '../../../registration/shared/models/payment-status';
import { withLatestFrom } from 'rxjs/operators';
import { ReactiveComponent } from '../../../shared/reactive-component/reactive.component';

@Component({
    selector: 'app-all-subscriptions',
    templateUrl: './all-subscriptions.component.html',
    styleUrls: ['./all-subscriptions.component.scss'],
})
export class AllSubscriptionsComponent extends ReactiveComponent implements OnInit {
    subscriptions: Subscription[] = [];

    selectedSubscription: Subscription = null;
    selectedMobile: boolean = false;
    paymentInfos: PaymentInfo[] = [];
    loading: boolean = true;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private userDetailsModelService: UserDetailsModelService
    ) {
        super();
    }

    /***
     * @description on Init check for subscriptions. Component renders a list of subscriptions only if number of subscriptions is higher than 1
     */
    ngOnInit() {
        this.reactiveXSubs.add(
            this.userDetailsModelService
                .getModel()
                .pipe(withLatestFrom(this.route.data))
                .subscribe(([userDetails, router]) => {
                    this.subscriptions = userDetails.subscriptions;
                    if (router.state === 'subscriptions') {
                        this.checkSubs();
                    } else {
                        this.loading = false;
                    }
                })
        );
    }

    /***
     * @description will redirect user if only one subscription is present
     */
    checkSubs() {
        if (this.subscriptions.length === 1) {
            this.router.navigate(['subscriptions', this.subscriptions[0].uuid]);
        } else {
            this.loading = false;
        }
    }

    selectSubscription(subscription: Subscription) {
        this.selectedSubscription = subscription;
        this.selectedMobile = true;
        this.router.navigate(['subscriptions', subscription.uuid]);
    }

    public getBikeImageString(subscription: Subscription) {
        if (subscription.assets[0]) {
            return subscription.assets[0].bikeImageString;
        }

        return new Asset({}).bikeImageString;
    }

    get subscriptionStatus() {
        if (
            this.subscriptions.length == 0 ||
            !this.selectedSubscription ||
            this.selectedSubscription.status == SubscriptionStatus.RequiresPlanning
        ) {
            // TODO: Show no-bike view for when there are no subscriptions at all.
            if (this.selectedSubscription && this.selectedSubscription.paymentStatus != PaymentStatus.Confirmed) {
                return 'SIGNUP_PROVIDED';
            } else {
                return 'SIGNUP_COMPLETED';
            }
        }

        switch (this.selectedSubscription.status) {
            case SubscriptionStatus.New:
            case SubscriptionStatus.DeliveryScheduled:
                return 'FD_PLANNED';
            case SubscriptionStatus.PickupScheduled:
                return 'PICKUP_PLANNED';
            case SubscriptionStatus.PickedUp:
                return 'PICKUP_DONE';
            default:
                if (this.selectedSubscription.end) {
                    return 'SUB_CANCELLED';
                } else {
                    return 'FD_DONE';
                }
        }
    }
}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

Sentry.init({
    dsn: 'https://2aaa1403892a44c58eded04757a4c54d@o286511.ingest.sentry.io/5750345',
    integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        new Integrations.BrowserTracing({
            tracingOrigins: ['localhost', environment.apiRoot, environment.authentication.identityServer],
            routingInstrumentation: Sentry.routingInstrumentation,
        }),
    ],
    // release: 'commit-sha', TODO: in the future we should set up release and source maps
    enabled: environment.name === 'production' || environment.name === 'staging',
    environment: environment.name,
    // Sample 1% events for tracing and performance analytics
    tracesSampleRate: 0.01,
    // Sample 10% of errors as we are burning through the quota :(
    sampleRate: 0.1,
});

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));

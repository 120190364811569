import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule } from '../../ui/ui.module';
import { ChangePasswordComponent } from './change-password.component';
import { SharedModule } from '../../shared.module';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [ChangePasswordComponent],
    imports: [UiModule, SharedModule, CommonModule, FormsModule],
    exports: [ChangePasswordComponent],
})
export class ChangePasswordModule {}

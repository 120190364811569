// src/app/auth/token.interceptor.ts
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth-service/auth.service';
@Injectable()
export class RequestTokenInterceptor implements HttpInterceptor {
    constructor(public auth: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.auth.getAuthorizationHeaderValue() === null) {
            return next.handle(request);
        }
        // There exists an edge case where a 'signed-in' user will navigate to the sign-up
        // and plan their first delivery.
        // to ensure that the first delivery is set and cleared for the "new" customer (via customer hash and id)
        // and not the signed-in user we skip setting an auth token for those endpoints
        if (request.url.includes('v1/book_swapblock_location') || request.url.includes('v1/clear_swapblock_location')) {
            return next.handle(request);
        } else {
            request = request.clone({
                setHeaders: {
                    Authorization: `${this.auth.getAuthorizationHeaderValue()}`,
                },
            });
            return next.handle(request);
        }
    }
}

export const relocationEvents = {
    changeAddress: 'change address',
    checkAddress: 'change address - check address',
    addressNotFound: 'change address - addressNotFound',
    addressInServiceArea: 'change address - address in service area',
    subscriptionNotSupported: 'change address - subscriptionNotSupported',
    newBikeDesired: 'change address - newBikeDesired',
    selectDateStep: 'change address - date selection',
    bikeTypeStep: 'change address - move bike',
    customerMovesBike: 'change address - move own bike - yes',
    successStep: 'change address - success',
} as const;

export type RelocationEvent = typeof relocationEvents[keyof typeof relocationEvents];

interface RelocationEventParams {
    eventDetails: RelocationEvent;
}

export default function relocationEvent({ eventDetails }: RelocationEventParams) {
    return {
        event: 'click_event',
        click_event_details: eventDetails,
    };
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { GiftCardValidated } from '../../shared/models/giftcard-validated';
import { RedeemRequest } from '../../shared/models/redeem-request';
import { EnrollmentStatus } from '../../registration/shared/models/enrollment-status';

@Injectable()
export class GiftCardService {
    constructor(private http: HttpClient) {}

    AddToSubscription(code: String, enrollmentStatus: EnrollmentStatus) {
        const url = environment.apiRoot + 'v1/giftcard/addsubscription';
        return this.http.post<GiftCardValidated>(url, {
            customerId: enrollmentStatus.customerId,
            customerHash: enrollmentStatus.hash,
            subscriptionId: enrollmentStatus.subscriptionId,
            giftcardCode: code,
        });
    }

    RedeemGiftCard(code: String, subscriptionId: String) {
        const url = environment.apiRoot + 'v1/giftcard/redeem';

        let redeemRequest = new RedeemRequest();
        redeemRequest.subscriptionId = subscriptionId;
        redeemRequest.giftCardCode = code;

        return this.http.post<GiftCardValidated>(url, redeemRequest);
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RowLinkComponent } from './row-link/row-link.component';
import { IconComponent } from './icon/icon.component';
import { IconLoaderComponent } from './icon-loader/icon-loader.component';
import { ShapeComponent } from './shape/shape.component';
import { HtmlPipe } from './pipes/html.pipe';
import { DropdownComponent } from './dropdown/dropdown.component';
import { OverlayComponent } from './overlay/overlay.component';

@NgModule({
    declarations: [
        RowLinkComponent,
        IconComponent,
        IconLoaderComponent,
        ShapeComponent,
        HtmlPipe,
        DropdownComponent,
        OverlayComponent,
    ],
    imports: [CommonModule],
    exports: [
        RowLinkComponent,
        IconComponent,
        ShapeComponent,
        HtmlPipe,
        IconLoaderComponent,
        DropdownComponent,
        OverlayComponent,
    ],
})
export class UiModule {}

import { Asset } from './asset';
import { PaymentStatus } from '../../registration/shared/models/payment-status';
import { SubscriptionStatus } from './subscription-status';

export class Subscription {
    uuid: string;
    name: string;
    businessUnitId: string;
    businessUnitName: string;
    businessUnitCountryCultureInfo: string;
    bikes: object[];
    changePaymentVerificationAmount: number;
    cultureInfo: string;
    currency: string;
    currentSubscriptionPeriodId: string;
    minimunEndDate: Date;
    currentSymbol: string;
    assets: Asset[];
    start: Date;
    end: Date;
    rate: number;
    status: SubscriptionStatus;
    paymentStatus: PaymentStatus;

    constructor(data: Partial<Subscription>) {
        Object.assign(this, data);
        for (let i = 0; i < this.assets.length; i++) {
            this.assets[i] = new Asset(this.assets[i]);
        }
    }
}

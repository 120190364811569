import { Output, EventEmitter, Directive } from '@angular/core';

@Directive()
export abstract class AbstractPopupDirective<T = any> {
    params: T;
    @Output() closeDialog = new EventEmitter();

    close() {
        this.closeDialog.emit(null);
    }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { StepsService } from '../steps-service/steps.service';

@Component({
    selector: 'su-steps',
    templateUrl: './steps.component.html',
    styleUrls: ['./steps.component.scss'],
})
export class StepsComponent {
    @Input() currentStep: number;
    @Output() setStep: EventEmitter<number> = new EventEmitter();

    constructor(private stepsService: StepsService) {}

    get steps() {
        return this.stepsService.steps;
    }

    clickStep(id: number) {
        if (id < this.currentStep) {
            this.setStep.emit(id);
        }
    }
}

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Country } from '../models/country';
import { GtmConfig } from '../google-tag-manager/gtm-config';
import { SharedCookieConfig } from '../shared-cookies/shared-cookie-config';
import { CookieInfoConfig } from '../cookie-consent-service/cookie-info-config';

@Injectable()
export class EnvironmentService {
    private countryKey: String;

    constructor() {}

    isProduction(): boolean {
        return environment.production;
    }

    get countries(): Country[] {
        return environment.countries;
    }

    getCountry() {
        if (!this.countryKey) {
            return this.countries[0];
        }
        return this.countries.find((c) => c.key == this.countryKey) || this.countries[0];
    }

    setCountryKey(countryKey?: String) {
        // We had a bug where the country info would miss from a user profile, better safe than sorry
        if (countryKey) {
            this.countryKey = countryKey;
        } else {
            this.countryKey = this.countries[0].key;
        }
    }

    get swapdeskNumber(): string {
        return this.getCountry().swapdesk;
    }

    get swapdeskNumberUrl(): string {
        return 'tel:' + this.getCountry().swapdesk.replace(/\s/g, '');
    }

    get apiRoot(): string {
        return environment.apiRoot;
    }

    get giftCardRoot(): string {
        return environment.giftCard.apiRoot;
    }

    get identityRoot(): string {
        return environment.authentication.identityServer;
    }

    get gtmConfig(): GtmConfig {
        return environment.googleTagManager;
    }

    get sharedCookieConfig(): SharedCookieConfig {
        return environment.sharedCookies;
    }

    get cookieInfoConfig(): CookieInfoConfig {
        return environment.cookieInfo;
    }
    get facebookPixelId(): string {
        return environment.facebookPixel.id;
    }
}

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UserDetails } from '../../../shared/models/userdetails';
import { HttpClient } from '@angular/common/http';
import { SwapResponse } from '../../../shared/models/swapresponse';
import { EnvironmentService } from '../../../shared/environment-service/environment.service';

@Injectable()
export class UserDetailsModelService {
    private USERDETAILS_KEY = 'userdetails_model';
    private _model: UserDetails;
    private _getUserDetails$: Subject<UserDetails>;

    constructor(private http: HttpClient, private environmentService: EnvironmentService) {}

    /***
     * @description takes any subscription and matches the subscriptionId with the uuid from the model. If present it will replace the sub with the parameter and write it inside the model
     * @param subscription
     */
    setSubscription(subscription: any) {
        const model = this._model;
        for (let i = 0; i < model.subscriptions.length; i++) {
            if (model.subscriptions[i].uuid === subscription.subscriptionId) {
                model.subscriptions[i].end = subscription.end;
                break;
            }
        }
        this.setModel(model);
    }

    setModel(model: UserDetails): void {
        this._model = model;
    }

    getModel(): Observable<UserDetails> {
        if (this._getUserDetails$ == null) {
            this._getUserDetails$ = new Subject<UserDetails>();

            this.http
                .get<SwapResponse>(this.environmentService.apiRoot + 'v2/users')
                .subscribe((response: SwapResponse) => {
                    this._model = new UserDetails(response.result);
                    this._getUserDetails$.next(this._model);
                });
            return this._getUserDetails$;
        } else if (this._model == null) {
            return this._getUserDetails$;
        } else {
            return Observable.create((observer) => {
                observer.next(this._model);
                // observer.complete();
            });
        }
    }

    saveModel(model: UserDetails): Observable<any> {
        this._model = model;
        return this.http.put(this.environmentService.apiRoot + 'v2/users/' + model.customerUserId, model);
    }

    clearStorage() {
        localStorage.removeItem(this.USERDETAILS_KEY);
    }
}

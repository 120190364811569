import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { LoginComponent } from './login/login.component';
import { SignoutComponent } from './signout/signout.component';
import { AuthGuardService } from '../shared/auth-service/auth-guard.service';
import { AccountComponent } from './account.component';
import { SubscriptionDetailsComponent } from './subscriptions/subscription-details/subscription-details.component';
import { ProfileTranslationResolver } from '../shared/translation-resolver/profile-translation.resolver';
import { ReferralComponent } from './referral/referral.component';
import { AddressDetailsComponent } from './relocation/address-details/address-details.component';
import { RelocationTypeComponent } from './relocation/relocation-type/relocation-type.component';
import { RelocationDateComponent } from './relocation/relocation-date/relocation-date.component';
import { RelocationConfirmationComponent } from './relocation/relocation-confirmation/relocation-confirmation.component';

const routes: Routes = [
    { path: 'auth-callback', component: AuthCallbackComponent },
    { path: 'login', component: LoginComponent },
    { path: 'signout', component: SignoutComponent },
    {
        path: '',
        component: AccountComponent,
        resolve: { translations: ProfileTranslationResolver },
        children: [
            {
                path: 'changepassword',
                component: ChangePasswordComponent,
                canActivate: [AuthGuardService],
                data: { state: 'changepassword' },
            },
            {
                path: 'profile',
                component: ProfileComponent,
                canActivate: [AuthGuardService],
                data: { state: 'profile' },
            },
            {
                path: 'referral',
                component: ReferralComponent,
                canActivate: [AuthGuardService],
                data: { State: 'referral' },
            },
            {
                path: 'subscriptions',
                component: SubscriptionsComponent,
                canActivate: [AuthGuardService],
                data: { state: 'subscriptions' },
            },
            {
                path: 'subscriptions/:id',
                component: SubscriptionDetailsComponent,
                canActivate: [AuthGuardService],
                data: { state: 'subscription-id', active: 'bike' },
            },
            {
                path: 'relocation/address',
                canActivate: [AuthGuardService],
                component: AddressDetailsComponent,
            },
            {
                path: 'relocation/type',
                canActivate: [AuthGuardService],
                component: RelocationTypeComponent,
            },
            {
                path: 'relocation/date',
                canActivate: [AuthGuardService],
                component: RelocationDateComponent,
            },
            {
                path: 'relocation/confirmation',
                canActivate: [AuthGuardService],
                component: RelocationConfirmationComponent,
            },
            {
                path: 'subscriptions/:id/voucher',
                component: SubscriptionDetailsComponent,
                canActivate: [AuthGuardService],
                data: { state: 'voucher', active: 'voucher' },
            },
            {
                path: 'subscriptions/:id/drop-off-locations',
                component: SubscriptionDetailsComponent,
                canActivate: [AuthGuardService],
                data: { state: 'drop-off-locations', active: 'drop-off-locations' },
            },
            {
                path: 'subscriptions/:id/cancel',
                component: SubscriptionDetailsComponent,
                canActivate: [AuthGuardService],
                data: { state: 'cancel', active: 'cancel' },
            },
            {
                path: 'subscriptions/:id/changepayment',
                component: SubscriptionDetailsComponent,
                canActivate: [AuthGuardService],
                data: { state: 'changepayment', active: 'changepayment' },
            },
            {
                path: 'subscriptions/:id/changepayment/success',
                component: SubscriptionDetailsComponent,
                canActivate: [AuthGuardService],
                data: { state: 'changepayment', active: 'changepayment' },
            },
            {
                path: 'subscriptions/:id/changepayment/failed',
                component: SubscriptionDetailsComponent,
                canActivate: [AuthGuardService],
                data: { state: 'changepayment', active: 'changepayment' },
            },
            { path: 'home', component: HomeComponent, canActivate: [AuthGuardService], data: { state: 'home' } },
            { path: '', pathMatch: 'full', redirectTo: 'home' },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AccountRoutingModule {}

<div class="wrapper">
    <main class="main--registration" #mainRegistration>
        <topbar></topbar>

        <div class="content" *ngIf="validated">
            <su-steps
                class="container horizontal-steps"
                [currentStep]="currentStep"
                (setStep)="setStep($event)"
            ></su-steps>

            <div class="container">
                <form
                    *ngIf="isCurrentStep('data')"
                    (ngSubmit)="submitForm(personalDetailsForm)"
                    #personalDetailsForm="ngForm"
                >
                    <div class="row">
                        <h1 class="col-12">{{ 'enroll.form.title' | translate }}</h1>
                        <h2 class="col-12 add-margin">{{ 'enroll.form.subtitle' | translate }}</h2>
                    </div>
                    <div class="row">
                        <h3 class="col-12">{{ 'enroll.form.personal' | translate }}</h3>
                        <form-field-container class="col-12">
                            <input
                                form-field
                                type="text"
                                placeholder="{{ 'enroll.form.firstname' | translate }}"
                                [(ngModel)]="enrollment.firstname"
                                [pattern]="lettersDashAndSpace"
                                name="firstname"
                                required
                            />
                        </form-field-container>
                        <form-field-container class="col-4">
                            <input
                                form-field
                                type="text"
                                placeholder="{{ 'enroll.form.infix' | translate }}"
                                [(ngModel)]="enrollment.lastnamePrefix"
                                [pattern]="lettersDashAndSpace"
                                name="lastnamePrefix"
                            />
                        </form-field-container>
                        <form-field-container class="col-8">
                            <input
                                form-field
                                type="text"
                                placeholder="{{ 'enroll.form.lastname' | translate }}"
                                [(ngModel)]="enrollment.lastname"
                                [pattern]="lettersDashAndSpace"
                                name="lastname"
                                required
                            />
                        </form-field-container>
                        <div class="col-12">
                            <div class="date-dropdowns">
                                <p>{{ 'enroll.form.dateofbirth' | translate }}</p>
                                <div>
                                    <select [(ngModel)]="dateOfBirth.day" (change)="updateDateOfBirth()" name="Day">
                                        <option value="">{{ 'enroll.form.day' | translate }}</option>
                                        <option *ngFor="let in of getDays(); let i = index" value="{{ i + 1 }}">
                                            {{ i + 1 }}
                                        </option>
                                    </select>
                                    <select [(ngModel)]="dateOfBirth.month" (change)="updateDateOfBirth()" name="Month">
                                        <option value="">{{ 'enroll.form.month' | translate }}</option>
                                        <option *ngFor="let in of getMonths(); let i = index" value="{{ i + 1 }}">
                                            {{ 'months.' + (i + 1) | translate }}
                                        </option>
                                    </select>
                                    <select [(ngModel)]="dateOfBirth.year" (change)="updateDateOfBirth()" name="Year">
                                        <option value="">{{ 'enroll.form.year' | translate }}</option>
                                        <option *ngFor="let i of getYears()" value="{{ i }}">{{ i }}</option>
                                    </select>
                                </div>
                                <input
                                    type="text"
                                    tabIndex="-1"
                                    placeholder="{{ 'enroll.form.dateofbirth' | translate }}"
                                    [(ngModel)]="enrollment.dateOfBirth"
                                    name="dateOfBirth"
                                    required
                                    readonly
                                />
                            </div>
                        </div>
                        <div class="col-12 radiobutton-container">
                            <div>
                                <input
                                    type="radio"
                                    placeholder="{{ 'enroll.form.gender' | translate }}"
                                    name="gender"
                                    [(ngModel)]="enrollment.gender"
                                    id="gender-female"
                                    [value]="2"
                                    required
                                /><label for="gender-female">{{ 'enroll.form.female' | translate }}</label>
                            </div>
                            <div>
                                <input
                                    type="radio"
                                    placeholder="{{ 'enroll.form.gender' | translate }}"
                                    name="gender"
                                    [(ngModel)]="enrollment.gender"
                                    id="gender-male"
                                    [value]="1"
                                    required
                                /><label for="gender-male">{{ 'enroll.form.male' | translate }}</label>
                            </div>
                            <div>
                                <input
                                    type="radio"
                                    placeholder="{{ 'enroll.form.gender' | translate }}"
                                    name="gender"
                                    [(ngModel)]="enrollment.gender"
                                    id="gender-other"
                                    [value]="3"
                                    required
                                /><label for="gender-other">{{ 'enroll.form.other' | translate }}</label>
                            </div>
                        </div>
                        <form-field-container class="col-12">
                            <div class="tooltip-button" (click)="toolTipOpened = !toolTipOpened"><span>?</span></div>
                            <div
                                class="tooltip-window"
                                (click)="toolTipOpened = false"
                                [ngClass]="{ opened: toolTipOpened }"
                            >
                                <span>{{ 'enroll.form.lengthtooltip' | translate }}</span>
                            </div>
                            <input
                                form-field
                                type="number"
                                numbersOnly
                                placeholder="{{ 'enroll.form.lengthcm' | translate }}"
                                pattern="^([1][0-9][0-9]|[2][5][0]|[2][0-4][0-9])$"
                                min="120"
                                max="250"
                                required
                                [(ngModel)]="enrollment.height"
                                name="height"
                                (blur)="toolTipOpened = false"
                                (ngModelChange)="onHeightInputChange($event)"
                            />
                        </form-field-container>
                        <form-field-container class="col-12">
                            <div
                                @sizeAlertPopUp
                                class="size-alert-popup mt-1 p-1"
                                *ngIf="shouldShowHeightAlertPopUp$ | async"
                            >
                                <p>
                                    {{ 'enroll.form.sizeAlertPopUpMessage' | translate }}
                                </p>
                            </div>
                        </form-field-container>
                        <form-field-container *ngIf="subscriptionType?.country === 'IT'" class="col-12">
                            <div class="tooltip-button" (click)="fiscalCodeToolTipOpened = !fiscalCodeToolTipOpened">
                                <span>?</span>
                            </div>
                            <div
                                class="tooltip-window"
                                (click)="fiscalCodeToolTipOpened = false"
                                [ngClass]="{ opened: fiscalCodeToolTipOpened }"
                            >
                                <span>{{ 'enroll.form.fiscalcodetooltip' | translate }}</span>
                            </div>
                            <input
                                form-field
                                type="text"
                                placeholder="{{ 'enroll.form.fiscalcode' | translate }}"
                                codiceFiscale
                                [codiceFiscaleSurname]="enrollment.lastname"
                                [codiceFiscaleName]="enrollment.firstname"
                                [codiceFiscaleDate]="enrollment.dateOfBirth"
                                [codiceFiscaleGender]="enrollment.gender"
                                required
                                [(ngModel)]="enrollment.fiscalcode"
                                name="fiscalcode"
                                (blur)="fiscalCodeToolTipOpened = false"
                            />
                        </form-field-container>
                    </div>
                    <div class="row">
                        <h3 class="col-12">{{ 'enroll.form.address' | translate }}</h3>
                        <form-field-container class="col-12">
                            <input
                                form-field
                                type="text"
                                placeholder="{{ 'enroll.form.streetname' | translate }}"
                                required
                                [(ngModel)]="enrollment.street"
                                pattern="{{ lettersNumbersDashQuoteDotAndWhiteSpace }}"
                                name="street"
                            />
                        </form-field-container>
                        <form-field-container class="col-6">
                            <input
                                form-field
                                type="text"
                                placeholder="{{ 'enroll.form.housenumber' | translate }}"
                                pattern="^([^]{1,20})$"
                                required
                                [(ngModel)]="enrollment.houseNumber"
                                pattern="{{ numberNotZero }}"
                                name="houseNumber"
                            />
                        </form-field-container>
                        <form-field-container class="col-6">
                            <input
                                form-field
                                type="text"
                                placeholder="{{ 'enroll.form.housenumbersuffix' | translate }}"
                                pattern="^([^]{0,50})$"
                                [(ngModel)]="enrollment.houseNumberSuffix"
                                pattern="{{ lettersNumbersDashAndWhiteSpaceMax12Length }}"
                                name="houseNumberSuffix"
                            />
                        </form-field-container>
                        <form-field-container class="col-4">
                            <input
                                form-field
                                type="text"
                                placeholder="{{ 'enroll.form.zipcode' | translate }}"
                                pattern="{{ postalCodePattern }}"
                                required
                                [(ngModel)]="enrollment.postalCode"
                                name="postalCode"
                            />
                        </form-field-container>
                        <form-field-container class="col-8">
                            <input
                                form-field
                                type="text"
                                placeholder="{{ 'enroll.form.city' | translate }}"
                                required
                                [(ngModel)]="enrollment.city"
                                pattern="{{ lettersNumbersDashQuoteDotAndWhiteSpace }}"
                                name="city"
                            />
                        </form-field-container>
                        <div class="col-12 select-container select-container--inline">
                            <select name="country" [(ngModel)]="subscriptionType.country" disabled>
                                <option *ngFor="let country of countries" value="{{ country.key }}">
                                    {{ 'countries.' + country.key | translate }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <h3 class="col-12">{{ 'enroll.form.contact' | translate }}</h3>
                        <form-field-container class="col-12">
                            <input
                                form-field
                                type="email"
                                placeholder="{{ 'enroll.form.emailaddress' | translate }}"
                                required
                                [(ngModel)]="enrollment.email"
                                email
                                pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$"
                                name="email"
                            />
                        </form-field-container>
                        <div class="row col-12 no-gutters phone-number-row">
                            <div class="col-5">
                                <select
                                    [(ngModel)]="phoneNumberPrefix"
                                    name="telephonePrefix"
                                    (change)="updatePhoneNumber()"
                                    autocomplete="tel-country-code"
                                >
                                    <option *ngFor="let prefix of phoneNumberPrefixes" [value]="prefix.dialPrefix">
                                        {{ prefix.dialPrefix }} ({{ prefix.isoCode }})
                                    </option>
                                </select>
                            </div>

                            <div class="col-7">
                                <form-field-container class="phone-number">
                                    <input
                                        form-field
                                        ltrim-zeros
                                        autocomplete="tel-national"
                                        type="tel"
                                        pattern="^[0-9\-\+ ]*$"
                                        (change)="updatePhoneNumber()"
                                        required
                                        name="telephoneNumber1"
                                        placeholder="{{ 'enroll.form.phonenumber' | translate }}"
                                        [(ngModel)]="phoneNumberBody"
                                    />
                                </form-field-container>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="subscriptionType?.isStudentOffer">
                        <h3 class="col-12">{{ 'enroll.form.student_data' | translate }}</h3>
                        <form-field-container class="col-12">
                            <input
                                form-field
                                type="number"
                                placeholder="{{ 'enroll.form.studentstartyear' | translate }}"
                                required
                                numbersOnly
                                min="1900"
                                max="2100"
                                [(ngModel)]="enrollment.studentStartYear"
                                name="year"
                            />
                        </form-field-container>
                    </div>
                    <div class="row">
                        <h3 class="col-12">{{ 'enroll.form.remark' | translate }}</h3>
                        <form-field-container class="col-12">
                            <input
                                form-field
                                type="text"
                                placeholder="{{ 'enroll.form.remark' | translate }}"
                                name="deliveryRemarks"
                                [(ngModel)]="enrollment.deliveryRemarks"
                            />
                        </form-field-container>
                    </div>
                    <div class="row missing-fields" *ngIf="missingFields.length">
                        <h1 class="col-12">{{ 'enroll.form.incomplete' | translate }}</h1>
                        <span class="col-12" *ngFor="let element of errorFields"
                            >{{ 'enroll.form.error_thefield' | translate }}
                            <a href="javascript:void(0);" (click)="scrollTo(element)">{{
                                (element.placeholder ? element.placeholder : element.title) | lowercase
                            }}</a>
                            {{ 'enroll.form.error_incorrectly' | translate }}</span
                        >
                        <span class="col-12" *ngFor="let element of missingFields"
                            >{{ 'enroll.form.error_thereisno' | translate }}
                            <a href="javascript:void(0);" (click)="scrollTo(element)">{{
                                (element.placeholder ? element.placeholder : element.title) | lowercase
                            }}</a>
                            {{ 'enroll.form.error_filled' | translate }}</span
                        >
                    </div>

                    <div class="row">
                        <div class="col-12 add-margin">
                            <input
                                type="checkbox"
                                required
                                [(ngModel)]="acceptTermsPrivacy"
                                id="terms-checkbox"
                                name="terms"
                            />
                            <label class="checkbox-spacing" for="terms-checkbox">
                                {{ 'enroll.payment.agreewiththe_terms' | translate }}
                                <a [href]="termsUrl" target="_blank">{{ 'enroll.payment.terms' | translate }}</a>
                                {{ 'enroll.payment.andwiththe' | translate }}
                                <a [href]="privacyUrl" target="_blank">{{ 'enroll.payment.privacy' | translate }}</a>
                                {{ 'enroll.payment.additionalConfirm' | translate }}
                            </label>
                            <input
                                type="checkbox"
                                required
                                [(ngModel)]="acceptWinterOfferTermsPrivacy"
                                *ngIf="isSpecialOfferSubscription"
                                id="winterOffer-terms-checkbox"
                                name="winterOfferterms"
                            /><label
                                class="checkbox-spacing"
                                *ngIf="isSpecialOfferSubscription"
                                for="winterOffer-terms-checkbox"
                            >
                               {{ 'enroll.payment.winterOffer.agreewiththe_terms' | translate }}
                                <a [href]="specialOfferTermsUrl" target="_blank">
                                    {{ 'enroll.payment.' + (isPedalPromo ? 'pedal' : 'power') + 'Offer.terms' | translate }}
                                </a>
                            </label>
                            <input
                                type="checkbox"
                                [(ngModel)]="enrollment.hasGivenGdprConsentForMarketing"
                                id="gdpr-checkbox"
                                name="gdprOptIn"
                            />
                            <label class="checkbox-spacing" for="gdpr-checkbox">
                                {{ 'enroll.gdpr.optin' | translate }}
                            </label>
                            <input
                                type="checkbox"
                                required
                                *ngIf="needParentPermission && !isEProduct()"
                                [(ngModel)]="parentPermission"
                                id="parentPermission-checkbox"
                                name="parentPermission"
                            /><label *ngIf="needParentPermission && !isEProduct()" for="parentPermission-checkbox">{{
                                'enroll.payment.parent_permission' | translate
                            }}</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div *ngIf="needParentPermission && isEProduct()" class="alert alert-danger" role="alert">
                                {{ 'enroll.form.ebikeAgeRestriction' | translate }}
                            </div>
                        </div>
                        <div class="col-12">
                            <button
                                class="btn btn-orange"
                                type="submit"
                                *ngIf="steps.length === 3"
                                data-mettest="exp02_v2"
                                [disabled]="!canSubmitForm || loading"
                                data-met="Signup - Personal details - CTA"
                            >
                                <i *ngIf="loading" class="fa fa-spin fa-spinner" aria-hidden="true"></i>
                                <span *ngIf="!loading">{{ 'enroll.button_next' | translate }}</span>
                            </button>
                            <button
                                class="btn btn-orange"
                                type="submit"
                                *ngIf="steps.length !== 3"
                                data-mettest="exp02_v1"
                                [disabled]="!canSubmitForm || loading"
                                data-met="Signup - Personal details - CTA"
                            >
                                <i *ngIf="loading" class="fa fa-spin fa-spinner" aria-hidden="true"></i>
                                <span *ngIf="!loading">{{ 'enroll.button_next' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            <!-- Return ngIf mechanic once A/b test 4439 is finished -->
            <div class="container step--hidden" [ngClass]="{ 'step--shown': isCurrentStep('delivery') }">
                <div class="row">
                    <div class="col-12">
                        <h1 class="mb-4">{{ 'enroll.delivery.title' | translate }}</h1>
                        <p *ngIf="isDeliverySubtitleVisible" class="mb-4" innerHTML="{{ 'enroll.delivery.subtitle' | translate }}"></p>
                        <div
                            class="select-frame"
                            [ngClass]="{ 'select-frame--active': selectedFrame == 1 }"
                            (click)="selectFrame(1)"
                        >
                            <input type="radio" name="store" [checked]="selectedFrame == 1" />
                            <label>
                                <h3 class="select-frame__title select-frame__title--green">
                                    {{ 'enroll.delivery.pick_up_in_store' | translate }}
                                </h3>
                            </label>
                            <div
                                class="select-frame__store-list"
                                [@toggleAnimation]="selectedFrame == 1 ? 'show' : 'hide'"
                            >
                                <div class="select-frame__store" *ngFor="let store of storeList; index as i">
                                    <p class="select-frame__store-title">{{ store.city }}</p>
                                    <h6 class="select-frame__address">{{ store.street }} {{ store.houseNumber }}</h6>
                                    <h6 class="select-frame__address">{{ store.zipcode }} {{ store.city }}</h6>
                                    <button
                                        class="btn select-frame__store-button"
                                        [ngClass]="{ 'select-frame__store-button--chosen': selectedStore === i }"
                                        (click)="selectStore(i, $event)"
                                        *ngIf="storeList.length > 1"
                                    >
                                        {{
                                            selectedStore !== '' && selectedStore == i
                                                ? ('enroll.delivery.chosen' | translate)
                                                : ('enroll.delivery.choose' | translate)
                                        }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div
                            class="select-frame"
                            *ngIf="!isIntroweekSubscription"
                            [ngClass]="{ 'select-frame--active': selectedFrame == 2 }"
                            (click)="selectFrame(2)"
                        >
                            <input type="radio" name="store" [checked]="selectedFrame == 2" />
                            <label class="mb-0">
                                <h3 class="select-frame__title">
                                    {{ 'enroll.delivery.have_it_delivered' | translate }}
                                </h3>
                            </label>
                            <div
                                class="select-frame__contact-box"
                                [@toggleAnimation]="selectedFrame == 2 ? 'show' : 'hide'"
                            >
                                <p class="select-frame__contact">
                                    {{ 'enroll.delivery.contact_me_for_appointment' | translate }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="selectedStore != null" @delayedShow>
                    <div class="col-12">
                        <app-slot-picker
                            [store]="storeList[selectedStore]"
                            (swapBlockSelected)="selectSwapblock($event)"
                            [currentBooking]="currentBooking"
                        ></app-slot-picker>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button
                            class="btn btn-orange"
                            [disabled]="!isDeliveryValid() || loading"
                            type="submit"
                            *ngIf="steps.length === 3"
                            data-mettest="exp02_v2"
                            data-met="Signup - Delivery details - CTA"
                            [attr.data-metdeliverychoice]="getMetDeliveryChoice()"
                            (click)="submitDelivery()"
                        >
                            <i *ngIf="loading" class="fa fa-spin fa-spinner" aria-hidden="true"></i>
                            <span *ngIf="!loading">{{ 'enroll.button_next' | translate }}</span>
                        </button>
                        <button
                            class="btn btn-orange"
                            [disabled]="!isDeliveryValid() || loading"
                            type="submit"
                            *ngIf="steps.length !== 3"
                            data-mettest="exp02_v1"
                            data-met="Signup - Delivery details - CTA"
                            [attr.data-metdeliverychoice]="getMetDeliveryChoice()"
                            (click)="submitDelivery()"
                        >
                            <i *ngIf="loading" class="fa fa-spin fa-spinner" aria-hidden="true"></i>
                            <span *ngIf="!loading">{{ 'enroll.button_next' | translate }}</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="container" *ngIf="isCurrentStep('check')">
                <div class="row">
                    <h1 class="col-12 add-margin">
                        {{ 'enroll.check.title' | translate }}
                    </h1>
                </div>
                <div class="row subscription-overview">
                    <h3 class="col-12">
                        {{ 'bikes.' + subscriptionType?.assetCategoryCode | translate }}
                        <span *ngIf="subscriptionType?.isStudentOffer">{{ 'enroll.check.student' | translate }}</span>
                        <span *ngIf="subscriptionType?.hasBasket"
                            ><br />{{ 'enroll.check.withbasket' | translate }}</span
                        >
                    </h3>
                    <div class="col-12">
                        <img src="/assets/bikes/{{ subscriptionType?.assetCategoryCode }}.jpg" />
                        <div *ngIf="!isThreeTierPricingSubscription">
                            <p
                                [ngClass]="{
                                    'strike-through': isSpecialOfferSubscription || isBackToSchoolCampaignSubscription,
                                    'is-special-price': isSpecialOfferSubscription || isBackToSchoolCampaignSubscription
                                }"
                            >
                                {{ subscriptionType?.finalRate | money }} {{ 'enroll.check.permonth' | translate }}
                            </p>
                            <p *ngIf="isSpecialOfferSubscription || isBackToSchoolCampaignSubscription">
                                {{ subscriptionType?.initialRate | money }} {{ 'enroll.check.permonth' | translate }}*
                            </p>
                        </div>
                        <div *ngIf="isThreeTierPricingSubscription">
                            <p
                                *ngIf="subscriptionType.maximumTypeDuration === 6 || subscriptionType.maximumTypeDuration === 12"
                                [ngClass]="{
                                    'is-special-price': true
                                }"
                            >
                                {{ 'enroll.check.three_tier_pricing.' + subscriptionType.maximumTypeDuration | translate }}
                            </p>
                            <p>
                                {{ subscriptionType?.initialRate | money }} {{ 'enroll.check.permonth' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="row-split">&nbsp;</div>
                    </div>
                </div>
                <div class="row address">
                    <div class="col-12 flex-container">
                        <h3 class="flex-item">{{ 'enroll.check.living_address' | translate }}</h3>
                        <a
                            href="javascript:void(0)"
                            (click)="backToEdit()"
                            class="flex-item"
                            data-met="Signup - Check details and Subscription - Change Address"
                            >{{ 'enroll.check.edit' | translate }}</a
                        >
                    </div>
                    <span class="col-12"
                        >{{ enrollment.firstname | titlecase }} {{ enrollment.lastnamePrefix }}
                        {{ enrollment.lastname | titlecase }}</span
                    >
                    <span class="col-12"
                        >{{ enrollment.street | titlecase }} {{ enrollment.houseNumber }}
                        {{ enrollment.houseNumberSuffix ? '-' : '' }} {{ enrollment.houseNumberSuffix }}</span
                    >
                    <span class="col-12"
                        >{{ enrollment.postalCode | uppercase }} {{ enrollment.city | titlecase }}</span
                    >
                    <span class="col-12">{{ 'countries.' + enrollment.country | translate }}</span>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="row-split">&nbsp;</div>
                    </div>
                </div>
                <div class="row delivery" *ngIf="!isStoreDelivery()">
                    <h3 class="col-12">{{ 'enroll.check.delivery' | translate }}</h3>
                    <p class="col-12">
                        {{ 'enroll.check.delivery_order' | translate }}
                    </p>
                </div>
                <div class="row" *ngIf="isStoreDelivery() && selectedSwapblock && selectedSwapblock !== true">
                    <div class="col-12 flex-container">
                        <h3 class="flex-item">{{ 'enroll.check.your_appointment' | translate }}</h3>
                        <a
                            href="javascript:void(0)"
                            (click)="backToDelivery()"
                            class="flex-item"
                            data-met="Signup - Check details and Subscription - Change Appointment"
                            >{{ 'enroll.check.edit' | translate }}</a
                        >
                    </div>
                    <p class="col-12">
                        {{ storeList[selectedStore].street }} {{ storeList[selectedStore].houseNumber }}<br />
                        {{ storeList[selectedStore].zipcode }} {{ storeList[selectedStore].city }}<br />
                        {{ 'weekDays.' + selectedSwapblock.date.getUTCDay() | translate }}
                        {{ selectedSwapblock.date.getUTCDate() }}
                        {{ 'months.' + (selectedSwapblock.date.getUTCMonth() + 1) | translate }}<br />
                        {{ 'enroll.check.between' | translate }}: {{ selectedSwapblock.startTimeString }} -
                        {{ selectedSwapblock.endTimeString }}
                    </p>
                </div>
                <div class="row" *ngIf="subscriptionType?.country === 'FR'">
                    <div class="col-12">
                        <div class="row-split">&nbsp;</div>
                    </div>
                </div>
                <div class="row" *ngIf="subscriptionType?.country === 'FR'">
                    <p class="col-12 agreed-terms">
                        {{ 'enroll.check.haveAgreed' | translate }}
                        <a [href]="termsUrl" target="_blank"> {{ 'enroll.check.terms' | translate }} </a>.
                        {{ 'enroll.check.termsAgreement' | translate }}
                    </p>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div id="id-verification" class="alert alert-warning" role="alert">
                            {{
                                (isEProduct()
                                    ? (isKycRequired$ | async)
                                        ? 'enroll.idcheck.onlineVerification.eproduct'
                                        : 'enroll.idcheck.eproduct'
                                    : 'enroll.idcheck.general'
                                ) | translate
                            }}
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="selectedSwapblock !== true && !isSpecialOfferSubscription">
                    <div class="col-12">
                        <div class="row-split">&nbsp;</div>
                    </div>
                </div>
                <div *ngIf="isSpecialOfferSubscription" class="row">
                    <div class="col-12">
                        <div class="row-split">&nbsp;</div>
                    </div>
                    <div class="col-12">
                        <p class="special-offer">{{ 'enroll.winterOffer.withinFirstWeekAlert' | translate }}</p>
                    </div>
                    <div class="col-12">
                        <div class="row-split">&nbsp;</div>
                    </div>
                </div>
                <div class="row giftcard">
                    <h3 class="col-12">{{ 'enroll.giftcard.title' | translate }}</h3>
                    <div class="col-12">
                        <div class="row no-margin">
                            <form-field-container class="col-md-7">
                                <input
                                    form-field
                                    type="text"
                                    placeholder="{{ 'enroll.giftcard.code' | translate }}"
                                    [(ngModel)]="enrollment.giftCardCode"
                                    pattern="{{ lettersNumbersDashQuoteDotAndWhiteSpace }}"
                                    name="giftCardCode"
                                />
                            </form-field-container>
                            <div class="col-md-5">
                                <button
                                    class="btn btn-orange btn-small"
                                    type="button"
                                    (click)="addGiftCard()"
                                    *ngIf="enrollment.giftCardCode"
                                >
                                    {{ 'enroll.giftcard.validate' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <p class="col-12 warning" *ngIf="credit && enrollment.giftCardCode">
                        {{ 'enroll.giftcard.credittext' | translate }} {{ credit | money }}
                        {{ 'enroll.giftcard.worth' | translate }}
                    </p>
                    <p class="col-12 warning" *ngIf="invalidvoucher && enrollment.giftCardCode">
                        {{ 'enroll.giftcard.notvalid' | translate }}
                    </p>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="row-split">&nbsp;</div>
                    </div>
                </div>
                <div class="row order-overview-lines">
                    <div class="col-12">
                        <span>
                            {{ 'bikes.' + subscriptionType?.assetCategoryCode | translate }}
                            <span *ngIf="subscriptionType?.isStudentOffer">
                                {{ 'enroll.check.student' | translate }}
                            </span>
                        </span>
                        <div *ngIf="!isThreeTierPricingSubscription">
                            <span
                                [ngClass]="{
                                    'strike-through': isSpecialOfferSubscription || isBackToSchoolCampaignSubscription
                                }"
                            >
                                {{ subscriptionType?.finalRate | money }} {{ 'enroll.check.permonth' | translate }}
                            </span>
                            <span class="is-special-price-footer" *ngIf="isSpecialOfferSubscription || isBackToSchoolCampaignSubscription">
                                {{ subscriptionType?.initialRate | money }} {{ 'enroll.check.permonth' | translate }}*
                            </span>
                        </div>
                        <div *ngIf="isThreeTierPricingSubscription">
                            <span class="is-special-price-footer">
                                {{ subscriptionType?.initialRate | money }} {{ 'enroll.check.permonth' | translate }}
                            </span>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="label">
                        <span>{{ 'enroll.check.offer' | translate }}</span
                        ><span>{{ label }}</span>
                    </div>
                    <div
                        class="col-12"
                        *ngIf="credit && enrollment.giftCardCode"
                        (click)="giftcardToolTipOpened = !giftcardToolTipOpened"
                    >
                        <div
                            class="tooltip-window"
                            (click)="giftcardToolTipOpened = false"
                            [ngClass]="{ opened: giftcardToolTipOpened }"
                        >
                            <span>{{ 'enroll.giftcard.tooltip' | translate }}</span>
                        </div>
                        <span class="blue">{{ 'enroll.giftcard.summarytitle' | translate }}</span
                        ><span>{{ -credit | money }}</span>
                    </div>
                    <div class="col-12" *ngIf="!isStoreDelivery()">
                        <span>{{ 'enroll.check.delivery' | translate }}</span
                        ><span>{{ 'enroll.check.free' | translate }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="row-split">&nbsp;</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 order-total-line">
                        <h2 class="add-margin">{{ 'enroll.check.total' | translate }}</h2>
                        <h2 *ngIf="!isThreeTierPricingSubscription">
                            {{
                                (isSpecialOfferSubscription || isBackToSchoolCampaignSubscription
                                    ? subscriptionType?.initialRate
                                    : subscriptionType?.finalRate
                                ) | money
                            }}
                            {{ 'enroll.check.permonth' | translate }}
                        </h2>
                        <h2 *ngIf="isThreeTierPricingSubscription">
                            {{
                                (subscriptionType?.initialRate) | money
                            }}
                            {{ 'enroll.check.permonth' | translate }}
                        </h2>
                    </div>

                    <div class="col-12" *ngIf="isInvertedRateSubscription">
                        {{ 'enroll.after_earlybird_you_pay' | translate }} {{ subscriptionType?.initialRate | money }}
                        {{ 'enroll.check.permonth' | translate }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button
                            class="btn btn-orange"
                            (click)="goToPaymentPage()"
                            data-met="Signup - Check details and Subscription - CTA"
                        >
                            {{ 'enroll.button_next' | translate }}
                        </button>
                    </div>
                </div>
            </div>

            <div class="container" *ngIf="isCurrentStep('payment')">
                <div class="row">
                    <div class="col-12 add-margin">
                        <h1>{{ 'enroll.payment.title' | translate }}</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 add-margin">
                        <h3>{{ 'enroll.payment.select_paymentmethod' | translate }}</h3>
                        <span class="payment-warning" *ngIf="!isPrepaid">{{
                            'enroll.payment.nothing_will_be_billed'
                                | translate: ('asset_generic.' + subscriptionType.assetCategoryCode | translate)
                        }}</span>
                        <span *ngIf="isPrepaid">
                            {{ 'enroll.payment.subtext_prepay' | translate
                            }}{{ subscriptionType?.verificationAmount | money
                            }}{{ 'enroll.payment.subtext_prepay2' | translate
                            }}{{
                                isSpecialOfferSubscription
                                    ? ('enroll.payment.winterOffer.extraWord' | translate)
                                    : (subscriptionType?.finalRate | money)
                            }}{{ 'enroll.payment.subtext_prepay3' | translate }}
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 add-margin">
                        <app-payment-select
                            [allowedPaymentMethods]="subscriptionType?.paymentMethods"
                            [countryName]="subscriptionType?.country"
                            [bottomText]="
                                (subscriptionType?.verificationAmount | money) +
                                ' ' +
                                ((isPrepaid ? 'enroll.payment.upfront' : 'enroll.payment.verification') | translate)
                            "
                        >
                        </app-payment-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button
                            class="btn btn-orange"
                            type="submit"
                            (click)="submitPayment()"
                            *ngIf="steps.length !== 3"
                            data-mettest="exp02_v1"
                            [disabled]="!canSubmitPayment"
                            data-met="Signup - Payment started- CTA"
                        >
                            <i *ngIf="loading" class="fa fa-spin fa-spinner" aria-hidden="true"></i>
                            <span *ngIf="!loading">{{ 'enroll.payment.button_next' | translate }}</span>
                        </button>
                        <button
                            class="btn btn-orange"
                            type="submit"
                            (click)="submitPayment()"
                            *ngIf="steps.length === 3"
                            data-mettest="exp02_v2"
                            [disabled]="!canSubmitPayment"
                            data-met="Signup - Payment started- CTA"    
                        >
                            <i *ngIf="loading" class="fa fa-spin fa-spinner" aria-hidden="true"></i>
                            <span *ngIf="!loading">{{ 'enroll.payment.button_next' | translate }}</span>
                        </button>
                    </div>
                </div>
            </div>

            <app-backbutton (click)="goBack()">{{ 'enroll.button_back' | translate }}</app-backbutton>

            <div class="container" *ngIf="isCurrentStep('check') && isSpecialOfferSubscription">
                <p class="winter-offer-terms">
                    {{ specialOfferTermsByCountry | translate }}
                    <a [href]="specialOfferTermsUrl" target="_blank">
                        {{ 'enroll.payment.' + (isPedalPromo ? 'pedal' : 'power') + 'Offer.terms' | translate }}
                    </a>
                </p>
            </div>

            <div class="container contact-link" *ngIf="paymentFailed">
                <div class="row">
                    <h3>{{ 'enroll.contact_title' | translate }}</h3>
                    <a href="{{ environmentService.swapdeskNumberUrl }}">{{ environmentService.swapdeskNumber }}</a>
                </div>
            </div>
        </div>
    </main>

    <aside>
        <su-steps class="vertical" [currentStep]="currentStep" (setStep)="setStep($event)"></su-steps>
    </aside>
</div>

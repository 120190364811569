<div class="container-fluid margin-extralarge">
    <h1>{{ 'relocation.confirm.title' | translate }}</h1>
    <p>
        {{ 'relocation.confirm.description' | translate }}
    </p>
    <div class="row">
        <div class="col">
            <h3>{{ 'relocation.new_address' | translate }}</h3>
            <p>{{ addressDetails.street }} {{ addressDetails.houseNumber }}</p>
            <p>{{ addressDetails.postalCode }}</p>
            <p>{{ addressDetails.city }}</p>
        </div>
        <div class="col">
            <h3>{{ 'relocation.starting_date' | translate }}</h3>
            <p>{{ relocationDate.date }}</p>
        </div>
    </div>
    <button class="btn btn-green" (click)="returnToProfile()">
        {{ 'account.profile.title' | translate }}
    </button>
</div>

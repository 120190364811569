<h2 class="margin-medium">{{ 'account.cancel_subscription.undo_cancelled_subscription' | translate }}</h2>
<p class="body-text margin-medium">
    {{ 'account.cancel_subscription.undo_cancelled_subscription_text' | translate }}
</p>

<button
    class="btn btn-primary btn-block"
    data-met="Subscription - Confirm undo cancellation - CTA"
    (click)="confirm()"
    [disabled]="loading"
>
    <span [hidden]="loading">{{ 'account.cancel_subscription.undo_cancelled_subscription' | translate }}</span>
    <span [hidden]="!loading"><i class="fa fa-spin fa-spinner"></i></span>
</button>

<p class="error-message" *ngIf="error">
    {{ 'account.cancel_subscription.undo_cancelled_subscription_error' | translate }}
</p>

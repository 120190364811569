import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from './shared/translate-pipe/translate.pipe';
import { FormFieldComponent } from './shared/form-field/form-field.component';
import { FormFieldContainerComponent } from './shared/form-field-container/form-field-container.component';
import { PasswordRequirementComponent } from './shared/password-requirement/password-requirement.component';
import { MoneyPipe } from './shared/money-pipe/money.pipe';
import { PaymentSelectComponent } from './shared/payment-select/payment-select.component';
import { PopupRegistrationGeneralComponent } from './shared/popup-registration-general/popup-registration-general.component';
import { PopupPaymentFailedComponent } from './shared/popup-payment-failed/popup-payment-failed.component';
import { PopupRegistrationExistsComponent } from './shared/popup-registration-exists/popup-registration-exists.component';
import { DematrializeErrorPipe } from './shared/pipes/dematerialize-error.pipe';
import { DematrializeValuePipe } from './shared/pipes/dematerialize-value.pipe';
import { ScaleComponent } from './shared/scale/scale.component';
import { ToastComponent } from './shared/toast-component/toast.component';
import { PopupBookingfailedComponent } from './shared/popup-bookingfailed/popup-bookingfailed.component';
import { PopupRegistrationTimeoutComponent } from './shared/popup-registration-timeout/popup-registration-timeout.component';
import { PopupEmailComponent } from './shared/popup-email/popup-email.component';
import { IbanValidatorDirective } from './shared/iban-validator/iban-validator-directive';
import { IbanFieldContainerComponent } from './shared/iban-field-container/iban-field-container.component';
import { PopupUndoCancelledSubscriptionComponent } from './shared/popup-undo-cancelled-subscription/popup-undo-cancelled-subscription.component';
import { BackbuttonComponent } from './registration/shared/backbutton/backbutton.component';
import { PopupReSignupComponent } from './shared/popup-re-signup/popup-re-signup.component';
import { ReSignupComponent } from './shared/re-signup/re-signup.component';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    declarations: [
        TranslatePipe,
        FormFieldComponent,
        FormFieldContainerComponent,
        IbanValidatorDirective,
        IbanFieldContainerComponent,
        PasswordRequirementComponent,
        MoneyPipe,
        BackbuttonComponent,
        PaymentSelectComponent,
        PopupRegistrationGeneralComponent,
        PopupPaymentFailedComponent,
        PopupUndoCancelledSubscriptionComponent,
        PopupBookingfailedComponent,
        PopupRegistrationExistsComponent,
        PopupRegistrationTimeoutComponent,
        PopupReSignupComponent,
        ReSignupComponent,
        DematrializeErrorPipe,
        DematrializeValuePipe,
        ScaleComponent,
        ToastComponent,
        PopupEmailComponent,
    ],
    entryComponents: [
        PopupRegistrationGeneralComponent,
        PopupPaymentFailedComponent,
        PopupRegistrationExistsComponent,
        PopupRegistrationTimeoutComponent,
        PopupBookingfailedComponent,
        PopupUndoCancelledSubscriptionComponent,
        PopupEmailComponent,
    ],
    exports: [
        TranslatePipe,
        FormFieldComponent,
        FormFieldContainerComponent,
        PasswordRequirementComponent,
        MoneyPipe,
        PaymentSelectComponent,
        DematrializeErrorPipe,
        DematrializeValuePipe,
        BackbuttonComponent,
        ScaleComponent,
        ToastComponent,
        ReSignupComponent,
    ],
})
export class SharedModule {}

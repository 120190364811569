import { Pipe, PipeTransform } from '@angular/core';
import { Notification } from 'rxjs';

@Pipe({ name: 'hasError' })
export class DematrializeErrorPipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        if (value instanceof Notification) {
            const notification: Notification<any> = <Notification<any>>value;
            return notification.kind == 'E' && notification.error;
        }
        return undefined;
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../translate-service/translate-service';

@Pipe({
    name: 'translate',
    pure: false,
})
export class TranslatePipe implements PipeTransform {
    constructor(private readonly translateService: TranslateService) {}

    transform(value: string, ...args: string[]): any {
        if (!value) {
            return '';
        }

        return this.translateService.translate(value, args);
    }
}

import { Component, OnInit } from '@angular/core';
import { EnvironmentService } from '../environment-service/environment.service';
import { AbstractPopupDirective } from '../abstract-popup/abstract-popup.directive';

@Component({
    templateUrl: './popup-payment-failed.component.html',
    styleUrls: ['./popup-payment-failed.component.scss'],
})
export class PopupPaymentFailedComponent extends AbstractPopupDirective implements OnInit {
    detailMessage: string;

    constructor(public environmentService: EnvironmentService) {
        super();
    }

    ngOnInit(): void {
        let detailCode = this.params.detailCode;
        this.detailMessage = null;

        if (!detailCode) {
            this.detailMessage = 'payment.error.general';
        } else {
            this.detailMessage = `payment.error.${detailCode}`;
        }
    }
}

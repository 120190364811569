import { Injectable } from '@angular/core';
import { PhonePrefix } from '../models/phone-number';
import { phonePrefixes as prefixes } from './phone-prefixes';

@Injectable({
    providedIn: 'root',
})
export class PhoneNumberService {
    phonePrefixes: PhonePrefix[];

    getPhonePrefixList(): PhonePrefix[] {
        this.phonePrefixes = prefixes;
        return this.phonePrefixes;
    }
}

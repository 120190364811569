import { Enrollment } from './enrollment';
import { Location } from './location';
import { LocationSwapblock } from './location-swapblock';

export class EnrollmentInfo extends Enrollment {
    subscriptionId: string;
    appointment: EnrollmentAppointment;

    constructor(data: Partial<EnrollmentInfo>) {
        super(data.businessUnitCode, data.subscriptionTypeCode);
        if (data.appointment) {
            data.appointment = new EnrollmentAppointment(data.appointment);
        }
        Object.assign(this, data);
    }

    static fromJson(json: string): EnrollmentInfo {
        let jsonObject = JSON.parse(json);
        let enrollmentInfo = new EnrollmentInfo(jsonObject);
        return enrollmentInfo;
    }

    toEnrollment(): Enrollment {
        this.subscriptionId = null;
        this.appointment = null;
        return this as Enrollment;
    }
}

export class EnrollmentAppointment {
    address: AppointmentAddress;
    swapBlock: LocationSwapblock;

    constructor(data: Partial<EnrollmentAppointment>) {
        if (data.address) {
            data.address = new AppointmentAddress(data.address);
        }
        if (data.swapBlock) {
            data.swapBlock = new LocationSwapblock(data.swapBlock);
        }
        Object.assign(this, data);
    }
}

export class AppointmentAddress {
    id: string;
    houseNumber: string;
    houseNumberSuffix: string;
    street: string;
    postalCode: string;
    city: string;
    country: string;
    latitude: string;
    longitude: string;

    constructor(data: Partial<AppointmentAddress>) {
        Object.assign(this, data);
    }

    static fromLocation(location: Location) {
        let address = new AppointmentAddress(location as Object);
        address.id = location.locationId;
        return address;
    }
}

import { Component, ViewChild } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { EnvironmentService } from '../../shared/environment-service/environment.service';
import { PasswordRequirementComponent } from '../../shared/password-requirement/password-requirement.component';
import { IdentityResult } from '../../shared/models/identity-result';
import { ToastService } from '../../shared/toast-service/toast.service';
import { Toast } from '../../shared/toast-component/toast';
import { TranslateService } from '../../shared/translate-service/translate-service';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent {
    @ViewChild(PasswordRequirementComponent, { static: true })
    passwordRequirementComponent: PasswordRequirementComponent;

    loading: boolean = false;
    oldPassword: string = '';

    constructor(
        private http: HttpClient,
        private environmentService: EnvironmentService,
        private translateService: TranslateService,
        private toastService: ToastService
    ) {}

    changePassword() {
        if (this.passwordRequirementComponent.isValid()) {
            this.loading = true;
            return this.http
                .post<IdentityResult>(this.environmentService.identityRoot + '/api/changeuserpassword', {
                    oldPassword: this.oldPassword,
                    newPassword: this.passwordRequirementComponent.newPassword,
                    confirmPassword: this.passwordRequirementComponent.repeatPassword,
                    statusMessage: '',
                })
                .subscribe(
                    (response) => {
                        if (response.succeeded) {
                            this.toastService.showToast(<Toast>{
                                text: this.translateService.translate('account.password.password_changed'),
                                icon: 'check',
                                time: 6000,
                                style: 'success',
                            });
                        } else {
                            this.toastService.showToast(<Toast>{
                                text: this.translateService.translate('account.password.incorrect_password'),
                                icon: 'times',
                                time: 6000,
                                style: 'error',
                            });
                        }
                        this.loading = false;
                        this.oldPassword = '';
                        this.passwordRequirementComponent.newPassword = '';
                        this.passwordRequirementComponent.repeatPassword = '';
                    },
                    (error) => {
                        this.toastService.showToast(<Toast>{
                            text: this.translateService.translate('account.password.error_unknown'),
                            icon: 'times',
                            time: 6000,
                            style: 'error',
                        });
                    }
                );
        }
    }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit {
    identifier: string;

    @Input() name: string;
    @Input() height: number;
    @Input() width: number;
    @Input() marginRight: boolean;
    @Input() marginLeft: boolean;
    @Input() inline: boolean;
    @Input() focusable: boolean;

    constructor() {}

    ngOnInit() {
        if (!this.width && !this.height) {
            this.width = 20;
            this.height = 20;
        }
    }
}

import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { EnvironmentService } from './environment.service';

@Injectable()
export class StagingGuardService implements CanActivate {
    constructor(private envService: EnvironmentService) {}

    canActivate(): boolean {
        console.log('staging guard called');
        if (this.envService.isProduction()) {
            return false;
        }
        return true;
    }
}

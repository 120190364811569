import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { EnvironmentService } from '../../shared/environment-service/environment.service';
import { PaymentMethod } from '../../registration/shared/models/payment-method'; // TODO: Move model
import { PaymentMethodService } from '../../registration/shared/payment-method-service/payment-method.service'; // TODO: Move service
import { SubscriptionType } from '../../registration/shared/models/subscription-type'; // TODO: Move model
import { PaymentResponse } from '../../registration/shared/models/payment-response';
import { PaymentChangeRequest } from '../../shared/models/payment-change-request';
import { PaymentInfo } from '../../shared/models/paymentinfo';
import { PaymentSelectComponent } from '../../shared/payment-select/payment-select.component';
import { PaymentRequestSepaDirectDebit } from '../../registration/shared/models/payment-request';
import { RegistrationService } from '../../registration/shared/registration-service/registration.service';
import { UserDetailsModelService } from '../services/user-details-model-service/user-details-model-service';
import { UserDetails } from '../../shared/models/userdetails';
import { Subscription } from '../../shared/models/subscription';

@Component({
    selector: 'app-change-payment',
    templateUrl: './change-payment.component.html',
    styleUrls: ['./change-payment.component.scss'],
})
export class ChangePaymentComponent implements OnInit {
    @ViewChild(PaymentSelectComponent, { static: true }) paymentSelectComponent: PaymentSelectComponent;

    loading: boolean = true;
    subscriptionType: SubscriptionType;
    paymentMethodList: Array<PaymentMethod | PaymentMethod[]>;
    subscriptionId: string;
    paymentInfo: PaymentInfo;
    failed: boolean = false;
    subscription: Subscription;

    constructor(
        private http: HttpClient,
        private environmentService: EnvironmentService,
        private paymentMethodService: PaymentMethodService,
        private registrationService: RegistrationService,
        private route: ActivatedRoute,
        private userDetailsModelService: UserDetailsModelService
    ) {}

    ngOnInit() {
        this.failed = window.location.href.indexOf('failed') > -1;
        this.route.params.subscribe((params) => {
            this.subscriptionId = params['id'];
            this.http
                .get<SubscriptionType>(
                    this.environmentService.apiRoot + 'v1/Subscription/Info?subscriptionId=' + this.subscriptionId
                )
                .subscribe((subscriptionInfo) => {
                    this.subscriptionType = subscriptionInfo;
                    this.loading = false;
                });

            this.http
                .get<PaymentInfo>(this.environmentService.apiRoot + 'v1/buckaroo/paymentinfo/' + this.subscriptionId)
                .subscribe((responsePaymentInfo) => {
                    this.paymentInfo = responsePaymentInfo as PaymentInfo;
                });
        });

        this.userDetailsModelService.getModel().subscribe((userDetails: UserDetails) => {
            this.subscription = userDetails.subscriptions.find((sub) => sub.uuid == this.subscriptionId);
        });
    }

    changePayment() {
        if (this.loading) {
            return;
        }
        this.loading = true;

        if (this.paymentSelectComponent.selectedPaymentMethod == PaymentMethod.SepaDirectDebit) {
            const sepaPaymentRequest = new PaymentRequestSepaDirectDebit({
                iban: this.paymentSelectComponent.sepaDirectDebitIban,
                name: this.paymentSelectComponent.sepaDirectDebitName,
                subscriptionId: this.subscriptionId,
            });

            return this.registrationService.PaySepaDirectDebit(sepaPaymentRequest).subscribe(
                (response) => {
                    this.loading = false;
                    location.reload();
                },
                (error) => {
                    console.log('Error getting payment url: ', error);
                    this.loading = false;
                }
            );
        }

        let paymentChangeRequest = new PaymentChangeRequest();
        paymentChangeRequest.paymentMethod = this.paymentSelectComponent.selectedPaymentMethod;
        paymentChangeRequest.issuerBIC = this.paymentSelectComponent.issuerBIC;
        paymentChangeRequest.subscriptionId = this.subscriptionId;

        return this.http
            .post<PaymentResponse>(
                this.environmentService.apiRoot + 'v1/Buckaroo/ChangePaymentUrl',
                paymentChangeRequest
            )
            .subscribe(
                (paymentResponse) => {
                    this.loading = false;
                    if (paymentResponse.paymentError || !paymentResponse.paymentUrl) {
                        // TODO: Handle error
                    } else {
                        if (paymentResponse.paymentUrl) {
                            window.location.href = paymentResponse.paymentUrl;
                        } else {
                            location.reload();
                        }
                    }
                },
                (error) => {
                    console.log('Error getting change payment url: ', error);
                    this.loading = false;
                    // TODO: Handle error
                }
            );
    }

    getPaymentItemName(item: PaymentMethod | Array<PaymentMethod>): string {
        return this.paymentMethodService.GetPaymentItemName(item);
    }

    getPaymentItemString(item: PaymentMethod): string {
        return this.paymentMethodService.GetPaymentItemString(item);
    }

    /**
     * Can submit button be active
     */
    get canSubmit(): boolean {
        return this.paymentSelectComponent.isValid() && !this.loading;
    }
}

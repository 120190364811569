import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

export const StoreOpeningAnimation = trigger('slideOpen', [
    state('closed', style({ height: '0', opacity: 0, paddingBottom: '0', overflow: 'hidden' })),
    state('open', style({ height: '*', opacity: 1 })),
    transition('closed => open', [animate('350ms ease-in-out', style({ opacity: 0, height: '*' }))]),
    transition('open => closed', [
        animate(
            '350ms ease-in-out',
            keyframes([
                style({ opacity: 0 }),
                style({ opacity: 0, height: '0', paddingBottom: '0', overflow: 'hidden' }),
            ])
        ),
    ]),
]);

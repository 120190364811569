import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-password-requirement',
    templateUrl: './password-requirement.component.html',
    styleUrls: ['./password-requirement.component.scss'],
})
export class PasswordRequirementComponent implements OnInit {
    newPassword: string = '';
    repeatPassword: string = '';

    constructor() {}

    ngOnInit() {}

    isNumberValid(): boolean {
        return /\d/.test(this.newPassword);
    }

    isCaseValid(): boolean {
        return /[a-z]/.test(this.newPassword) && /[A-Z]/.test(this.newPassword);
    }

    isLengthValid(): boolean {
        return this.newPassword.length >= 8;
    }

    isSpecialValid(): boolean {
        return /[^a-zA-Z\d\s]/.test(this.newPassword);
    }

    isValid(): boolean {
        return (
            this.isNumberValid() &&
            this.isCaseValid() &&
            this.isLengthValid() &&
            this.isSpecialValid() &&
            this.newPassword === this.repeatPassword
        );
    }
}

export enum PaymentMethod {
    Unknown = 0,
    Mastercard = 1,
    Visa = 2,
    SepaDirectDebit = 3,
    Ideal = 4,
    Sofort = 5,
    Bancontact = 6,
    Dankort = 7,
    VisaDankort = 8,
    MastercardComingSoon = 9,
    CarteBleue = 10,
    CarteBancaire = 11,
    Nexi = 12,
    PayPal = 13,
    Amex = 14,
    EPS = 15,
}

import { Asset } from '../../shared/models/asset';

/***
 * @description Subscription helper methods
 */

export const GetBikeImageString = (subscription) => {
    if (subscription && subscription.assets[0]) {
        return subscription.assets[0].bikeImageString;
    }

    return new Asset({}).bikeImageString;
};

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PopupServiceDirective } from '../../shared/popup-service/popup-service.directive';
import { RegistrationService } from '../shared/registration-service/registration.service';
import { EnrollmentService } from '../shared/enrollment-service/enrollment.service';
import { EnrollmentStatus } from '../shared/models/enrollment-status';
import { EnrollmentInfo } from '../shared/models/enrollment-info';
import { TranslateService } from '../../shared/translate-service/translate-service';
import { Locale } from '../../shared/models/CountryLanguage';
import { getLocaleLanguageTag } from '../../shared/translate-service/account-translate-service-fixer';

@Component({
    selector: 'app-failed-entry',
    templateUrl: './failed-entry.component.html',
    styleUrls: ['./failed-entry.component.scss'],
})
export class FailedEntryComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private registrationService: RegistrationService,
        private enrollmentService: EnrollmentService,
        private translateService: TranslateService,
        private popupService: PopupServiceDirective
    ) {}

    ngOnInit() {
        let customerId: string;
        let hash: string;
        let detailCode: string;

        this.route.queryParamMap.subscribe((params) => {
            customerId = params.get('customerId') || '';
            hash = params.get('customerHash') || params.get('hash') || '';
            detailCode = params.get('detailCode') ?? '';

            if (this.router.url.indexOf('failed') > -1) {
                this.popupService.showPopupPaymentFailed(detailCode);
            } else {
                localStorage.setItem('paymentResume', '1');
            }

            if (customerId != '' && hash != '') {
                this.registrationService.GetEnrollment(customerId, hash).subscribe(
                    (enrollment: EnrollmentInfo) => {
                        if (enrollment.expired) {
                            this.router.navigateByUrl('/registration/paymentexpired', { replaceUrl: true });
                            return;
                        }
                        if (enrollment.completed) {
                            this.router.navigateByUrl('/registration/alreadycompleted', { replaceUrl: true });
                            return;
                        }

                        this.enrollmentService.SaveEnrollmentInfo(enrollment);
                        this.enrollmentService.setEnrollmentStatus(
                            new EnrollmentStatus({
                                customerId: customerId,
                                hash: hash,
                                subscriptionId: enrollment.subscriptionId,
                            })
                        );
                        localStorage.setItem('language', Locale.fromString(enrollment.localeLanguageTag).toString());
                        localStorage.setItem('localeLanguageTag', getLocaleLanguageTag(enrollment.localeLanguageTag));
                        localStorage.setItem('businessUnit', enrollment.businessUnitCode);
                        localStorage.setItem('subscriptionCode', enrollment.subscriptionTypeCode);

                        this.router.navigateByUrl('/registration/enroll', { replaceUrl: true });
                    },
                    (error) => {
                        console.log('Error getting enrollment: ', error);
                        // Let's just navigate to enroll anyway on the off chance there is already some localstorage set
                        this.router.navigateByUrl('/registration/enroll', { replaceUrl: true });
                    }
                );
            } else {
                this.router.navigateByUrl('/registration/enroll', { replaceUrl: true });
            }
        });
    }
}

<div class="container-fluid margin-extralarge">
    <div class="row">
        <div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">
            <h1 class="d-block d-lg-none">{{ 'account.profile.title' | translate }}</h1>
            <hr class="d-lg-none" />
            <form (ngSubmit)="updateUser(personalDetailsForm)" #personalDetailsForm="ngForm">
                <fieldset class="margin-extralarge">
                    <form-field-container class="account-form">
                        <label>{{ 'enroll.form.streetname' | translate }}</label>
                        <input
                            form-field
                            type="text"
                            placeholder="{{ 'enroll.form.streetname' | translate }}"
                            [(ngModel)]="userDetails.street"
                            pattern="{{ lettersNumbersDashQuoteDotAndWhiteSpace }}"
                            name="street"
                            required
                        />
                    </form-field-container>
                    <form-field-container class="account-form" #zipCodeContainer>
                        <label>{{ 'enroll.form.zipcode' | translate }}</label>
                        <input
                            form-field
                            type="text"
                            placeholder="{{ 'enroll.form.zipcode' | translate }}"
                            [(ngModel)]="userDetails.zipCode"
                            name="zipCode"
                            pattern="{{ postalCodePattern }}"
                            required
                        />
                    </form-field-container>
                    <form-field-container class="account-form">
                        <label>{{ 'enroll.form.housenumber' | translate }}</label>
                        <input
                            form-field
                            type="text"
                            placeholder="{{ 'enroll.form.housenumber' | translate }}"
                            [(ngModel)]="userDetails.houseNumber"
                            pattern="{{ lettersNumbersDashQuoteDotAndWhiteSpace }}"
                            name="houseNumber"
                            required
                        />
                    </form-field-container>
                    <form-field-container class="account-form">
                        <label>{{ 'enroll.form.housenumbersuffix' | translate }}</label>
                        <input
                            form-field
                            type="text"
                            [(ngModel)]="userDetails.houseNumberSuffix"
                            pattern="{{ lettersNumbersDashQuoteDotAndWhiteSpace }}"
                            name="houseNumberSuffix"
                        />
                    </form-field-container>
                    <form-field-container class="account-form">
                        <label>{{ 'enroll.form.city' | translate }}</label>
                        <input
                            form-field
                            type="text"
                            placeholder="{{ 'enroll.form.city' | translate }}"
                            [(ngModel)]="userDetails.city"
                            pattern="{{ lettersNumbersDashQuoteDotAndWhiteSpace }}"
                            name="city"
                            required
                        />
                    </form-field-container>
                    <form-field-container class="account-form">
                        <label>{{ 'account.profile.language' | translate }}</label>
                        <div class="select-container">
                            <select name="locale" [(ngModel)]="userDetails.locale" required>
                                <option *ngFor="let locale of locales" value="{{ locale.key }}">
                                    {{ locale.name }}
                                </option>
                            </select>
                        </div>
                    </form-field-container>
                    <form-field-container class="account-form">
                        <label>{{ 'account.profile.country' | translate }}</label>
                        <div class="select-container">
                            <select
                                name="country"
                                disabled
                                [(ngModel)]="userDetails.country"
                                required
                                (change)="countryChanged()"
                            >
                                <option *ngFor="let country of countries" value="{{ country.key }}">
                                    {{ 'countries.' + country.key | translate }}
                                </option>
                            </select>
                        </div>
                    </form-field-container>
                    <form-field-container class="account-form">
                        <label>{{ 'enroll.form.emailaddress' | translate }}</label>
                        <input
                            form-field
                            type="text"
                            disabled
                            placeholder="{{ 'enroll.form.emailaddress' | translate }}"
                            [(ngModel)]="userDetails.email"
                            pattern="{{ lettersNumbersDashQuoteDotAndWhiteSpace }}"
                            name="email"
                        />
                    </form-field-container>
                    <form-field-container class="account-form">
                        <label>{{ 'enroll.form.phonenumber' | translate }}</label>
                        <input
                            form-field
                            type="text"
                            disabled
                            placeholder="{{ 'enroll.form.phonenumber' | translate }}"
                            [(ngModel)]="userDetails.phoneNumber"
                            pattern="{{ lettersNumbersDashQuoteDotAndWhiteSpace }}"
                            name="phoneNumber"
                        />
                    </form-field-container>
                </fieldset>
                <div class="row missing-fields" *ngIf="missingFields.length || errorFields.length">
                    <h1 class="col-12">{{ 'account.profile.incomplete' | translate }}</h1>
                    <span class="col-12" *ngFor="let element of errorFields"
                        >{{ 'enroll.form.error_thefield' | translate }}
                        <a href="javascript:void(0);" (click)="scrollTo(element)">{{
                            (element.placeholder ? element.placeholder : element.title) | lowercase
                        }}</a>
                        {{ 'enroll.form.error_incorrectly' | translate }}</span
                    >
                    <span class="col-12" *ngFor="let element of missingFields"
                        >{{ 'enroll.form.error_thereisno' | translate }}
                        <a href="javascript:void(0);" (click)="scrollTo(element)">{{
                            (element.placeholder ? element.placeholder : element.title) | lowercase
                        }}</a>
                        {{ 'enroll.form.error_filled' | translate }}</span
                    >
                </div>
                <div class="row flex-row-reverse no-gutters" *ngIf="!saving">
                    <div class="col-6 col-lg-auto">
                        <button class="btn btn-primary" type="submit" [disabled]="!personalDetailsForm.dirty">
                            {{ 'account.profile.save' | translate }}
                        </button>
                    </div>
                    <div class="col-6 col-lg-auto">
                        <a class="btn btn-secondary" href="/home">{{ 'account.profile.cancel' | translate }}</a>
                    </div>
                </div>
                <div class="row flex-row-reverse no-gutters" *ngIf="saving">
                    <div class="col-6 col-lg-auto">
                        <button class="btn btn-primary" disabled>
                            <i class="fa fa-refresh fa-spin"></i> &nbsp;{{ 'account.profile.saving' | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

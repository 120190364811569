import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import { Toast } from '../toast-component/toast';

@Directive()
@Injectable({
    providedIn: 'root',
})
export class ToastService {
    @Output() show: EventEmitter<Toast> = new EventEmitter();

    constructor() {}

    showToast(toast: Toast) {
        this.show.emit(toast);
    }
}

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth-service/auth.service';

@Injectable()
export class RequestErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((response: HttpResponse<any>) => {
                // Temp log all errors
                console.error({
                    request: request,
                    response: response,
                });

                // if unauthorized, login
                if (response.status === 401) {
                    this.authService.startAuthentication();
                }

                return throwError(response.status);
            })
        );
    }
}

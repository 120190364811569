<div class="container-fluid margin-extralarge" *ngIf="subscriptions.length > 0; else reSignup">
    <div class="row" [ngClass]="{ 'hide-mobile': selectedMobile }">
        <div class="col-12 col-sm-10 offset-sm-1 col-lg-12 offset-lg-0">
            <h1 class="d-lg-none">{{ 'account.subscription.subscriptions' | translate }}</h1>
            <hr class="d-lg-none" />
            <h4 class="d-block d-lg-none">{{ 'account.subscription.bikes' | translate }}</h4>
            <app-all-subscriptions> </app-all-subscriptions>
        </div>
    </div>
</div>

<ng-template #reSignup
    ><div class="re-signup-container"><app-re-signup></app-re-signup></div
></ng-template>

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LocationService } from '../../../registration/shared/location-service/location.service';
import { Location } from '../../../registration/shared/models/location';
import { Subscription } from '../../../shared/models/subscription';
import { Asset } from '../../../shared/models/asset';
import { ActivatedRoute } from '@angular/router';
import { StoreOpeningAnimation } from './store_opening.animation';
import { ReactiveComponent } from '../../../shared/reactive-component/reactive.component';

@Component({
    selector: 'app-subscription-dropoff-locations',
    templateUrl: './subscription-dropoff-locations.component.html',
    styleUrls: ['./subscription-dropoff-locations.component.scss'],
    animations: [StoreOpeningAnimation],
})
export class SubscriptionDropoffLocationsComponent extends ReactiveComponent implements OnInit, OnChanges {
    @Input() subscription: Subscription;

    days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    date = new Date();
    today: string;
    errorLoadingSub = false;
    selectedStores: Location[] = [];
    storeList: Location[];
    businessHoursOpen: number = -1;
    /***
     * @description used to determine active tab
     */
    state: string;

    constructor(private locationService: LocationService, private route: ActivatedRoute) {
        super();
    }

    ngOnInit() {
        if (this.subscription) {
            this.getStores();
        }
    }

    getStores() {
        this.today = this.days[this.date.getDay()].toLowerCase() + '_close';
        this.reactiveXSubs.add(
            this.locationService.Get(this.subscription.businessUnitId).subscribe((stores) => {
                this.storeList = stores;
            })
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.subscription.currentValue) {
            this.getStores();
        }
    }

    openClose(index: number) {
        if (this.businessHoursOpen == index) {
            this.businessHoursOpen = -1;
        } else {
            this.businessHoursOpen = index;
        }
    }

    getBikeImageString(subscription: Subscription) {
        if (subscription.assets[0]) {
            return subscription.assets[0].bikeImageString;
        }
        return new Asset({}).bikeImageString;
    }

    getMapUrl(address: string) {
        return `https://maps.google.com/maps?daddr=${address}`;
    }
}

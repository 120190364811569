import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionType } from '../../../registration/shared/models/subscription-type';
import { EnvironmentService } from '../../../shared/environment-service/environment.service';
import { GtmService } from '../../../shared/google-tag-manager/gtm-service';
import { Country } from '../../../shared/models/CountryLanguage';
import { Subscription } from '../../../shared/models/subscription';
import { UserDetails } from '../../../shared/models/userdetails';
import { UserDetailsModelService } from '../user-details-model-service/user-details-model-service';

import relocationEvent, { RelocationEvent } from '../../../shared/google-tag-manager/events/relocationGTM';

export class AddressDetails {
    street: string;
    postalCode: string;
    houseNumber: string;
    houseNumberAddition: string;
    city: string;
}

export interface GeocodedLocation {
    formattedAddress: string;
    longitude: string;
    latitude: string;
}

export type GeocodedLocationResponse = [GeocodedLocation];

export class RelocationDate {
    date: string;
}

enum Relocation {
    replaceBike = 'replaceBike',
    keepBike = 'keepBike',
}
export class RelocationType {
    type: Relocation;
}

export const step = {
    address: 'address',
    type: 'type',
    date: 'date',
    confirmation: 'confirmation',
} as const;

export const steps = [step.address, step.type, step.date, step.confirmation];
export type StepType = keyof typeof step;

export interface IsSubscriptionInRegionResponse {
    isSubscriptionTypeAvailableInRegion: boolean;
}
export interface ServiceAreaLocation {
    geoLocationIsWithinRegionServiceArea: boolean;
    geoLocationIsWithinSameCountrySwapfietsServiceAreas: boolean;
    geoLocationIsWithinSwapfietsServiceAreas: boolean;
    regionCodeForGeoLocation: string;
    countryCodeForGeoLocation: string;
    regionCodeOfClosestRegionForGeolocation: string;
}

export interface RelocationPayload {
    regionCode: string;
    relocationDate: string;
    address: {
        street: string;
        houseNumber: string;
        houseNumberSuffix?: string;
        country: Country;
        city: string;
        postalCode: string;
        latitude: string;
        longitude: string;
    };
}

@Injectable({
    providedIn: 'root',
})
export class RelocationFormDataService {
    addressDetails: AddressDetails;
    relocationType: RelocationType;
    relocationDate: RelocationDate;
    currentStep: StepType;
    steps: StepType[];
    userDetails: UserDetails;
    subscriptionType: SubscriptionType;
    subscriptionId: Subscription['uuid'];
    hasInitialisationError: boolean;
    newRegion: string;
    geoCoordinates: { latitude: string; longitude: string };

    constructor(
        private router: Router,
        private http: HttpClient,
        private environmentService: EnvironmentService,
        private userDetailsModelService: UserDetailsModelService,
        private gtmService: GtmService
    ) {
        this.router = router;
        this.relocationType = new RelocationType();
        this.addressDetails = new AddressDetails();
        this.relocationDate = new RelocationDate();
        this.relocationDate.date = undefined;
        this.steps = steps;
        this.currentStep = step.address;

        this.init();
    }

    init() {
        this.userDetailsModelService.getModel().subscribe((userDetails) => {
            this.userDetails = userDetails;
            if (userDetails.subscriptions.length === 0) {
                this.hasInitialisationError = true;
                return;
            }
            this.subscriptionId = userDetails.subscriptions[0]?.uuid;

            this.http
                .get<SubscriptionType>(
                    this.environmentService.apiRoot + 'v1/Subscription/Info?subscriptionId=' + this.subscriptionId
                )
                .subscribe(
                    (subscriptionInfo) => {
                        this.subscriptionType = subscriptionInfo;
                        console.log({ subscriptionInfo });
                    },
                    (error) => {
                        //handle error
                        this.hasInitialisationError = true;
                        console.error(error);
                    }
                );
        });
    }

    proceed = ({ stepArg, isSameCity }: { stepArg: StepType; isSameCity?: boolean }) => {
        if (isSameCity) {
            // if the user is moving to the same city, we exclude the second step of the flow
            this.steps = this.steps.filter((stepName) => stepName !== step.type);
        }
        if (isSameCity === false) {
            this.steps = steps;
        }

        const nextStepIndex = this.steps.indexOf(stepArg) + 1;
        const nextStep = this.steps[nextStepIndex];
        this.currentStep = nextStep;
        this.router.navigateByUrl(`relocation/${nextStep}`, { skipLocationChange: true });
    };

    goBack = (step: StepType) => {
        const previousStepIndex = this.steps.indexOf(step) - 1;
        const previousStep = this.steps[previousStepIndex];
        this.currentStep = previousStep;
        this.router.navigateByUrl(`relocation/${previousStep}`, { skipLocationChange: true });
    };

    gtm = (eventDetails: RelocationEvent) => {
        this.gtmService.event(relocationEvent({ eventDetails }));
    };

    reset() {
        //TODO: when endpoints ready
    }

    geocodeAddress = (address: AddressDetails) => {
        const params = new HttpParams()
            .set('postalCode', address.postalCode)
            .set('streetName', address.street)
            .set('houseNumber', address.houseNumber)
            .set('houseNumberSuffix', address.houseNumberAddition ?? '')
            .set('cityName', address.city)
            .set('countryCode', this.userDetails.country);

        return this.http.get<GeocodedLocationResponse>(this.environmentService.apiRoot + 'geocoding/v1/Geocode', {
            params,
        });
    };

    checkGeocodeWithinServiceArea = ({ lat, long }) => {
        const params = new HttpParams().set('latitude', lat).set('longitude', long);
        const requestParams = this.subscriptionType.regionCode
            ? params.set('regionCode', this.subscriptionType.regionCode)
            : params;

        return this.http.get<ServiceAreaLocation>(this.environmentService.apiRoot + 'v1/service-areas/is-within', {
            params: requestParams,
        });
    };

    setNewRegion = (region: string) => {
        this.newRegion = region;
    };

    setGeocoordinates = ({ latitude, longitude }: { latitude: string; longitude: string }) => {
        this.geoCoordinates = { latitude, longitude };
    };

    checkSubscriptionAvailableInRegion = ({ regionCode }) => {
        const params = new HttpParams()
            .set('customerType', this.subscriptionType.customerType)
            .set('assetCategoryCode', this.subscriptionType.assetCategoryCode)
            .set('isFlexible', this.subscriptionType.isFlexible)
            .set('isSpecialOffer', this.subscriptionType.isSpecialOffer)
            .set('regionCode', regionCode);

        return this.http.get<IsSubscriptionInRegionResponse>(
            this.environmentService.apiRoot + 'v3/subscription-types/is-available-in-region',
            {
                params,
            }
        );
    };

    submitRelocation() {
        return this.http.post<RelocationPayload>(
            this.environmentService.apiRoot + `v1/me/subscriptions/${this.subscriptionId}/relocate`,
            {
                regionCode: this.newRegion,
                relocationDate: new Date(this.relocationDate.date).toISOString(),
                address: {
                    street: this.addressDetails.street,
                    houseNumber: this.addressDetails.houseNumber,
                    houseNumberSuffix: this.addressDetails.houseNumberAddition || '',
                    countryCode: this.userDetails.country,
                    city: this.addressDetails.city,
                    postalCode: this.addressDetails.postalCode,
                    latitude: this.geoCoordinates.latitude,
                    longitude: this.geoCoordinates.longitude,
                },
            }
        );
    }
}

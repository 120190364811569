import { Injectable } from '@angular/core';
import { EnvironmentService } from '../environment-service/environment.service';
import { SharedCookieService } from '../shared-cookies/shared-cookie-service';
import { take } from 'rxjs/operators';
import { GtmService } from '../google-tag-manager/gtm-service';
import { TranslateService } from '../translate-service/translate-service';

declare global {
    interface Window {
        Cookiebot: any;
    }
}
@Injectable()
export class CookieConsentService {
    private cookieName = 'CookieConsent';
    private cookiebarScript: HTMLScriptElement;
    private mpDistinctId: string;

    constructor(
        private environmentService: EnvironmentService,
        private sharedCookieService: SharedCookieService,
        private gtmService: GtmService,
        private translateService: TranslateService
    ) {}

    /**
     * Attach cookiebot script to the document
     * Try and get the mixpanel id from cookies if it exists
     * Set CookieConsent cookie
     * Load GTM
     */
    init() {
        this.sharedCookieService
            .getSharedCookies()
            .pipe(take(1))
            .subscribe((cookies) => {
                if (cookies['mixpanel']) {
                    try {
                        let mixpanel = JSON.parse(cookies['mixpanel']);
                        if (mixpanel && mixpanel.distinct_id) {
                            this.mpDistinctId = mixpanel.distinct_id;
                        }
                    } catch (e) {
                        console.error('Error parsing mixpanel cookie json', e);
                    }
                }
                if (cookies[this.cookieName] !== null) {
                    this.setCookie(this.cookieName, cookies[this.cookieName], 365);
                }
            });

        this.loadConsentScript();
        this.gtmService.init(this.mpDistinctId);
    }

    private setCookie(name, value, days) {
        let expires = '';
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + value + expires + '; path=/';
    }

    private loadConsentScript() {
        this.cookiebarScript = document.createElement('script');
        this.cookiebarScript.id = 'CookieConsent';
        this.cookiebarScript.src = 'https://consent.cookiebot.com/uc.js';
        this.cookiebarScript.type = 'text/javascript';
        this.cookiebarScript.async = true;
        this.cookiebarScript.dataset.culture = this.translateService.getLocale().language.toUpperCase();
        this.cookiebarScript.dataset.cbid = this.environmentService.cookieInfoConfig.cbid;
        document.head.appendChild(this.cookiebarScript);
        this.cookiebarScript.onload = () => {
            this.addCustomConsentOnDecline({
                preference: true,
                statistics: true,
            });
        };
    }

    // TODO: delete it when the next ticket is done
    // https://swapfiets.visualstudio.com/Digital/_workitems/edit/18568/
    addCustomConsentOnDecline({ preference = false, statistics = false, marketing = false }) {
        if (!window.Cookiebot?.consented) {
            window?.addEventListener('CookiebotOnDialogDisplay', e => {
                e.preventDefault();
                const declineBtn = document.getElementById(
                    'CybotCookiebotDialogBodyButtonDecline'
                );
                declineBtn.addEventListener('click', e => {
                    e.preventDefault();
                    setTimeout(() => {
                        window?.Cookiebot?.submitCustomConsent?.(preference, statistics, marketing);
                    }, 100);
                });
            });
        }
    }
}

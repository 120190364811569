import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BookedSwapblock } from '../../../../shared/models/booked-swapblock';
import { environment } from '../../../../../environments/environment';

type GetBookedSwapblockResponse = BookedSwapblock[];

@Injectable({
    providedIn: 'root',
})
export class BookedSwapblockService {
    constructor(private http: HttpClient) {}

    GetBookedSwapblocks(): Observable<GetBookedSwapblockResponse> {
        const url = environment.apiRoot + 'v1/me/swap-blocks';
        return this.http.get<GetBookedSwapblockResponse>(url);
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserDetails } from '../../shared/models/userdetails';
import { UserDetailsModelService } from '../services/user-details-model-service/user-details-model-service';
import { Asset } from '../../shared/models/asset';
import { EnvironmentService } from '../../shared/environment-service/environment.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
    userDetails: UserDetails;
    appLink: string;

    constructor(
        private router: Router,
        private userDetailsModelService: UserDetailsModelService,
        private environmentService: EnvironmentService
    ) {}

    ngOnInit() {
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('dark-bg');

        this.userDetailsModelService.getModel().subscribe((userDetails) => {
            this.userDetails = userDetails;
        });

        this.environmentService.setCountryKey(this.userDetails.country);
        const country = this.environmentService.getCountry();
        this.appLink = country.appLink;
    }

    ngOnDestroy(): void {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('dark-bg');
    }

    changepassword() {
        this.router.navigateByUrl('/changepassword', { replaceUrl: true });
    }

    get bikeImageString() {
        if (this.userDetails && this.userDetails.subscriptions[0] && this.userDetails.subscriptions[0].assets[0]) {
            return this.userDetails.subscriptions[0].assets[0].bikeImageString;
        }

        return new Asset({}).bikeImageString;
    }
}

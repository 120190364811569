import { Injectable } from '@angular/core';
import { EnvironmentService } from '../environment-service/environment.service';
import { SharedCookies } from './shared-cookies';
import { Observable, fromEvent } from 'rxjs';
import { map, shareReplay, tap, switchMap, filter } from 'rxjs/operators';

@Injectable()
export class SharedCookieService {
    private iframe: HTMLIFrameElement;
    private iframeLoaded: Observable<boolean>;
    private receiveSharedCookies: Observable<SharedCookies>;

    /**
     * Create shared cookie iframe running on public Swapfiets domain.
     * Listen to postMessage events for cross-domain communication.
     */
    constructor(private environmentService: EnvironmentService) {
        let sharedCookieConfig = this.environmentService.sharedCookieConfig;

        this.iframe = document.createElement('iframe');
        this.iframe.src = encodeURI(sharedCookieConfig.host + sharedCookieConfig.path);
        this.iframe.setAttribute('style', 'display: none; position: fixed; top: -9999px; visibility: hidden;');
        this.iframe.setAttribute('sandbox', 'allow-scripts');
        document.body.appendChild(this.iframe);

        this.iframeLoaded = fromEvent(this.iframe, 'load').pipe(
            map(() => true),
            shareReplay(1)
        );

        this.receiveSharedCookies = fromEvent(window, 'message').pipe(
            filter((e: MessageEvent) => e.origin === sharedCookieConfig.host),
            map((e) => <SharedCookies>e.data)
        );
    }

    getSharedCookies(): Observable<SharedCookies> {
        return this.iframeLoaded.pipe(
            tap(() =>
                this.iframe.contentWindow.postMessage(
                    { action: 'getSharedCookies' },
                    this.environmentService.sharedCookieConfig.host
                )
            ),
            switchMap(() => this.receiveSharedCookies)
        );
    }

    setSharedCookies(sharedCookies: SharedCookies) {
        this.iframeLoaded.subscribe(() =>
            this.iframe.contentWindow.postMessage(
                { action: 'setSharedCookies', cookies: sharedCookies },
                this.environmentService.sharedCookieConfig.host
            )
        );
    }
}

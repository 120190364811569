import { PaymentMethod } from './payment-method';
import { AssetCategory } from '../../../shared/models/asset-category';
import { CustomerType } from '../../../shared/models/customer-type';
import { AvailabilityStateType } from '../../shared/models/availability-state';

export class SubscriptionType {
    bikeType: string;
    subscriptionTypeId: string;
    businessUnitId: string;
    availabilityState: AvailabilityStateType;
    subscriptionCode: string;
    assetCategoryCode: AssetCategory;
    businessUnit: string;
    regionCode: string;
    subscription: string;
    paymentMethods: PaymentMethod[];
    initialRate: number;
    finalRate: number;
    verificationAmount: number;
    isStudentOffer: boolean;
    isPromotion: boolean;
    isFlexible: boolean;
    isSpecialOffer: boolean;
    customerType: CustomerType;
    country: string;
    currencySymbol: string;
    currencyISOCode: string;
    maximumTypeDuration: number;
    maximumTypePeriod: string;

    constructor(data: Partial<SubscriptionType>) {
        Object.assign(this, data);
    }

    get hasBasket() {
        return this.subscriptionCode.indexOf('korb') > -1;
    }
}

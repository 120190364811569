import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionDetailsComponent } from './subscription-details/subscription-details.component';
import { AllSubscriptionsComponent } from './all-subscriptions/all-subscriptions.component';
import { UiModule } from '../../ui/ui.module';
import { AllSubscriptionsDrawerComponent } from '../header/all-subscriptions-drawer/all-subscriptions-drawer.component';
import { SubscriptionsComponent } from './subscriptions.component';
import { SubscriptionMenuComponent } from './subscription-details/subscription-menu/subscription-menu.component';
import { SubscriptionBikeCardComponent } from './subscription-details/subscription-bike-card/subscription-bike-card.component';
import { SubscriptionInfoComponent } from './subscription-details/subscription-info/subscription-info.component';
import { ChangePaymentModule } from '../change-payment/change-payment.module';
import { SharedModule } from '../../shared.module';
import { VoucherModule } from '../voucher/voucher.module';
import { CancelModule } from '../cancel/cancel.module';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [CommonModule, UiModule, SharedModule, VoucherModule, ChangePaymentModule, CancelModule, RouterModule],
    declarations: [
        SubscriptionDetailsComponent,
        AllSubscriptionsComponent,
        SubscriptionsComponent,
        SubscriptionMenuComponent,
        SubscriptionInfoComponent,
        SubscriptionBikeCardComponent,
        AllSubscriptionsDrawerComponent,
    ],
    exports: [
        SubscriptionDetailsComponent,
        AllSubscriptionsComponent,
        SubscriptionMenuComponent,
        SubscriptionsComponent,
        SubscriptionInfoComponent,
        SubscriptionBikeCardComponent,
        AllSubscriptionsDrawerComponent,
    ],
})
export class SubscriptionsModule {}

import { Component, OnInit } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { environment } from '../../../environments/environment';
import { RegistrationService } from '../shared/registration-service/registration.service';
import { KycService } from '../shared/kyc-service/kyc.service';
import { SubscriptionType } from '../shared/models/subscription-type';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionTypeService } from '../shared/subscription-type-service/subscription-type.service';
import { EnvironmentService } from '../../shared/environment-service/environment.service';
import { EnrollmentInfo } from '../shared/models/enrollment-info';
import { EnrollmentService } from '../shared/enrollment-service/enrollment.service';
import { TranslateService } from '../../shared/translate-service/translate-service';
import { GtmService } from '../../shared/google-tag-manager/gtm-service';
import purchaseConfirmationGTM from '../../shared/google-tag-manager/events/purchaseGTM';
import { PopupServiceDirective } from '../../shared/popup-service/popup-service.directive';
import { combineLatest, throwError, BehaviorSubject, Subject, EMPTY } from 'rxjs';
import { shareReplay, map, switchMap, catchError, exhaustMap, tap } from 'rxjs/operators';

import { Router } from '@angular/router';

@Component({
    selector: 'app-success',
    templateUrl: './success.component.html',
    styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private registrationService: RegistrationService,
        private enrollmentService: EnrollmentService,
        private subscriptionTypeService: SubscriptionTypeService,
        private translateService: TranslateService,
        public environmentService: EnvironmentService,
        public kycService: KycService,
        public popupService: PopupServiceDirective,
        private gtmService: GtmService,
        private router: Router
    ) {}

    subscriptionType: SubscriptionType;
    actualRate: string;
    customerID: string;

    // OBSERVABLES
    queryRouteParam$ = this.route.queryParamMap.pipe(
        map((params) => {
            const customerId = params.get('customerId') || '';
            const hash = params.get('customerHash') || '';
            if (customerId == '' && hash == '') {
                console.log('CustomerId & Hash not provided');
                throw new Error('CustomerId & Hash not provided');
            }
            return {
                customerId,
                hash,
            };
        }),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    getEnrollment$ = this.queryRouteParam$.pipe(
        switchMap((params) =>
            this.registrationService.GetEnrollment(params.customerId, params.hash).pipe(
                map((enrollment) => new EnrollmentInfo(enrollment)),
                catchError((error) => {
                    Sentry.captureException(error);
                    return EMPTY;
                })
            )
        ),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    validateSubscriptionWithEnrollment$ = this.getEnrollment$.pipe(
        switchMap((enrollment) =>
            this.subscriptionTypeService
                .ValidateSubscription({
                    businessUnitCode: enrollment.businessUnitCode,
                    subscriptionCode: enrollment.subscriptionTypeCode,
                })
                .pipe(
                    map((subscriptionType) => ({ subscriptionType, enrollment })),
                    catchError((error) => {
                        Sentry.captureException(error);
                        return throwError(error);
                    })
                )
        ),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    // KYC stuff
    isKycRequired$ = combineLatest([this.queryRouteParam$, this.validateSubscriptionWithEnrollment$]).pipe(
        switchMap(([params, { subscriptionType }]) =>
            this.kycService
                .isRequiredForCustomer(params.customerId, subscriptionType.assetCategoryCode, subscriptionType.country)
                .pipe(map((data) => data.isRequired))
        ),
        shareReplay({ bufferSize: 1, refCount: true })
    );
    kycLoading = new BehaviorSubject(false);

    ngOnInit() {
        this.startIdVerification$.subscribe();

        this.validateSubscriptionWithEnrollment$.subscribe(({ subscriptionType, enrollment }) => {
            console.log(enrollment);
            // CURRENT - implementation to solve dublicate purchase events in GTM, By checking if businessUnit exists in localstorage
            const isBusinessUnitExists = !!localStorage.getItem('businessUnit');
            if (isBusinessUnitExists) {
                console.log('[GTM] = sending purchase event');
                // We only want to send this event once if possible
                // i.e a user going directly to this page shouldn't trigger the event being sent again
                this.gtmService.event(
                    purchaseConfirmationGTM({
                        enrollment,
                        subscription: subscriptionType,
                    })
                );
            }
            this.subscriptionType = subscriptionType;
            this.environmentService.setCountryKey(subscriptionType.country);
            this.translateService.setCurrencyISOCode(this.subscriptionType.currencyISOCode);

            console.log('Enroll flow completed, clearing localstorage');
            localStorage.removeItem('businessUnit');
            localStorage.removeItem('subscriptionCode');
            localStorage.removeItem('label');
            this.enrollmentService.ClearEnrollment();

            // PREVIOUS - implementation to solve dublicate purchase events in GTM
            // setTimeout(() => {
            //     this.router.navigateByUrl('/registration/alreadycompleted', { replaceUrl: true });
            // }, 15000);
        });
    }

    goBack() {
        let country =
            environment.countries.find((c) => c.key == this.subscriptionType.country) || environment.countries[0];
        window.location.href = country.website;
    }

    startIdVerificationRequest$$ = new Subject();

    startIdVerification$ = this.startIdVerificationRequest$$.pipe(
        exhaustMap(() => {
            this.kycLoading.next(true);
            return this.queryRouteParam$.pipe(
                switchMap((param) =>
                    this.kycService.getSignUpKycAttempt(param.customerId).pipe(
                        catchError((error) => {
                            this.kycLoading.next(false);
                            Sentry.captureException(error);
                            return EMPTY;
                        })
                    )
                )
            );
        }),
        tap((kycAttempt) => {
            window.location.href = kycAttempt.link;
        })
    );

    isBoolean(value: any): value is boolean {
        return typeof value === 'boolean';
    }

    startIdVerification() {
        this.startIdVerificationRequest$$.next();
    }

    getSwapdesk(): string {
        return this.environmentService.getCountry().swapdesk;
    }

    getFAQ(): string {
        return this.environmentService.getCountry().faq;
    }

    get isSpecialOfferSubscription(): boolean {
        return !!this.subscriptionType?.isSpecialOffer;
    }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, transition, style, animate, sequence } from '@angular/animations';
import { LocationSwapblock } from '../models/location-swapblock';
import { LocationSwapblockService } from '../location-swapblock-service/location-swapblock.service';
import { Location } from '../models/location';
import { LocationInfo } from '../models/location-info';
import { EnrollmentAppointment } from '../models/enrollment-info';
import { TranslateService } from '../../../shared/translate-service/translate-service';

@Component({
    selector: 'app-slot-picker',
    templateUrl: './slot-picker.component.html',
    styleUrls: ['./slot-picker.component.scss'],
    animations: [
        trigger('dayContainerScroll', [
            transition(
                '* => prev',
                sequence([
                    style({ width: '200%', marginLeft: '-100%' }),
                    animate('0.4s ease-in-out', style({ marginLeft: '0' })),
                ])
            ),
            transition(
                '* => next',
                sequence([
                    style({ width: '200%', marginLeft: '0' }),
                    animate('0.4s ease-in-out', style({ marginLeft: '-100%' })),
                ])
            ),
            transition('* => static', sequence([style({ width: '100%', marginLeft: '0' })])),
        ]),
        trigger('fadeLoad', [
            transition(
                ':enter',
                sequence([style({ opacity: '0' }), animate('0.15s 0.15s ease-out', style({ opacity: '1' }))])
            ),
            transition(
                ':leave',
                sequence([style({ opacity: '1' }), animate('0.15s ease-in', style({ opacity: '0' }))])
            ),
        ]),
    ],
})
export class SlotPickerComponent implements OnInit {
    @Output() swapBlockSelected: EventEmitter<LocationSwapblock | boolean> = new EventEmitter();

    private _store: Location;
    days: Date[];
    today: Date;
    selectedDate: Date;
    swapblocks: LocationSwapblock[];
    closed: boolean;
    selectedSwapblock: LocationSwapblock | boolean;
    animationState: string;
    touchX: any;
    touchTime: any;

    constructor(
        private translateService: TranslateService,
        private locationSwapblockService: LocationSwapblockService
    ) {}

    get store(): Location {
        return this._store;
    }

    @Input()
    set store(store: Location) {
        this._store = store;
        if (this.today) {
            this.getSwapBlocks();
        }
    }

    @Input() currentBooking: EnrollmentAppointment;

    ngOnInit() {
        this.days = [];
        this.today = new Date();
        // Make today the current date at 00:00 in UTC.
        this.today.setTime(Date.UTC(this.today.getFullYear(), this.today.getMonth(), this.today.getDate()));
        this.selectDay(this.today);
        this.addWeekDays(this.today);
        this.animationState = 'static';
    }

    // Day selection

    private addWeekDays(newDate: Date) {
        if (this.animationState == 'prev') {
            for (let i = 6; i >= 0; i--) {
                let date = this.getOffsetDate(newDate, i);
                this.days.unshift(date);
            }
        } else {
            for (let i = 0; i <= 6; i++) {
                let date = this.getOffsetDate(newDate, i);
                this.days.push(date);
            }
        }
    }

    private getOffsetDate(date, days) {
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    previousWeek() {
        if (this.canGoBack() && this.animationState == 'static') {
            this.animationState = 'prev';
            this.addWeekDays(this.getOffsetDate(this.days[0], -this.days.length));
        }
    }

    nextWeek() {
        if (this.animationState == 'static') {
            this.animationState = 'next';
            this.addWeekDays(this.getOffsetDate(this.days[this.days.length - 1], 1));
        }
    }

    onAnimationComplete($event) {
        if ($event.toState == 'next' && this.animationState == 'next') {
            this.days.splice(0, 7);
            this.animationState = 'static';
        } else if ($event.toState == 'prev' && this.animationState == 'prev') {
            this.days.splice(7, 7);
            this.animationState = 'static';
        }
    }

    canGoBack(): boolean {
        return this.days.length && this.days[0].getTime() > this.today.getTime();
    }

    get monthNames(): string {
        if (this.days.length == 0) {
            return '';
        }
        let month = this.translateService.translate('months.' + (this.days[0].getMonth() + 1));
        if (this.days[0].getMonth() != this.days[this.days.length - 1].getMonth()) {
            month +=
                ' - ' + this.translateService.translate('months.' + (this.days[this.days.length - 1].getMonth() + 1));
        }
        return month;
    }

    selectDay(day) {
        if (this.selectedDate != day) {
            this.selectedDate = day;
            this.getSwapBlocks();
        }
    }

    onTouchStart($event) {
        let touchobj = $event.changedTouches[0];
        this.touchX = touchobj.pageX;
        this.touchTime = new Date().getTime();
    }

    onTouchEnd($event) {
        let elapsedTime = new Date().getTime() - this.touchTime;
        let touchobj = $event.changedTouches[0];
        if (elapsedTime < 100 || Math.abs(touchobj.pageX - this.touchX) < 25) {
            return;
        }

        if (touchobj.pageX > this.touchX) {
            this.previousWeek();
        } else {
            this.nextWeek();
        }
    }

    // Swapblock selection
    selectSwapBlock(swapBlock: LocationSwapblock | boolean) {
        this.selectedSwapblock = swapBlock;
        this.swapBlockSelected.emit(this.selectedSwapblock);
    }

    getSwapBlocks() {
        this.selectSwapBlock(null);
        this.swapblocks = null;
        this.locationSwapblockService
            .GetLocationSwapblocks(this.store, this.selectedDate)
            .subscribe((locationInfo: LocationInfo) => {
                this.swapblocks = locationInfo.swapblocks;

                // If selectedDay is the same as currentBooking, and currentBooking does not exist as a block, add it to the list
                if (
                    this.currentBooking &&
                    this.selectedDate.getTime() == this.currentBooking.swapBlock.date.getTime() &&
                    this.swapblocks.findIndex((block) => block.key == this.currentBooking.swapBlock.key) == -1
                ) {
                    this.swapblocks.push(this.currentBooking.swapBlock);
                    this.swapblocks.sort((a, b) => {
                        return a.startTime == b.startTime ? 0 : a.startTime > b.startTime ? 1 : -1;
                    });
                }

                // Hide blocks which are full (except the block of the current booking)
                this.swapblocks = locationInfo.swapblocks.filter((block: LocationSwapblock, i) => {
                    return block.workLoad < 5 || block.key == this.currentBooking.swapBlock.key;
                });

                this.closed = locationInfo.closed;
            });
    }
}

import { Component, ContentChild, Renderer2, AfterContentInit, OnDestroy, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormFieldComponent } from '../form-field/form-field.component';

@Component({
    selector: 'form-field-container',
    templateUrl: './form-field-container.component.html',
    styleUrls: ['./form-field-container.component.scss'],
})
export class FormFieldContainerComponent implements AfterContentInit, OnDestroy {
    @ContentChild(FormFieldComponent, { read: NgControl }) inputElement: NgControl;
    @ContentChild(FormFieldComponent, { read: ElementRef }) inputElementRef: ElementRef;

    private _subscription: Subscription;

    errorMessage: boolean = false;
    missingMessage: boolean = false;

    constructor(private renderer: Renderer2) {}

    ngAfterContentInit() {
        if (!this.inputElement) {
            return;
        }

        this._subscription = this.inputElement.statusChanges.subscribe((_) => {
            this.runValidation();
        });
    }

    ngOnDestroy() {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }

    runValidation() {
        if (!this.inputElement.dirty) {
            return;
        }

        this.errorMessage = this.missingMessage = false;
        if (!this.inputElement.value && this.inputElement.hasError('required')) {
            this.missingMessage = true;
        } else if (!this.inputElement.valid) {
            this.errorMessage = true;
        }
    }
}

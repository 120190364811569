export const usabillaCallbackActions = {
    success: 'Campaign:Success',
    close: 'Campaign:Close',
} as const;

export type UsabillaCallbackActionType = typeof usabillaCallbackActions[keyof typeof usabillaCallbackActions];

export interface UsabillaCallbackParams {
    category: string;
    action: UsabillaCallbackActionType;
    label: null | string;
    value: null | string;
    userData: unknown;
}

interface UsabillaSurvey {
    [key: string]: {
        analyticsID: string;
        manualTrigger: string;
    };
}
export const usabillaSurveys: UsabillaSurvey = {
    movingDE: {
        analyticsID: '6bea6964ec0e',
        manualTrigger: 'survey_moving_destinationDE',
    },
    movingNL: {
        analyticsID: '02a8b9feef69',
        manualTrigger: 'survey_moving_destinationNL',
    },
    churnNonMovingCountries: {
        analyticsID: '',
        manualTrigger: 'churnItEsGbAt',
    },
    churn: {
        analyticsID: '',
        manualTrigger: 'manual trigger',
    },
};

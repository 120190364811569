import { SubscriptionType } from '../../../registration/shared/models/subscription-type';
import { Country } from '../../models/CountryLanguage';
import { getRevenueFromCountryAndBike } from './purchaseGTM';

interface CheckoutStep {
    stepNumber: number;
    subscription: SubscriptionType;
}

export default function checkoutStepGTM({ subscription, stepNumber }: CheckoutStep) {
    return {
        event: 'checkoutStep',
        pageCountry: subscription.country,
        ecommerce: {
            currencyCode: subscription.currencyISOCode,
            checkout: {
                actionField: { step: stepNumber + 1 },
                products: [
                    {
                        name: subscription.bikeType,
                        id: subscription.subscriptionCode,
                        price: getRevenueFromCountryAndBike({
                            country: subscription.country as Country,
                            assetCategory: subscription.assetCategoryCode,
                        }),
                        quantity: 1,
                        monthlyPrice: subscription.finalRate,
                        brand: 'Swapfiets',
                        category: `Bikes/${subscription.bikeType}`,
                        city: subscription.businessUnit,
                        membership: subscription.isFlexible ? 'flexible' : 'loyal',
                        student: subscription.isStudentOffer ? 'yes' : 'no',
                    },
                ],
            },
        },
    };
}

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class OverlayService {
    private overlay = new Subject<any>();

    constructor() {}

    setOverlay() {
        this.overlay.next(true);
    }

    disableOverlay() {
        this.overlay.next(false);
    }

    getOverlay$(): Observable<boolean> {
        return this.overlay.asObservable();
    }
}

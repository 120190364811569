import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StartComponent } from './start/start.component';
import { EnrollComponent } from './enroll/enroll.component';
import { SuccessComponent } from './success/success.component';
import { FailedEntryComponent } from './failed-entry/failed-entry.component';
import { PaymentExpiredComponent } from './payment-expired/payment-expired.component';
import { AlreadyCompletedComponent } from './already-completed/already-completed.component';
import { RegistrationComponent } from './registration.component';
import { RegistrationNotFoundComponent } from './registration-not-found/registration-not-found.component';
import { PresetTranslationResolver } from '../shared/translation-resolver/preset-translation.resolver';

const routes: Routes = [
    {
        path: 'registration',
        component: RegistrationComponent,
        children: [
            { path: 'start', component: StartComponent },
            { path: 'enroll', component: EnrollComponent, resolve: { translations: PresetTranslationResolver } },
            { path: 'success', component: SuccessComponent, resolve: { translations: PresetTranslationResolver } },
            {
                path: 'paymentexpired',
                component: PaymentExpiredComponent,
                resolve: { translations: PresetTranslationResolver },
            },
            {
                path: 'alreadycompleted',
                component: AlreadyCompletedComponent,
                resolve: { translations: PresetTranslationResolver },
            },
            { path: 'failed', component: FailedEntryComponent },
            { path: 'resume', component: FailedEntryComponent },
            { path: 'not-found', component: RegistrationNotFoundComponent },
            { path: '**', component: StartComponent },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class RegistrationRoutingModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '../../../shared/environment-service/environment.service';
import { Profile } from '../../../shared/models/profile';

@Injectable()
export class ProfileService {
    constructor(private http: HttpClient, private environmentService: EnvironmentService) {}

    getProfile() {
        return this.http.get<Profile>(this.environmentService.apiRoot + 'v1/me');
    }
    updateProfile(locale: string) {
        return this.http.patch(this.environmentService.apiRoot + 'v1/me', {
            locale,
        });
    }
}

export class EnrollmentStatus {
    customerId: string;
    subscriptionId: string;
    hash: string;

    constructor(data: Partial<EnrollmentStatus>) {
        Object.assign(this, data);
    }

    static fromJson(json: string): EnrollmentStatus {
        let jsonObject = JSON.parse(json);
        let enrollmentInfo = new EnrollmentStatus(jsonObject);
        return enrollmentInfo;
    }
}

export enum AssetColor {
    Red = 'Red',
    Blue = 'Blue',
    Beige = 'Beige',
    Black = 'Black',
    Gray = 'Gray',
    Orange = 'Orange',
    White = 'White',
    Yellow = 'Yellow',
    Green = 'Green',
}

<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <h1 class="d-md-none">{{ 'account.cancel_subscription.cancel' | translate }}</h1>
            <div class="row margin-medium">
                <img class="bike-image" src="/assets/bikes/account/{{ getBikeImageString() }}.png" />
            </div>
            <div class="row col-lg-9 col-md-12">
                <h3 class="margin-medium">{{ 'account.cancel_subscription.terms_header' | translate }}</h3>
                <ul class="list--black">
                    <li>
                        <p class="list__content">
                            {{ 'account.cancel_subscription.term_enddate' | translate }}
                            <span class="font-weight-bold">{{ subscription.minimunEndDate | date: 'dd/MM/yyyy' }}</span>
                        </p>
                    </li>
                    <li>
                        <p class="list__content">{{ 'account.cancel_subscription.term_returnedbike' | translate }}</p>
                    </li>
                </ul>
                <p class="subscription__byline">
                    {{ 'account.cancel_subscription.term_returnfee' | translate }}
                </p>

                <p class="error-message" *ngIf="error">{{ 'account.cancel_subscription_cancel_error' | translate }}</p>
            </div>

            <div class="termination__action-buttons">
                <button
                    class="btn btn-primary cancel__button"
                    data-met="Subscription  - Confirm Cancellation  -  CTA"
                    (click)="askConfirmation()"
                >
                    {{ 'account.cancel_subscription.cancel' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

import { Component } from '@angular/core';
import { EnvironmentService } from '../environment-service/environment.service';
import { AbstractPopupDirective } from '../abstract-popup/abstract-popup.directive';

@Component({
    selector: 'app-popup-registration-general',
    templateUrl: './popup-registration-general.component.html',
    styleUrls: ['./popup-registration-general.component.scss'],
})
export class PopupRegistrationGeneralComponent extends AbstractPopupDirective {
    constructor(public environmentService: EnvironmentService) {
        super();
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RegistrationRoutingModule } from './registration-routing.module';
import { StartComponent } from './start/start.component';
import { HttpClientModule } from '@angular/common/http';
import { EnrollComponent } from './enroll/enroll.component';

import { RegistrationService } from './shared/registration-service/registration.service';
import { PaymentMethodService } from './shared/payment-method-service/payment-method.service';
import { TopbarComponent } from './shared/topbar/topbar.component';
import { FormsModule, FormBuilder } from '@angular/forms';
import { SuccessComponent } from './success/success.component';
import { FailedEntryComponent } from './failed-entry/failed-entry.component';
import { PaymentExpiredComponent } from './payment-expired/payment-expired.component';
import { StepsComponent } from './shared/steps/steps.component';
import { NumbersOnlyDirective } from './shared/numbers-only-directive/numbers-only.directive';
import { AlphanumericOnlyDirective } from './shared/alphanumeric-only-directive/alphanumeric-only.directive';
import { SharedModule } from '../shared.module';
import { LtrimZerosDirective } from './shared/ltrim-zeros-directive/ltrim-zeros.directive';
import { AlreadyCompletedComponent } from './already-completed/already-completed.component';
import { SubscriptionTypeService } from './shared/subscription-type-service/subscription-type.service';
import { RegistrationComponent } from './registration.component';
import { LocationService } from './shared/location-service/location.service';
import { StepsService } from './shared/steps-service/steps.service';
import { SlotPickerComponent } from './shared/slot-picker/slot-picker.component';
import { EnrollmentService } from './shared/enrollment-service/enrollment.service';
import { IbanService } from './shared/iban-service/iban.service';
import { CodiceFiscaleService } from './shared/codice-fiscale-validator/codice-fiscale.service';
import { CodiceFiscaleValidatorDirective } from './shared/codice-fiscale-validator/codice-fiscale-validator.directive';
import { GiftCardService } from '../shared/giftcard-service/giftcard.service';
import { PhoneNumberService } from './shared/phone-number-service/phone-number.service';
import { RegistrationNotFoundComponent } from './registration-not-found/registration-not-found.component';

@NgModule({
    imports: [CommonModule, RegistrationRoutingModule, HttpClientModule, FormsModule, SharedModule],
    providers: [
        RegistrationService,
        PhoneNumberService,
        GiftCardService,
        EnrollmentService,
        SubscriptionTypeService,
        PaymentMethodService,
        FormBuilder,
        LocationService,
        StepsService,
        IbanService,
        CodiceFiscaleService,
    ],
    declarations: [
        RegistrationComponent,
        StartComponent,
        EnrollComponent,
        TopbarComponent,
        SuccessComponent,
        FailedEntryComponent,
        PaymentExpiredComponent,
        StepsComponent,
        NumbersOnlyDirective,
        AlphanumericOnlyDirective,
        LtrimZerosDirective,
        AlreadyCompletedComponent,
        SlotPickerComponent,
        CodiceFiscaleValidatorDirective,
        RegistrationNotFoundComponent,
    ],
})
export class RegistrationModule {}

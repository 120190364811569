export class Toast {
    text: string = '';
    icon: string = '';
    style: string = 'primary';
    time: number = 4000;

    constructor(data: Object) {
        Object.keys(data).forEach((key) => {
            this[key] = data[key];
        });
    }
}

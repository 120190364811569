import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { SubscriptionType } from '../models/subscription-type';
import {
    AvailabilityStateEnum,
    availabilityState,
    AvailabilityStateValueType,
} from '../../shared/models/availability-state';

@Injectable()
export class SubscriptionTypeService {
    constructor(private http: HttpClient) {}

    ValidateSubscription({
        businessUnitCode,
        subscriptionCode,
        checkAvailability = false,
    }: {
        businessUnitCode: string;
        subscriptionCode: string;
        checkAvailability?: boolean;
    }): Observable<SubscriptionType> {
        this.Log('ValidateSubscription', businessUnitCode, subscriptionCode);
        const url =
            environment.apiRoot +
            'v1/subscription/validate' +
            '?businessUnitCode=' +
            businessUnitCode +
            '&subscriptionCode=' +
            subscriptionCode;

        return this.http.get<SubscriptionType>(url).pipe(
            map((result: Partial<SubscriptionType>) => {
                result.subscriptionCode = subscriptionCode;
                if (
                    checkAvailability &&
                    (<AvailabilityStateValueType[]>[
                        availabilityState.unavailable,
                        // Temporarily disable for introweeks period
                        // availabilityState.waitingList,
                    ]).includes(AvailabilityStateEnum[result.availabilityState])
                ) {
                    throw new Error(AvailabilityStateEnum[result.availabilityState]);
                }

                if (this.IsInvertedRateSubscription(result.subscriptionCode)) {
                    let temp = result.initialRate;
                    result.initialRate = result.finalRate;
                    result.finalRate = temp;
                }

                return new SubscriptionType(result);
            })
        );
    }

    // Workaround for german penetration pricing and some introweek subscriptions. We swap initial and finalrate, and display an extra text.
    IsInvertedRateSubscription(subscriptionCode: string): boolean {
        return [
            'de-dlu7-norm-12m',
            'de-dlu7-stu-12m',
            'de-dlu7-stu-pen-owoche',
            'nl-b2c-org-stu-intro-2019',
            'nl-b2c-org-stu-intro-2019-ams',
            'dk-b2c-dlu7-intro2019',
            'be-b2c-dlu7-stu-intro2019-min2mo',
            'de-b2c-dlu7-stu-intro2019-min2mo',
            'nl-b2c-org-stu-first10',
            'nl-b2c-org-norm-first10',
            'de-b2c-dlu7-stu-12m-intro2019-min2mo',
            'org-norm-upgraded-20offmin6mo',
            'dlu7-norm-nl-upgraded-20offmin6mo',
            'nl-b2c-org-stu-std-20offmin6mo',
            'dlu7-norm-20offmin6mo',
            'dlu7-stu-20offmin6mo',
            'dk-dlu7-norm-20offmin6mo',
            'dk-dlu7-stu-20offmin6mo',
            'dk-b2c-dlu7-norm-min2mo-20offmin6mo',
            'dk-b2c-dlu7-stu-min2mo-20offmin6mo',
            'de-dlu7-norm-upgraded-20offmin6mo',
            'de-dlu7-stu-upgraded-20offmin6mo',
        ].includes(subscriptionCode);
    }

    IsIntroweekSubscription(subscriptionCode: string): boolean {
        return [
            'nl-b2c-org-stu-flex-intro2023',
            'nl-b2c-org-stu-promotion-min6mo-intro2023',
            'nl-b2c-dlu7-stu-flex-intro2023',
            'nl-b2c-dlu7-stu-promotion-min6mo-intro2023',
            'dk-b2c-org-stu-flex-intro2023',
            'dk-b2c-org-stu-min6mo-intro2023',
            'dk-b2c-dlu7-stu-flex-intro2023',
            'dk-b2c-dlu7-stu-min6mo-intro2023',
            'nl-b2b-org-pers-inv-tsh',
        ].includes(subscriptionCode);
    }

    isBackToSchoolCampaignSubscription(subscriptionCode: string): boolean {
        return [
            'uk-b2c-dlu7-norm-partnership-introweek-flex-2023',
            'uk-b2c-dlu7-norm-partnership-introweek-min1mo-2023',
            'uk-b2c-pwr1-norm-partnership-introweek-flex-2023',
            'uk-b2c-pwr1-norm-partnership-introweek-min1mo-2023',
            'uk-b2c-pwr7-norm-partnership-introweek-flex-2023',
            'uk-b2c-pwr7-norm-partnership-introweek-min1mo-2023',
            'fr-b2c-org-stu-partnership-introweek-min6mo-2023',
            'fr-b2c-dlu7-stu-partnership-introweek-min6mo-2023',
            'fr-b2c-pwr1-stu-partnership-introweek-min6mo-2023',
        ].includes(subscriptionCode);
    }

    private Log(...params: any[]) {
        if (!environment.production && console.log) {
            let args = Array.prototype.slice.call(params);
            args.unshift('[SUBSCRIPTIONTYPE_SERVICE]');
            console.log.apply(console, args);
        }
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CancelComponent } from './cancel.component';
import { SharedModule } from '../../shared.module';
import { CancelConfirmedComponent } from './cancel-confirmed/cancel-confirmed.component';
import { CancelConfirmationComponent } from './cancel-confirmation/cancel-confirmation.component';
import { SubscriptionDropoffLocationsComponent } from '../subscriptions/subscription-dropoff-locations/subscription-dropoff-locations.component';

@NgModule({
    declarations: [
        CancelComponent,
        CancelConfirmedComponent,
        CancelConfirmationComponent,
        SubscriptionDropoffLocationsComponent,
    ],
    providers: [],
    imports: [CommonModule, SharedModule],
    exports: [CancelComponent, SubscriptionDropoffLocationsComponent],
})
export class CancelModule {}

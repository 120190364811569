<div class="container-fluid">
    <h1 class="voucher__title--card">{{ 'account.change_payment.title' | translate }}</h1>
    <hr class="d-lg-none" />
    <div class="current-payment-method">
        <div>
            <img
                class="mini-icon inline-icon"
                src="/assets/paymentmethods/{{ getPaymentItemString(paymentInfo?.paymentMethod) }}.png"
            />
            {{ 'account.change_payment.current' | translate }}
        </div>
        <div class="subtext">
            <span *ngIf="paymentInfo?.iban || paymentInfo?.maskedCreditcardNumber"
                >{{ paymentInfo?.iban }}{{ paymentInfo?.maskedCreditcardNumber }}</span
            ><span>{{ getPaymentItemName(paymentInfo?.paymentMethod) }}</span>
        </div>
        <i *ngIf="!paymentInfo" class="fa fa-spin fa-spinner" aria-hidden="true"></i>
    </div>
    <div class="error-container" *ngIf="failed">
        <span class="error-message">{{ 'account.change_payment.failed' | translate }}</span>
    </div>
    <div class="payment-button-container">
        <app-payment-select
            [allowedPaymentMethods]="subscriptionType?.paymentMethods"
            [countryName]="subscriptionType?.country"
            [bottomText]="
                (subscription?.changePaymentVerificationAmount | money: subscription?.currency) +
                ' ' +
                ('enroll.payment.verification' | translate)
            "
        >
        </app-payment-select>
    </div>
    <button
        class="btn btn-primary col-12"
        type="submit"
        (click)="changePayment()"
        [disabled]="!canSubmit"
        data-met="Change payment  - Selected paymentmethod - CTA"
    >
        {{ 'enroll.button_next' | translate }}
    </button>
</div>

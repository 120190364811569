import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangePaymentComponent } from './change-payment.component';
import { PaymentMethodService } from '../../registration/shared/payment-method-service/payment-method.service';
import { SubscriptionTypeService } from '../../registration/shared/subscription-type-service/subscription-type.service';
import { SharedModule } from '../../shared.module';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [ChangePaymentComponent],
    providers: [PaymentMethodService, SubscriptionTypeService],
    imports: [CommonModule, SharedModule, FormsModule],
    exports: [ChangePaymentComponent],
})
export class ChangePaymentModule {}

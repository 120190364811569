import { Component } from '@angular/core';
import { AbstractPopupDirective } from '../abstract-popup/abstract-popup.directive';
import { UserDetails } from '../models/userdetails';

@Component({
    selector: 'app-popup-resignup',
    templateUrl: './popup-re-signup.component.html',
    styleUrls: ['./popup-re-signup.component.scss'],
})
export class PopupReSignupComponent extends AbstractPopupDirective<UserDetails> {}

<div
    class="ui__shape"
    [ngClass]="{
        'shape--square': shape === 'square',
        'shape--circle': shape === 'circle',
        'color--white': color === 'white'
    }"
    [ngStyle]="{ 'width.px': width, 'height.px': height, backgroundImage: 'url(assets/icons/sprite.svg#' + icon + ')' }"
>
    <ng-content></ng-content>
</div>

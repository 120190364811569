import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Locale } from '../models/CountryLanguage';
import { Observable } from 'rxjs';
import { TranslateService, LocaliseProject } from '../translate-service/translate-service';

@Injectable({
    providedIn: 'root',
})
export class PresetTranslationResolver implements Resolve<Observable<boolean>> {
    constructor(private translateService: TranslateService) {}

    resolve(): Observable<boolean> {
        let locale = localStorage.getItem('language') || 'en_GB';

        return this.translateService.initiateLanguage(Locale.fromString(locale), LocaliseProject.Account);
    }
}

import { Injectable } from '@angular/core';
import { PaymentMethod } from '../models/payment-method';
import { TranslateService } from '../../../shared/translate-service/translate-service';

@Injectable()
export class PaymentMethodService {
    private paymentMethodList: { [country: string]: Array<PaymentMethod | PaymentMethod[]> } = {
        NL: [
            [PaymentMethod.Ideal, PaymentMethod.Bancontact, PaymentMethod.Sofort],
            PaymentMethod.Mastercard,
            PaymentMethod.Visa,
            PaymentMethod.Dankort,
            PaymentMethod.VisaDankort,
            PaymentMethod.MastercardComingSoon,
        ],
        BE: [
            [PaymentMethod.Bancontact, PaymentMethod.Ideal, PaymentMethod.Sofort],
            PaymentMethod.Mastercard,
            PaymentMethod.Visa,
            PaymentMethod.Dankort,
            PaymentMethod.SepaDirectDebit,
            PaymentMethod.VisaDankort,
            PaymentMethod.MastercardComingSoon,
        ],
        DE: [
            [PaymentMethod.Sofort, PaymentMethod.Ideal, PaymentMethod.Bancontact],
            PaymentMethod.Mastercard,
            PaymentMethod.Visa,
            PaymentMethod.Dankort,
            PaymentMethod.VisaDankort,
            PaymentMethod.MastercardComingSoon,
            PaymentMethod.PayPal,
        ],
        DK: [
            PaymentMethod.Dankort,
            PaymentMethod.Visa,
            PaymentMethod.VisaDankort,
            PaymentMethod.MastercardComingSoon,
            PaymentMethod.Mastercard,
            [PaymentMethod.Ideal, PaymentMethod.Bancontact, PaymentMethod.Sofort],
            PaymentMethod.SepaDirectDebit,
        ],
        FR: [
            PaymentMethod.CarteBancaire,
            PaymentMethod.CarteBleue,
            PaymentMethod.Mastercard,
            PaymentMethod.Visa,
            PaymentMethod.Amex,
            PaymentMethod.PayPal,
        ],
        IT: [PaymentMethod.Mastercard, PaymentMethod.Visa, PaymentMethod.Nexi, PaymentMethod.Amex],
        GB: [PaymentMethod.Mastercard, PaymentMethod.Visa, PaymentMethod.Amex],
        ES: [PaymentMethod.Mastercard, PaymentMethod.Visa, [PaymentMethod.Ideal, PaymentMethod.Sofort]],
        AT: [
            [PaymentMethod.EPS, PaymentMethod.Ideal, PaymentMethod.Bancontact],
            PaymentMethod.Visa,
            PaymentMethod.Mastercard,
            PaymentMethod.Amex,
            PaymentMethod.PayPal,
        ],
    };

    private idealIssuers = {
        ABNANL2A: 'ABN AMRO',
        ASNBNL21: 'ASN Bank',
        INGBNL2A: 'ING',
        RABONL2U: 'Rabobank',
        SNSBNL2A: 'SNS Bank',
        RBRBNL21: 'SNS Regio Bank',
        TRIONL2U: 'Triodos Bank',
        FVLBNL22: 'Van Lanschot',
        KNABNL2H: 'Knab',
        BUNQNL2A: 'Bunq',
        MOYONL21: 'Moneyou',
    };

    constructor(private translateService: TranslateService) {}

    GetPaymentMethodsByCountry(countryKey: string): Array<PaymentMethod | PaymentMethod[]> {
        return this.paymentMethodList[countryKey];
    }

    GetIdealIssuers() {
        return this.idealIssuers;
    }

    /**
     * Return name of payment item, usually the item's name or direct debit
     * @param item
     */
    GetPaymentItemName(item: PaymentMethod | Array<PaymentMethod>): string {
        if (item == undefined) {
            return '';
        }

        if (Array.isArray(item)) {
            return this.translateService.translate('enroll.payment.debit');
        }

        return this.translateService.translate('enroll.payment.methods.' + PaymentMethod[item]);
    }

    GetPaymentItemString(item: PaymentMethod): string {
        if (item == undefined) {
            return 'empty';
        }
        return PaymentMethod[item];
    }
}

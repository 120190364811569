import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-row-link',
    templateUrl: './row-link.component.html',
    styleUrls: ['./row-link.component.scss'],
})
export class RowLinkComponent implements OnInit {
    @Input() link: any[];
    @Input() active;
    @Input() border: boolean = true;

    constructor(private router: Router) {}

    ngOnInit() {}

    navigate() {
        this.router.navigate(this.link);
    }
}

import { Component, HostListener, OnInit } from '@angular/core';
import { Subscription } from '../../../shared/models/subscription';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDetailsModelService } from '../../services/user-details-model-service/user-details-model-service';
import { Asset } from '../../../shared/models/asset';
import { ReactiveComponent } from '../../../shared/reactive-component/reactive.component';
import { BookedSwapblockService } from '../../services/booked-swapblock-service/location-swapblock-service/booked-swapblock.service';
import { BookedSwapblock } from '../../../shared/models/booked-swapblock';

@Component({
    selector: 'app-subscription-details',
    templateUrl: './subscription-details.component.html',
    styleUrls: ['./subscription-details.component.scss'],
})
export class SubscriptionDetailsComponent extends ReactiveComponent implements OnInit {
    subscriptions: Subscription[] = [];
    subscriptionSwapblock: BookedSwapblock = null;

    selectedSubscription: Subscription = null;
    selectedMobile: boolean = false;
    errorLoadingSub = false;
    loading: boolean = true;
    cancelHeader = 'account.cancel_subscription.cancel';

    /***
     * @description used to determine active tab
     */
    state: string;

    constructor(
        private route: ActivatedRoute,
        private userDetailsModelService: UserDetailsModelService,
        private bookedSwapblockService: BookedSwapblockService
    ) {
        super();
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
        this.selectedMobile = false;
        if (this.subscriptions.length) {
            this.selectedSubscription = this.subscriptions[0];
        }
    }

    ngOnInit() {
        this.reactiveXSubs.add(
            this.userDetailsModelService.getModel().subscribe((userDetails) => {
                this.subscriptions = userDetails.subscriptions;

                if (this.subscriptions.length == 0) {
                    return;
                }

                this.bookedSwapblockService.GetBookedSwapblocks().subscribe(
                    (response) => {
                        if (!this.selectedSubscription) {
                            this.loading = false;
                            return;
                        }
                        this.subscriptionSwapblock = response.find(
                            (block) => block.subscriptionId === this.selectedSubscription.uuid
                        );
                        this.loading = false;
                    },
                    (error) => {
                        this.loading = false;
                    }
                );

                this.route.data.subscribe((data) => {
                    this.state = data.active;
                });

                this.route.params.subscribe((params) => {
                    for (let i = 0; i < this.subscriptions.length; i++) {
                        if (this.subscriptions[i].uuid == params['id']) {
                            this.selectedSubscription = this.subscriptions[i];
                            this.errorLoadingSub = false;
                        }
                    }
                    if (!this.selectedSubscription) {
                        this.errorLoadingSub = true;
                    }
                });
            })
        );
    }

    /***
     * @description changes card header of the cancel state
     * @param atConfirm
     */
    cancelConfirmed(atConfirm: boolean) {
        atConfirm
            ? (this.cancelHeader = 'account.cancel_subscription.cancel_submit')
            : (this.cancelHeader = 'account.cancel_subscription.cancel');
    }

    getBikeImageString(subscription: Subscription) {
        if (subscription.assets[0]) {
            return subscription.assets[0].bikeImageString;
        }
        return new Asset({}).bikeImageString;
    }
}

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-registration-not-found',
    templateUrl: './registration-not-found.component.html',
    styleUrls: ['./registration-not-found.component.scss'],
})
export class RegistrationNotFoundComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {
        // PLEASE FIX ME
        // QUICK FIX FOR REDIRECT TO THE RIGHT DOMAIN
        window.location.href = 'https://swapfiets.com';
    }
}

<section *ngIf="subscription">
    <section class="subscription__info__wrapper">
        <div class="subscription__info__bike__image-wrapper">
            <img
                class="subscription__info__bike__image"
                src="/assets/bikes/account/{{ getBikeImageString(subscription) }}.png"
            />
        </div>

        <div class="subscription__info__text">
            <p class="margin-medium">
                <span *ngIf="getHasFirstDeliveryPlanned; else subInfo">
                    <!-- // First delivery appointment Planned -->
                    <span>
                        {{ 'account.subscription.subscriptionstatus.fd_planned' | translate }}
                        <br />
                        {{ 'account.subscription.fd_date' | translate }}
                        <b>{{ subscriptionSwapblock?.startDateTime | date: 'dd-MM-yyyy, hh:mm ' }}</b>
                        <br />
                        <br />
                        {{ 'account.subscription.fd_location' | translate }}
                        <b>{{ subscriptionSwapblock?.locationName }}</b>
                        <br />
                        {{ subscriptionSwapblock?.address?.street }}
                        {{ subscriptionSwapblock?.address?.houseNumber }}
                        {{ subscriptionSwapblock?.address?.houseNumberSuffix }}

                        <br />
                        {{ subscriptionSwapblock?.address?.city }}
                        <br />
                        {{ subscriptionSwapblock?.address?.postalCode }}
                    </span>
                </span>
                <ng-template #subInfo>
                    <span *ngIf="!subscription?.start">
                        <!-- // Awaiting First Delivery Planning -->
                        {{ 'account.subscription.subscriptionstatus.fd_planned' | translate }}
                        <br />
                        {{ 'account.subscription.fd_generic' | translate }}
                    </span>
                    <span *ngIf="!subscription?.end && subscription.start">
                        <!-- // Active Subscription -->
                        {{ 'account.subscription.started_on' | translate }}
                        <b>{{ subscription?.start | date: 'dd-MM-yyyy' }}</b
                        >, <br />{{ 'account.subscription.will_be_charged' | translate }}
                        {{ subscription?.rate | money: subscription?.currency }}
                        {{ 'account.subscription.per_month' | translate }}
                        <br />
                    </span>
                    <span *ngIf="subscription?.end">
                        <!-- // Cancellation initiated -->

                        <span *ngIf="endDateReached">
                            <!-- // Cancelled -->

                            {{ 'account.subscription.subscriptionstatus.sub_terminated_date_passed1' | translate }}
                            <span class="font-weight-bold">{{ subscription?.end | date: 'dd-MM-yyyy' }}</span
                            >.
                            {{ 'account.subscription.subscriptionstatus.sub_terminated_date_passed2' | translate }}
                            <a
                                routerLink="/subscriptions/{{ subscription.uuid }}/drop-off-locations"
                                class="inline-link"
                                routerLinkActive="active"
                                >{{ 'account.subscription.stores_lowercase' | translate }}</a
                            >.
                        </span>
                        <span *ngIf="!endDateReached">
                            <!-- // Awaiting cancellation date -->

                            {{ 'account.subscription.subscriptionstatus.sub_terminated' | translate }}
                            <span class="font-weight-bold">{{ subscription?.end | date: 'dd-MM-yyyy' }}</span
                            >.
                            {{ 'account.subscription.subscriptionstatus.sub_terminated2' | translate }}
                            <a
                                routerLink="/subscriptions/{{ subscription.uuid }}/drop-off-locations"
                                class="inline-link"
                                routerLinkActive="active"
                                >{{ 'account.subscription.stores_lowercase' | translate }}</a
                            >.
                            {{ 'account.subscription.subscriptionstatus.sub_terminated3' | translate }}
                            <span class="font-weight-bold">{{ subscription?.end | date: 'dd-MM-yyyy' }}</span>
                            {{ 'account.subscription.subscriptionstatus.sub_terminated4' | translate }}
                        </span>
                    </span>
                </ng-template>
            </p>

            <div class="contract-link">
                <ul>
                    <li *ngIf="subscription.end">
                        <a
                            href="javascript:void(0)"
                            (click)="undoCancelledSubscription()"
                            routerLinkActive=""
                            data-met="Subscription  - undo cancel subscription - CTA"
                            >{{ 'account.cancel_subscription.undo_cancelled_subscription' | translate }}</a
                        >
                    </li>
                    <li *ngIf="isContractViewable">
                        <a
                            href="javascript:void(0)"
                            (click)="downloadContract(subscription.uuid)"
                            data-met="Subscription  - Download Agreement - CTA"
                            >{{ 'account.subscription.download_agreement' | translate }}</a
                        >
                    </li>
                    <li *ngIf="isSubscriptionCancellable">
                        <a
                            href="javascript:void(0)"
                            (click)="cancelSubscription()"
                            routerLinkActive=""
                            data-met="Subscription - Cancel Subscription - CTA"
                            >{{ 'account.cancel_subscription.cancel' | translate }}</a
                        >
                    </li>
                    <li *ngIf="movingFAQLink">
                        <a
                            (click)="handleMovingLink()"
                            data-met="Subscription - Moving FAQ - CTA"
                            href="javascript:void(0)"
                            >{{ 'account.subscription.move' | translate }}</a
                        >
                    </li>
                </ul>
            </div>
        </div>
    </section>
</section>

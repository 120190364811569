import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { VoucherComponent } from './voucher.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [VoucherComponent],
    imports: [SharedModule, CommonModule, HttpClientModule, FormsModule],
    exports: [VoucherComponent],
})
export class VoucherModule {}

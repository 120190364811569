export const accountSupportedLocales = {
    'DE': [
        {
            key: 'de-DE',
            name: 'Deutsch',
        },
        {
            key: 'en-DE',
            name: 'English',
        }
    ],
    'DK': [
        {
            key: 'da-DK',
            name: 'Dansk',
        },
        {
            key: 'en-DK',
            name: 'English',
        }
    ],
    'FR': [
        {
            key: 'fr-FR',
            name: 'Français',
        },
        {
            key: 'en-FR',
            name: 'English',
        }
    ],
    'BE': [
        {
            key: 'nl-BE',
            name: 'Vlaams',
        },
        {
            key: 'fr-BE',
            name: 'Français',
        },
        {
            key: 'en-BE',
            name: 'English',
        }
    ],
    'NL': [
        {
            key: 'nl-NL',
            name: 'Nederlands',
        },
        {
            key: 'en-NL',
            name: 'English',
        },
    ],
    'IT': [
        {
            key: 'en-IT',
            name: 'English',
        },
    ],
    'AT': [
        {
            key: 'de-DE',
            name: 'Deutsch',
        },
        {
            key: 'en-DE',
            name: 'English',
        }
    ],
    'ES': [
        {
            key: 'es-ES',
            name: 'Español',
        },
        {
            key: 'en-ES',
            name: 'English',
        },
    ],
    'GB': [
        {
            key: 'en-GB',
            name: 'English',
        },
    ],
}

import { Component, OnInit } from '@angular/core';
import { EnvironmentService } from '../environment-service/environment.service';
import { TranslateService } from '../translate-service/translate-service';
import { ReactiveComponent } from '../reactive-component/reactive.component';
import { Locale } from '../models/CountryLanguage';

@Component({
    selector: 'app-re-signup',
    templateUrl: './re-signup.component.html',
    styleUrls: ['./re-signup.component.scss'],
})
export class ReSignupComponent extends ReactiveComponent implements OnInit {
    reSignupUrl: string;
    usps = ['resignup.usp.1', 'resignup.usp.2', 'resignup.usp.3'];

    constructor(private environmentService: EnvironmentService, private translateService: TranslateService) {
        super();
    }

    ngOnInit() {
        this.generateReSignupUrl(this.translateService.getLocale());
        this.reactiveXSubs.add(
            this.translateService.localeChange$.subscribe((currentLocale) => {
                this.generateReSignupUrl(currentLocale);
            })
        );
    }

    generateReSignupUrl(currentLocale: Locale) {
        const countryConfig = this.environmentService.getCountry();

        function findLocale(lang: string) {
            return countryConfig.languages.find((locale) => {
                return locale.split(/[-_]/)[0].toLowerCase() === lang;
            });
        }

        const userSelectedLang = currentLocale.language.toLowerCase();

        const newWebsiteLocale = findLocale(userSelectedLang) ?? findLocale('en') ?? countryConfig.languages[0];

        this.reSignupUrl = new URL(newWebsiteLocale, countryConfig.website).toString();
    }
}

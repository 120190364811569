import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { EnvironmentService } from '../../../shared/environment-service/environment.service';
import { Profile } from '../../../shared/models/profile';
import { Country } from '../../../shared/models/country';
import { AvailableLocale } from '../../../shared/models/available-locale';
import { ToastService } from '../../../shared/toast-service/toast.service';
import { Toast } from '../../../shared/toast-component/toast';
import { FormFieldContainerComponent } from '../../../shared/form-field-container/form-field-container.component';
import { TranslateService } from '../../../shared/translate-service/translate-service';
import { Locale } from '../../../shared/models/CountryLanguage';
import { ReactiveComponent } from '../../../shared/reactive-component/reactive.component';
import { ProfileService } from '../../services/profile-service/profile.service';

@Component({
    selector: 'app-profile-nl',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponentNL extends ReactiveComponent implements OnInit {
    @ViewChild('personalDetailsForm', { static: true }) personalDetailsForm: HTMLFormElement;
    @ViewChild('zipCodeContainer', { static: true }) zipCodeContainer: FormFieldContainerComponent;

    userDetails: Profile['info'] & Profile['currentAddress'] = {
        firstName: '',
        lastName: '',
        postalCode: '',
        street: '',
        countryCode: '',
        city: '',
        phoneNumber: '',
        locale: '',
        email: '',
        houseNumber: '',
        houseNumberSuffix: '',
    };
    currentLocale = null;
    saving: boolean = false;
    isRelocationPending: boolean = false;
    futureAddress: Profile['futureAddress'];

    constructor(
        private environmentService: EnvironmentService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private profileService: ProfileService
    ) {
        super();
    }

    ngOnInit() {
        this.reactiveXSubs.add(
            this.profileService.getProfile().subscribe((userDetails) => {
                this.userDetails = { ...userDetails.info, ...userDetails.currentAddress };
                this.futureAddress = userDetails.futureAddress;
                this.currentLocale = this.userDetails.locale;
            })
        );
    }

    /**
     * All countries
     */
    get countries(): Country[] {
        return this.environmentService.countries;
    }

    /**
     * All locales
     */
    get locales(): AvailableLocale[] {
        return this.translateService.getLocales(this.userDetails.countryCode);
    }

    updateUser(form: NgForm) {
        this.saving = true;
        this.profileService.updateProfile(this.userDetails.locale).subscribe(
            (response) => {
                let toastText;
                let toastTime;
                let toastStyle;
                if (this.currentLocale != this.userDetails.locale) {
                    this.translateService.changeLocale(Locale.fromString(this.userDetails.locale));
                    this.currentLocale = this.userDetails.locale;
                    toastText = this.translateService.translate('account.profile.profile_saved_language_changed');
                    toastTime = 6000;
                    toastStyle = 'medium';
                } else {
                    toastText = this.translateService.translate('account.profile.profile_saved');
                    toastTime = 4000;
                    toastStyle = 'success';
                }
                this.saving = false;
                this.toastService.showToast(
                    new Toast({
                        text: toastText,
                        icon: 'check',
                        time: toastTime,
                        style: toastStyle,
                    })
                );
                this.personalDetailsForm.form.markAsPristine();
            },
            (error) => {
                this.saving = false;
                this.toastService.showToast(<Toast>{
                    text: this.translateService.translate('account.password.error_unknown'),
                    icon: 'times',
                    time: 6000,
                    style: 'error',
                });
                this.personalDetailsForm.form.markAsPristine();
            }
        );
    }
}

import { Directive, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[numbersOnly]',
})
export class NumbersOnlyDirective {
    constructor(private el: ElementRef) {}

    @HostListener('keydown', ['$event']) onKeyDown(event) {
        let e = <KeyboardEvent>event;

        // Allow: delete, backspace, tab, escape, enter
        if (
            [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
            // Allow: Ctrl+A, Ctrl+C, Ctrl+V, Ctrl+X
            ([65, 67, 86, 88].indexOf(e.keyCode) !== -1 && e.ctrlKey === true) ||
            // Allow: home, end, left, right
            (e.keyCode >= 35 && e.keyCode <= 39)
        ) {
            return;
        }

        let ch = '';

        // Convert numpad keys
        if (e.keyCode >= 96 && e.keyCode <= 105) {
            ch = String.fromCharCode(e.keyCode - 48);
        } else {
            ch = String.fromCharCode(e.keyCode);
        }

        let regEx = new RegExp('^[0-9]*$');
        if (regEx.test(ch)) {
            return;
        } else {
            e.preventDefault();
        }
    }
}

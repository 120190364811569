<section class="container-fluid" *ngIf="selectedSubscription">
    <div class="swapfiets__subscription__container">
        <div class="swapfiets__subscription__menu">
            <app-subscription-menu [subscription]="selectedSubscription" [active]="state"> </app-subscription-menu>
        </div>

        <div class="swapfiets__subscription__bike">
            <h1 class="swapfiets__subscription__header">
                {{ 'account.subscription.bike' | translate }} {{ selectedSubscription.businessUnitName }}
                {{ selectedSubscription.assets[0]?.code }}
            </h1>

            <!-- Bike -->
            <div [ngSwitch]="state" *ngIf="state">
                <ng-container *ngSwitchCase="'bike'">
                    <app-subscription-bike-card header="account.header.subscription">
                        <app-subscription-info
                            [subscription]="selectedSubscription"
                            [subscriptionSwapblock]="subscriptionSwapblock"
                        >
                        </app-subscription-info>
                    </app-subscription-bike-card>
                </ng-container>

                <ng-container *ngSwitchCase="'changepayment'">
                    <app-subscription-bike-card header="payment.change">
                        <app-change-payment> </app-change-payment>
                    </app-subscription-bike-card>
                </ng-container>

                <ng-container *ngSwitchCase="'voucher'">
                    <app-subscription-bike-card header="account.voucher_redeem.redeem_gift_card">
                        <app-voucher> </app-voucher>
                    </app-subscription-bike-card>
                </ng-container>

                <ng-container *ngSwitchCase="'cancel'">
                    <app-subscription-bike-card [header]="cancelHeader">
                        <app-cancel (atConfirmation)="cancelConfirmed($event)"> </app-cancel>
                    </app-subscription-bike-card>
                </ng-container>

                <ng-container *ngSwitchCase="'drop-off-locations'">
                    <app-subscription-bike-card [header]="'account.subscription.stores_title'">
                        <img
                            class="bike-image margin-large"
                            src="/assets/bikes/account/{{ getBikeImageString(selectedSubscription) }}.png"
                        />
                        <app-subscription-dropoff-locations [subscription]="selectedSubscription">
                        </app-subscription-dropoff-locations>
                    </app-subscription-bike-card>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    <app-subscription-bike-card header="account.header.subscription">
                        <app-subscription-info
                            [subscription]="selectedSubscription"
                            [subscriptionSwapblock]="subscriptionSwapblock"
                        >
                        </app-subscription-info>
                    </app-subscription-bike-card>
                </ng-container>
            </div>
        </div>
    </div>
</section>

<section *ngIf="errorLoadingSub">
    <p class="swapfiets__subscription__bike__error">{{ 'account.subscription.load_error' | translate }}</p>
</section>

import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { Locale } from '../models/CountryLanguage';
import { accountTranslateServiceFixer } from '../translate-service/account-translate-service-fixer';
import { TranslateService, LocaliseProject } from '../translate-service/translate-service';
import { UserDetailsModelService } from '../../account/services/user-details-model-service/user-details-model-service';
import { UserDetails } from '../models/userdetails';

@Injectable({
    providedIn: 'root',
})
export class ProfileTranslationResolver implements Resolve<Observable<boolean>> {
    constructor(private translateService: TranslateService, private userDetailsModelService: UserDetailsModelService) {}

    resolve(): Observable<boolean> {
        let temporaryLocale = sessionStorage.getItem('temporaryLocale');

        if (temporaryLocale) {
            return this.translateService.initiateLanguage(
                accountTranslateServiceFixer(temporaryLocale),
                LocaliseProject.Account
            );
        } else {
            return this.userDetailsModelService.getModel().pipe(
                take(1),
                switchMap((userDetails: UserDetails) => {
                    return this.translateService.initiateLanguage(
                        accountTranslateServiceFixer(userDetails.locale),
                        LocaliseProject.Account
                    );
                })
            );
        }
    }
}

import { Injectable } from '@angular/core';
import { UserManager, UserManagerSettings, User, WebStorageStateStore, SignoutResponse } from 'oidc-client';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthService {
    private manager = new UserManager(getClientSettings());
    user: User = null;

    constructor() {
        this.manager.getUser().then((user) => {
            this.user = user;
        });
    }

    isLoggedIn(): boolean {
        return this.user != null && !this.user.expired;
    }

    getClaims(): any {
        return this.user.profile;
    }

    getAuthorizationHeaderValue(): string {
        if (!this.user) {
            return null;
        }
        return `${this.user.token_type} ${this.user.access_token}`;
    }

    startAuthentication(route?: string): Promise<void> {
        return this.manager.signinRedirect({ state: { targetUrl: route ?? 'home' } });
    }

    signOut(): Promise<void> {
        return this.manager.signoutRedirect();
    }

    completeSignOut(): Promise<SignoutResponse> {
        this.user = null;
        return this.manager.signoutRedirectCallback();
    }

    completeAuthentication(): Promise<User> {
        return this.manager.signinRedirectCallback();
    }

    storeUser(user: User): Promise<void> {
        this.user = user;
        return this.manager.storeUser(user);
    }

    removeUser(): Promise<void> {
        return this.manager.removeUser();
    }
}

export function getClientSettings(): UserManagerSettings {
    let redirectUrl = location.protocol + '//' + location.host + '/auth-callback';
    let postLogOutUrl = location.protocol + '//' + location.host + '/signout';
    // identityServerApi is set only for Okta identity server and required a bit different scope
    // from the legacy identity server

    return {
        authority: environment.authentication.identityServer,
        client_id: environment.authentication.clientId,
        redirect_uri: redirectUrl,
        post_logout_redirect_uri: postLogOutUrl,
        response_type: 'id_token token',
        scope: 'openid profile swap-app-api',
        filterProtocolClaims: true,
        loadUserInfo: false,
        userStore: new WebStorageStateStore({ store: window.localStorage }),
    };
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { AccountRoutingModule } from './account-routing.module';
import { LoginComponent } from './login/login.component';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { HttpClientModule } from '@angular/common/http';
import { SignoutComponent } from './signout/signout.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared.module';
import { PaymentMethodService } from '../registration/shared/payment-method-service/payment-method.service';
import { SubscriptionTypeService } from '../registration/shared/subscription-type-service/subscription-type.service';
import { UserDetailsModelService } from './services/user-details-model-service/user-details-model-service';
import { AccountComponent } from './account.component';
import { FooterComponent } from './footer/footer.component';
import { UiModule } from '../ui/ui.module';
import { AllSubscriptionsComponent } from './subscriptions/all-subscriptions/all-subscriptions.component';
import { HeaderModule } from './header/header.module';
import { ChangePasswordModule } from './change-password/change-password.module';
import { SubscriptionsModule } from './subscriptions/subscriptions.module';
import { VoucherModule } from './voucher/voucher.module';
import { ReferralComponent } from './referral/referral.component';
import { AddressDetailsComponent } from './relocation/address-details/address-details.component';
import { RelocationDateComponent } from './relocation/relocation-date/relocation-date.component';
import { RelocationTypeComponent } from './relocation/relocation-type/relocation-type.component';
import { RelocationConfirmationComponent } from './relocation/relocation-confirmation/relocation-confirmation.component';
import { ProfileService } from './services/profile-service/profile.service';
import { ProfileModule } from './profile/profile.module';

@NgModule({
    imports: [
        CommonModule,
        AccountRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        HeaderModule,
        ChangePasswordModule,
        SubscriptionsModule,
        ProfileModule,
        VoucherModule,
        UiModule,
    ],
    providers: [PaymentMethodService, SubscriptionTypeService, UserDetailsModelService, ProfileService],
    exports: [ChangePasswordComponent, AllSubscriptionsComponent],
    declarations: [
        AccountComponent,
        FooterComponent,
        HomeComponent,
        LoginComponent,
        AuthCallbackComponent,
        SignoutComponent,
        ReferralComponent,
        AddressDetailsComponent,
        RelocationDateComponent,
        RelocationTypeComponent,
        RelocationConfirmationComponent,
    ],
})
export class AccountModule {}

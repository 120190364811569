import { Pipe, PipeTransform } from '@angular/core';
import { Notification } from 'rxjs';

@Pipe({ name: 'hasValue' })
export class DematrializeValuePipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        console.debug('dematerializing value', value);
        if (value instanceof Notification) {
            const notification: Notification<any> = <Notification<any>>value;
            return notification.kind == 'N' && notification.value;
        }
        return undefined;
    }
}

<ul class="swapfiets__subscription__desktop-menu">
    <li *ngFor="let item of menu">
        <a
            (click)="navigate(item.link)"
            [ngClass]="{ 'subscription__menu__link--active': item.id === active }"
            class="subscription__menu__link"
        >
            {{ item.label | translate }}
        </a>
    </li>
</ul>

<ul class="swapfiets__subscription__mobile-menu">
    <app-row-link
        *ngFor="let item of menu"
        [link]="item.link"
        [active]="item.id === active"
        class="swapfiets__subscription__mobile-menu__link"
    >
        {{ item.label | translate }}
    </app-row-link>
</ul>

import { Component, Input, OnInit } from '@angular/core';
import { overlayPop } from './overlay.animation';
import { OverlayService } from '../services/overlay.service';

@Component({
    selector: 'app-overlay',
    templateUrl: './overlay.component.html',
    animations: [overlayPop],
    styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent implements OnInit {
    /***
     * @description used to open or close overlay
     */
    @Input() open: boolean;

    /***
     * @description optional offset to top for overlay wrapper
     */
    @Input() offsetHeight: number;

    constructor(private overlay: OverlayService) {}

    ngOnInit() {}

    clickAway() {
        this.overlay.disableOverlay();
    }
}

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/auth-service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

@Component({
    selector: 'app-auth-callback',
    templateUrl: './auth-callback.component.html',
    styleUrls: ['./auth-callback.component.scss'],
})
export class AuthCallbackComponent implements OnInit {
    constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router) {}

    ngOnInit() {
        this.authService
            .completeAuthentication()
            .then((user) => {
                this.authService.storeUser(user).then(() => {
                    this.router.navigateByUrl(`/${user.state?.targetUrl ?? 'home'}`, { replaceUrl: true });
                });
            })
            .catch((err) => {
                Sentry.captureException(err);
                this.router.navigateByUrl('/home', { replaceUrl: true });
            });
    }
}

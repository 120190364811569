<section class="mobile-menu__wrapper__backdrop" [ngClass]="{ 'mobile-menu__wrapper__backdrop--open': open }"></section>

<section class="mobile-menu__wrapper" [@menuAppear]="open" (@menuAppear.done)="animationComplete($event)">
    <div class="padding-extralarge">
        <ul class="mobile-menu__list">
            <li>
                <a routerLink="/profile" routerLinkActive="active" class="mobile__menu__link-row">
                    {{ 'account.header.profile' | translate }}
                    <span class="mobile__menu__link--icon"></span>
                </a>
            </li>

            <li>
                <a routerLink="/subscriptions" routerLinkActive="active" class="mobile__menu__link-row">
                    {{ 'account.header.subscription' | translate }}
                    <span class="mobile__menu__link--icon"></span>
                </a>
            </li>
            <li>
                <a routerLink="/referral" routerLinkActive="active" class="mobile__menu__link-row has-icon">
                    <span>
                        <img class="menu-link-icon" src="/assets/icons/gift.svg" />
                        {{ 'account.header.invitefriends_experiment' | translate }}
                    </span>
                    <span class="mobile__menu__link--icon"></span>
                </a>
            </li>
            <li>
                <a routerLink="/changepassword" routerLinkActive="active" class="mobile__menu__link-row">
                    {{ 'account.header.password' | translate }}
                    <span class="mobile__menu__link--icon"></span>
                </a>
            </li>
            <li>
                <a [href]="appLink" class="mobile__menu__link-row" data-met="Menu - DownloadTheApp - CTA"
                    >{{ 'enroll_success.download_the_app' | translate }} <span class="mobile__menu__link--icon"></span>
                </a>
            </li>
        </ul>
    </div>
    <div class="row padding-extralarge mobile-menu__extra-spacer">
        <div class="col-12">
            <div class="line-bottom"></div>
        </div>
    </div>
    <div class="row padding-extralarge">
        <div class="col-12">
            <h2>{{ 'account.footer.contact' | translate }}</h2>
        </div>
        <div class="col-12 col-sm-6">
            <p>
                <a href="#"><i class="fa fa-phone" aria-hidden="true"></i> {{ getSwapdesk() }}</a>
            </p>
            <p>
                <a href="mailto:info@swapfiets.com"
                    ><i class="fa fa-envelope-o" aria-hidden="true"></i>info@swapfiets.com</a
                >
            </p>
        </div>
    </div>
    <div class="row padding-extralarge mobile-menu__extra-spacer">
        <div class="col-12">
            <div class="line-bottom"></div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <ul class="menu__lower-links">
                <li>
                    <a href="https://help.swapfiets.nl/hc/en-gb" class="bottom-link">Help</a>
                </li>
                <li>
                    <a (click)="signout()" class="bottom-link" data-met="Menu - Log out - CTA">{{
                        'account.header.sign_out' | translate
                    }}</a>
                </li>
            </ul>
        </div>
    </div>
</section>

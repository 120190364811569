import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[ltrim-zeros]',
})
export class LtrimZerosDirective {
    @Input() ngModel: string;

    constructor(private el: ElementRef) {
        (el.nativeElement as HTMLInputElement).value = '';
    }

    @HostListener('change')
    onChange() {
        (this.el.nativeElement as HTMLInputElement).value = (this.el.nativeElement as HTMLInputElement).value.replace(
            /^0+/,
            ''
        );
    }
}

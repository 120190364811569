import { Injectable } from '@angular/core';
import { AssetCategory } from '../../../shared/models/asset-category';

@Injectable({
    providedIn: 'root',
})
export class StepsService {
    public readonly STEP_DATA = 'data';
    public readonly STEP_CHECK = 'check';
    public readonly STEP_DELIVERY = 'delivery';
    public readonly STEP_PAYMENT = 'payment';
    public readonly STEP_CONFIRMATION = 'confirmation';

    private readonly oldSteps = [this.STEP_DATA, this.STEP_CHECK, this.STEP_PAYMENT, this.STEP_CONFIRMATION];

    private readonly deliverySteps = [this.STEP_DATA, this.STEP_DELIVERY, this.STEP_CHECK, this.STEP_PAYMENT];

    private readonly deliveryFlowAssetCategories: string[] = [
        AssetCategory.org,
        AssetCategory.dlu,
        AssetCategory['org-one-plus'],
        AssetCategory.ebike,
        AssetCategory['pwr-one'],
    ];

    public steps: string[];

    /***
     * @description sets Steps for enroll process
     * @param assetCategory
     * @param country
     * @param disableDelivery
     */
    updateForSubscriptionBusinessunit({
        assetCategory,
        disableDelivery,
    }: {
        assetCategory: AssetCategory;
        disableDelivery: boolean;
    }): string[] {
        if (this.deliveryFlowAssetCategories.indexOf(assetCategory) > -1 && !disableDelivery) {
            this.steps = this.deliverySteps;
        } else {
            this.steps = this.oldSteps;
        }

        return this.steps;
    }
}

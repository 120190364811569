import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EnvironmentService } from '../environment-service/environment.service';
import { AbstractPopupDirective } from '../abstract-popup/abstract-popup.directive';
import { TranslateService } from '../translate-service/translate-service';
import { AuthService } from '../auth-service/auth.service';
import { environment } from '../../../environments/environment';

export const EMAIL_STATE = {
    start: 'EMAIL_START',
    sent: 'EMAIL_SENT',
};
@Component({
    templateUrl: './popup-email.component.html',
    styleUrls: ['./popup-email.component.scss'],
})
export class PopupEmailComponent extends AbstractPopupDirective implements OnInit {
    modalContent = EMAIL_STATE.start;
    isSending = false;
    referralForm: FormGroup;
    emails: FormArray;
    readonly EMAIL_STATES = EMAIL_STATE;

    constructor(
        public environmentService: EnvironmentService,
        private authService: AuthService,
        private http: HttpClient,
        private fb: FormBuilder,
        private translateService: TranslateService
    ) {
        super();
    }

    ngOnInit() {
        this.referralForm = this.fb.group({
            emails: this.fb.array([this.createItem()]),
        });
    }

    closeModal() {
        this.close();
    }

    createItem(): FormGroup {
        return this.fb.group({
            email: ['', [Validators.required, Validators.email]],
        });
    }

    addItem(): void {
        this.emails = this.referralForm.get('emails') as FormArray;

        if (this.emails.length < 10) {
            this.emails.push(this.createItem());
        }
    }

    removeItem(i): void {
        this.emails = this.referralForm.get('emails') as FormArray;
        this.emails.removeAt(i);
    }

    sendEmails() {
        this.isSending = true;
        this.emails = this.referralForm.get('emails') as FormArray;
        let locale = this.translateService.getLocale();
        let emails = this.emails.value.map((e) => e.email).filter((e) => e !== '');

        return this.http
            .post<any>(
                `${environment.frontendAPIRoot}v1/vouchers/SendReferralInvite?locale=${locale.language}-${locale.country}`,
                emails,
                { observe: 'response', responseType: 'json' }
            )
            .subscribe((data) => {
                if (data.status === 200) {
                    this.modalContent = EMAIL_STATE.sent;
                }

                this.isSending = false;
            });
    }
}

export const phonePrefixes = [
    {
        dialPrefix: '+31',
        isoCode: 'NL',
    },
    {
        dialPrefix: '+32',
        isoCode: 'BE',
    },
    {
        dialPrefix: '+49',
        isoCode: 'DE',
    },
    {
        dialPrefix: '+43',
        isoCode: 'AT',
    },
    {
        dialPrefix: '+33',
        isoCode: 'FR',
    },
    {
        dialPrefix: '+34',
        isoCode: 'ES',
    },
    {
        dialPrefix: '+45',
        isoCode: 'DK',
    },
    {
        dialPrefix: '+39',
        isoCode: 'IT',
    },
    {
        dialPrefix: '+44',
        isoCode: 'GB',
    },
    {
        dialPrefix: '+93',
        isoCode: 'AF',
    },
    {
        dialPrefix: '+358',
        isoCode: 'AX',
    },
    {
        dialPrefix: '+355',
        isoCode: 'AL',
    },
    {
        dialPrefix: '+213',
        isoCode: 'DZ',
    },
    {
        dialPrefix: '+1684',
        isoCode: 'AS',
    },
    {
        dialPrefix: '+376',
        isoCode: 'AD',
    },
    {
        dialPrefix: '+244',
        isoCode: 'AO',
    },
    {
        dialPrefix: '+1264',
        isoCode: 'AI',
    },
    {
        dialPrefix: '+672',
        isoCode: 'AQ',
    },
    {
        dialPrefix: '+1268',
        isoCode: 'AG',
    },
    {
        dialPrefix: '+54',
        isoCode: 'AR',
    },
    {
        dialPrefix: '+374',
        isoCode: 'AM',
    },
    {
        dialPrefix: '+297',
        isoCode: 'AW',
    },
    {
        dialPrefix: '+61',
        isoCode: 'AU',
    },

    {
        dialPrefix: '+994',
        isoCode: 'AZ',
    },
    {
        dialPrefix: '+1242',
        isoCode: 'BS',
    },
    {
        dialPrefix: '+973',
        isoCode: 'BH',
    },
    {
        dialPrefix: '+880',
        isoCode: 'BD',
    },
    {
        dialPrefix: '+1246',
        isoCode: 'BB',
    },
    {
        dialPrefix: '+375',
        isoCode: 'BY',
    },
    {
        dialPrefix: '+501',
        isoCode: 'BZ',
    },
    {
        dialPrefix: '+229',
        isoCode: 'BJ',
    },
    {
        dialPrefix: '+1441',
        isoCode: 'BM',
    },
    {
        dialPrefix: '+975',
        isoCode: 'BT',
    },
    {
        dialPrefix: '+591',
        isoCode: 'BO',
    },
    {
        dialPrefix: '+387',
        isoCode: 'BA',
    },
    {
        dialPrefix: '+267',
        isoCode: 'BW',
    },
    {
        dialPrefix: '+55',
        isoCode: 'BR',
    },
    {
        dialPrefix: '+246',
        isoCode: 'IO',
    },
    {
        dialPrefix: '+673',
        isoCode: 'BN',
    },
    {
        dialPrefix: '+359',
        isoCode: 'BG',
    },
    {
        dialPrefix: '+226',
        isoCode: 'BF',
    },
    {
        dialPrefix: '+257',
        isoCode: 'BI',
    },
    {
        dialPrefix: '+855',
        isoCode: 'KH',
    },
    {
        dialPrefix: '+237',
        isoCode: 'CM',
    },
    {
        dialPrefix: '+1',
        isoCode: 'CA',
    },
    {
        dialPrefix: '+238',
        isoCode: 'CV',
    },
    {
        dialPrefix: '+ 345',
        isoCode: 'KY',
    },
    {
        dialPrefix: '+236',
        isoCode: 'CF',
    },
    {
        dialPrefix: '+235',
        isoCode: 'TD',
    },
    {
        dialPrefix: '+56',
        isoCode: 'CL',
    },
    {
        dialPrefix: '+86',
        isoCode: 'CN',
    },
    {
        dialPrefix: '+61',
        isoCode: 'CX',
    },
    {
        dialPrefix: '+61',
        isoCode: 'CC',
    },
    {
        dialPrefix: '+57',
        isoCode: 'CO',
    },
    {
        dialPrefix: '+269',
        isoCode: 'KM',
    },
    {
        dialPrefix: '+242',
        isoCode: 'CG',
    },
    {
        dialPrefix: '+243',
        isoCode: 'CD',
    },
    {
        dialPrefix: '+682',
        isoCode: 'CK',
    },
    {
        dialPrefix: '+506',
        isoCode: 'CR',
    },
    {
        dialPrefix: '+225',
        isoCode: 'CI',
    },
    {
        dialPrefix: '+385',
        isoCode: 'HR',
    },
    {
        dialPrefix: '+53',
        isoCode: 'CU',
    },
    {
        dialPrefix: '+357',
        isoCode: 'CY',
    },
    {
        dialPrefix: '+420',
        isoCode: 'CZ',
    },
    {
        dialPrefix: '+253',
        isoCode: 'DJ',
    },
    {
        dialPrefix: '+1767',
        isoCode: 'DM',
    },
    {
        dialPrefix: '+1849',
        isoCode: 'DO',
    },
    {
        dialPrefix: '+593',
        isoCode: 'EC',
    },
    {
        dialPrefix: '+20',
        isoCode: 'EG',
    },
    {
        dialPrefix: '+503',
        isoCode: 'SV',
    },
    {
        dialPrefix: '+240',
        isoCode: 'GQ',
    },
    {
        dialPrefix: '+291',
        isoCode: 'ER',
    },
    {
        dialPrefix: '+372',
        isoCode: 'EE',
    },
    {
        dialPrefix: '+251',
        isoCode: 'ET',
    },
    {
        dialPrefix: '+500',
        isoCode: 'FK',
    },
    {
        dialPrefix: '+298',
        isoCode: 'FO',
    },
    {
        dialPrefix: '+679',
        isoCode: 'FJ',
    },
    {
        dialPrefix: '+358',
        isoCode: 'FI',
    },

    {
        dialPrefix: '+594',
        isoCode: 'GF',
    },
    {
        dialPrefix: '+689',
        isoCode: 'PF',
    },
    {
        dialPrefix: '+241',
        isoCode: 'GA',
    },
    {
        dialPrefix: '+220',
        isoCode: 'GM',
    },
    {
        dialPrefix: '+995',
        isoCode: 'GE',
    },

    {
        dialPrefix: '+233',
        isoCode: 'GH',
    },
    {
        dialPrefix: '+350',
        isoCode: 'GI',
    },
    {
        dialPrefix: '+30',
        isoCode: 'GR',
    },
    {
        dialPrefix: '+299',
        isoCode: 'GL',
    },
    {
        dialPrefix: '+1473',
        isoCode: 'GD',
    },
    {
        dialPrefix: '+590',
        isoCode: 'GP',
    },
    {
        dialPrefix: '+1671',
        isoCode: 'GU',
    },
    {
        dialPrefix: '+502',
        isoCode: 'GT',
    },
    {
        dialPrefix: '+44',
        isoCode: 'GG',
    },
    {
        dialPrefix: '+224',
        isoCode: 'GN',
    },
    {
        dialPrefix: '+245',
        isoCode: 'GW',
    },
    {
        dialPrefix: '+595',
        isoCode: 'GY',
    },
    {
        dialPrefix: '+509',
        isoCode: 'HT',
    },
    {
        dialPrefix: '+379',
        isoCode: 'VA',
    },
    {
        dialPrefix: '+504',
        isoCode: 'HN',
    },
    {
        dialPrefix: '+852',
        isoCode: 'HK',
    },
    {
        dialPrefix: '+36',
        isoCode: 'HU',
    },
    {
        dialPrefix: '+354',
        isoCode: 'IS',
    },
    {
        dialPrefix: '+91',
        isoCode: 'IN',
    },
    {
        dialPrefix: '+62',
        isoCode: 'ID',
    },
    {
        dialPrefix: '+98',
        isoCode: 'IR',
    },
    {
        dialPrefix: '+964',
        isoCode: 'IQ',
    },
    {
        dialPrefix: '+353',
        isoCode: 'IE',
    },
    {
        dialPrefix: '+44',
        isoCode: 'IM',
    },
    {
        dialPrefix: '+972',
        isoCode: 'IL',
    },
    {
        dialPrefix: '+1876',
        isoCode: 'JM',
    },
    {
        dialPrefix: '+81',
        isoCode: 'JP',
    },
    {
        dialPrefix: '+44',
        isoCode: 'JE',
    },
    {
        dialPrefix: '+962',
        isoCode: 'JO',
    },
    {
        dialPrefix: '+77',
        isoCode: 'KZ',
    },
    {
        dialPrefix: '+254',
        isoCode: 'KE',
    },
    {
        dialPrefix: '+686',
        isoCode: 'KI',
    },
    {
        dialPrefix: '+850',
        isoCode: 'KP',
    },
    {
        dialPrefix: '+82',
        isoCode: 'KR',
    },
    {
        dialPrefix: '+965',
        isoCode: 'KW',
    },
    {
        dialPrefix: '+996',
        isoCode: 'KG',
    },
    {
        dialPrefix: '+856',
        isoCode: 'LA',
    },
    {
        dialPrefix: '+371',
        isoCode: 'LV',
    },
    {
        dialPrefix: '+961',
        isoCode: 'LB',
    },
    {
        dialPrefix: '+266',
        isoCode: 'LS',
    },
    {
        dialPrefix: '+231',
        isoCode: 'LR',
    },
    {
        dialPrefix: '+218',
        isoCode: 'LY',
    },
    {
        dialPrefix: '+423',
        isoCode: 'LI',
    },
    {
        dialPrefix: '+370',
        isoCode: 'LT',
    },
    {
        dialPrefix: '+352',
        isoCode: 'LU',
    },
    {
        dialPrefix: '+853',
        isoCode: 'MO',
    },
    {
        dialPrefix: '+389',
        isoCode: 'MK',
    },
    {
        dialPrefix: '+261',
        isoCode: 'MG',
    },
    {
        dialPrefix: '+265',
        isoCode: 'MW',
    },
    {
        dialPrefix: '+60',
        isoCode: 'MY',
    },
    {
        dialPrefix: '+960',
        isoCode: 'MV',
    },
    {
        dialPrefix: '+223',
        isoCode: 'ML',
    },
    {
        dialPrefix: '+356',
        isoCode: 'MT',
    },
    {
        dialPrefix: '+692',
        isoCode: 'MH',
    },
    {
        dialPrefix: '+596',
        isoCode: 'MQ',
    },
    {
        dialPrefix: '+222',
        isoCode: 'MR',
    },
    {
        dialPrefix: '+230',
        isoCode: 'MU',
    },
    {
        dialPrefix: '+262',
        isoCode: 'YT',
    },
    {
        dialPrefix: '+52',
        isoCode: 'MX',
    },
    {
        dialPrefix: '+691',
        isoCode: 'FM',
    },
    {
        dialPrefix: '+373',
        isoCode: 'MD',
    },
    {
        dialPrefix: '+377',
        isoCode: 'MC',
    },
    {
        dialPrefix: '+976',
        isoCode: 'MN',
    },
    {
        dialPrefix: '+382',
        isoCode: 'ME',
    },
    {
        dialPrefix: '+1664',
        isoCode: 'MS',
    },
    {
        dialPrefix: '+212',
        isoCode: 'MA',
    },
    {
        dialPrefix: '+258',
        isoCode: 'MZ',
    },
    {
        dialPrefix: '+95',
        isoCode: 'MM',
    },
    {
        dialPrefix: '+264',
        isoCode: 'NA',
    },
    {
        dialPrefix: '+674',
        isoCode: 'NR',
    },
    {
        dialPrefix: '+977',
        isoCode: 'NP',
    },
    {
        dialPrefix: '+599',
        isoCode: 'AN',
    },
    {
        dialPrefix: '+687',
        isoCode: 'NC',
    },
    {
        dialPrefix: '+64',
        isoCode: 'NZ',
    },
    {
        dialPrefix: '+505',
        isoCode: 'NI',
    },
    {
        dialPrefix: '+227',
        isoCode: 'NE',
    },
    {
        dialPrefix: '+234',
        isoCode: 'NG',
    },
    {
        dialPrefix: '+683',
        isoCode: 'NU',
    },
    {
        dialPrefix: '+672',
        isoCode: 'NF',
    },
    {
        dialPrefix: '+1670',
        isoCode: 'MP',
    },
    {
        dialPrefix: '+47',
        isoCode: 'NO',
    },
    {
        dialPrefix: '+968',
        isoCode: 'OM',
    },
    {
        dialPrefix: '+92',
        isoCode: 'PK',
    },
    {
        dialPrefix: '+680',
        isoCode: 'PW',
    },
    {
        dialPrefix: '+970',
        isoCode: 'PS',
    },
    {
        dialPrefix: '+507',
        isoCode: 'PA',
    },
    {
        dialPrefix: '+675',
        isoCode: 'PG',
    },
    {
        dialPrefix: '+595',
        isoCode: 'PY',
    },
    {
        dialPrefix: '+51',
        isoCode: 'PE',
    },
    {
        dialPrefix: '+63',
        isoCode: 'PH',
    },
    {
        dialPrefix: '+872',
        isoCode: 'PN',
    },
    {
        dialPrefix: '+48',
        isoCode: 'PL',
    },
    {
        dialPrefix: '+351',
        isoCode: 'PT',
    },
    {
        dialPrefix: '+1939',
        isoCode: 'PR',
    },
    {
        dialPrefix: '+974',
        isoCode: 'QA',
    },
    {
        dialPrefix: '+40',
        isoCode: 'RO',
    },
    {
        dialPrefix: '+7',
        isoCode: 'RU',
    },
    {
        dialPrefix: '+250',
        isoCode: 'RW',
    },
    {
        dialPrefix: '+262',
        isoCode: 'RE',
    },
    {
        dialPrefix: '+590',
        isoCode: 'BL',
    },
    {
        dialPrefix: '+290',
        isoCode: 'SH',
    },
    {
        dialPrefix: '+1869',
        isoCode: 'KN',
    },
    {
        dialPrefix: '+1758',
        isoCode: 'LC',
    },
    {
        dialPrefix: '+590',
        isoCode: 'MF',
    },
    {
        dialPrefix: '+508',
        isoCode: 'PM',
    },
    {
        dialPrefix: '+1784',
        isoCode: 'VC',
    },
    {
        dialPrefix: '+685',
        isoCode: 'WS',
    },
    {
        dialPrefix: '+378',
        isoCode: 'SM',
    },
    {
        dialPrefix: '+239',
        isoCode: 'ST',
    },
    {
        dialPrefix: '+966',
        isoCode: 'SA',
    },
    {
        dialPrefix: '+221',
        isoCode: 'SN',
    },
    {
        dialPrefix: '+381',
        isoCode: 'RS',
    },
    {
        dialPrefix: '+248',
        isoCode: 'SC',
    },
    {
        dialPrefix: '+232',
        isoCode: 'SL',
    },
    {
        dialPrefix: '+65',
        isoCode: 'SG',
    },
    {
        dialPrefix: '+421',
        isoCode: 'SK',
    },
    {
        dialPrefix: '+386',
        isoCode: 'SI',
    },
    {
        dialPrefix: '+677',
        isoCode: 'SB',
    },
    {
        dialPrefix: '+252',
        isoCode: 'SO',
    },
    {
        dialPrefix: '+27',
        isoCode: 'ZA',
    },
    {
        dialPrefix: '+211',
        isoCode: 'SS',
    },
    {
        dialPrefix: '+500',
        isoCode: 'GS',
    },

    {
        dialPrefix: '+94',
        isoCode: 'LK',
    },
    {
        dialPrefix: '+249',
        isoCode: 'SD',
    },
    {
        dialPrefix: '+597',
        isoCode: 'SR',
    },
    {
        dialPrefix: '+47',
        isoCode: 'SJ',
    },
    {
        dialPrefix: '+268',
        isoCode: 'SZ',
    },
    {
        dialPrefix: '+46',
        isoCode: 'SE',
    },
    {
        dialPrefix: '+41',
        isoCode: 'CH',
    },
    {
        dialPrefix: '+963',
        isoCode: 'SY',
    },
    {
        dialPrefix: '+886',
        isoCode: 'TW',
    },
    {
        dialPrefix: '+992',
        isoCode: 'TJ',
    },
    {
        dialPrefix: '+255',
        isoCode: 'TZ',
    },
    {
        dialPrefix: '+66',
        isoCode: 'TH',
    },
    {
        dialPrefix: '+670',
        isoCode: 'TL',
    },
    {
        dialPrefix: '+228',
        isoCode: 'TG',
    },
    {
        dialPrefix: '+690',
        isoCode: 'TK',
    },
    {
        dialPrefix: '+676',
        isoCode: 'TO',
    },
    {
        dialPrefix: '+1868',
        isoCode: 'TT',
    },
    {
        dialPrefix: '+216',
        isoCode: 'TN',
    },
    {
        dialPrefix: '+90',
        isoCode: 'TR',
    },
    {
        dialPrefix: '+993',
        isoCode: 'TM',
    },
    {
        dialPrefix: '+1649',
        isoCode: 'TC',
    },
    {
        dialPrefix: '+688',
        isoCode: 'TV',
    },
    {
        dialPrefix: '+256',
        isoCode: 'UG',
    },
    {
        dialPrefix: '+380',
        isoCode: 'UA',
    },
    {
        dialPrefix: '+971',
        isoCode: 'AE',
    },

    {
        dialPrefix: '+1',
        isoCode: 'US',
    },
    {
        dialPrefix: '+598',
        isoCode: 'UY',
    },
    {
        dialPrefix: '+998',
        isoCode: 'UZ',
    },
    {
        dialPrefix: '+678',
        isoCode: 'VU',
    },
    {
        dialPrefix: '+58',
        isoCode: 'VE',
    },
    {
        dialPrefix: '+84',
        isoCode: 'VN',
    },
    {
        dialPrefix: '+1284',
        isoCode: 'VG',
    },
    {
        dialPrefix: '+1340',
        isoCode: 'VI',
    },
    {
        dialPrefix: '+681',
        isoCode: 'WF',
    },
    {
        dialPrefix: '+967',
        isoCode: 'YE',
    },
    {
        dialPrefix: '+260',
        isoCode: 'ZM',
    },
    {
        dialPrefix: '+263',
        isoCode: 'ZW',
    },
];

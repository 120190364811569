import { Component, OnInit } from '@angular/core';
import { Country } from '../../shared/models/CountryLanguage';
import { UserDetailsModelService } from '../services/user-details-model-service/user-details-model-service';

// The "relocation" feature is initially only being rolled out to NL customers.
// All other customers will see the old profile page, which uses different endpoints and doesn't offer relocation possibilites.
// After relocation is available in all countries, the "profile-old" component should no longer be used.
@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
})
export class ProfileComponent implements OnInit {
    shouldRenderNLProfileComponent: boolean = false;

    constructor(private userDetailsModelService: UserDetailsModelService) {}

    ngOnInit() {
        this.userDetailsModelService.getModel().subscribe((userDetails) => {
            const subscriptions = userDetails.subscriptions;
            const country = userDetails.country;

            if (country !== Country.NL || subscriptions.length > 1) {
                this.shouldRenderNLProfileComponent = false;
            } else {
                this.shouldRenderNLProfileComponent = true;
            }
        });
    }
}

import { Component, ElementRef, ContentChild, Renderer2, AfterContentInit, OnDestroy, Input } from '@angular/core';
import { FormFieldComponent } from '../form-field/form-field.component';
import { IbanService } from '../../registration/shared/iban-service/iban.service';

@Component({
    selector: 'iban-field-container',
    templateUrl: './iban-field-container.component.html',
    styleUrls: ['../form-field-container/form-field-container.component.scss'],
})
export class IbanFieldContainerComponent implements AfterContentInit, OnDestroy {
    @ContentChild(FormFieldComponent, { read: ElementRef }) inputElement: ElementRef;

    @Input() countryName: string;

    private blurUnlistenFunction: Function;
    private blurred = false;
    missingMessage: boolean = false;

    constructor(private renderer: Renderer2, private ibanService: IbanService) {}

    ngAfterContentInit() {
        if (!this.inputElement || !this.inputElement.nativeElement) {
            return;
        }

        this.blurUnlistenFunction = this.renderer.listen(this.inputElement.nativeElement, 'blur', (evt) => {
            this.runValidation();
            this.blurred = true;
        });
    }

    ngOnDestroy() {
        if (this.blurUnlistenFunction instanceof Function) {
            this.blurUnlistenFunction();
        }
    }

    runValidation() {
        this.missingMessage = false;

        if (!this.inputElement.nativeElement.value && this.inputElement.nativeElement.hasAttribute('required')) {
            this.missingMessage = true;
        }
    }

    get errorMessage(): boolean {
        return (
            !this.missingMessage && this.blurred && !this.ibanService.isValidIBAN(this.inputElement.nativeElement.value)
        );
    }

    // Dutch bank accounts need to use iDeal
    get errorMessageNoDutch(): boolean {
        return (
            this.ibanService.isValidIBAN(this.inputElement.nativeElement.value) &&
            !this.ibanService.isAllowedCountry(this.inputElement.nativeElement.value, this.countryName)
        );
    }
}

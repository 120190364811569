import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PopupServiceDirective } from './shared/popup-service/popup-service.directive';
import { PopupHostComponent } from './shared/popup-host-component/popup-host.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SharedModule } from './shared.module';
import { EnvironmentService } from './shared/environment-service/environment.service';
import { AuthGuardService } from './shared/auth-service/auth-guard.service';
import { AuthService } from './shared/auth-service/auth.service';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { GtmService } from './shared/google-tag-manager/gtm-service';
import { SharedCookieService } from './shared/shared-cookies/shared-cookie-service';
import { CookieConsentService } from './shared/cookie-consent-service/cookie-consent-service';
import { UiModule } from './ui/ui.module';
import { SubscriptionCancelService } from './account/services/subscription-cancel-service/subscription-cancel-service';
import { RequestErrorInterceptor } from './shared/request-error-interceptor/request-error.interceptor';
import { RequestTokenInterceptor } from './shared/request-token-interceptor/request-token.interceptor';
import { StagingGuardService } from './shared/environment-service/staging-guard.service';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        BrowserTransferStateModule,
        BrowserAnimationsModule,
        SharedModule,
        FormsModule,
        HttpClientModule,
        UiModule,
    ],
    providers: [
        PopupServiceDirective,
        EnvironmentService,
        AuthGuardService,
        StagingGuardService,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler(),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        AuthService,
        SubscriptionCancelService,
        GtmService,
        SharedCookieService,
        CookieConsentService,
        CookieService,
        { provide: HTTP_INTERCEPTORS, useClass: RequestTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: RequestErrorInterceptor, multi: true },
    ],
    bootstrap: [AppComponent],
    exports: [PopupHostComponent],
    declarations: [AppComponent, PageNotFoundComponent, PopupHostComponent],
})
export class AppModule {
    constructor(trace: Sentry.TraceService) {}
}

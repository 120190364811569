<header id="header">
    <app-header></app-header>
</header>

<main id="main">
    <router-outlet #o="outlet"></router-outlet>
</main>

<footer id="footer">
    <app-footer></app-footer>
</footer>

import { Component, OnInit } from '@angular/core';

import { NgForm } from '@angular/forms';
import { captureException } from '@sentry/angular';
import { relocationEvents } from '../../../shared/google-tag-manager/events/relocationGTM';

import {
    AddressDetails,
    RelocationDate,
    RelocationFormDataService,
    step,
} from '../../services/subscription-relocation-form-service/subscription-relocation-form-service';

@Component({
    selector: 'app-relocation-date',
    templateUrl: './relocation-date.component.html',
    styleUrls: ['./relocation-date.component.scss'],
})
export class RelocationDateComponent implements OnInit {
    addressDetails: AddressDetails;
    relocationDate: RelocationDate;
    relocationForm: RelocationFormDataService;
    isLoading: boolean = false;
    hasError: boolean = false;

    constructor(relocationFormDataService: RelocationFormDataService) {
        this.relocationDate = relocationFormDataService.relocationDate;
        this.addressDetails = relocationFormDataService.addressDetails;
        this.relocationForm = relocationFormDataService;
    }

    ngOnInit(): void {
        this.relocationForm.gtm(relocationEvents.selectDateStep);
    }

    goBack(): void {
        this.relocationForm.goBack(step.date);
    }

    submitForm(form: NgForm) {
        this.isLoading = true;
        this.relocationForm.submitRelocation().subscribe(
            (response) => {
                this.isLoading = false;
                this.relocationForm.proceed({ stepArg: step.date });
            },
            (error) => {
                captureException(new Error('Something went wrong during relocation submission'), {
                    extra: {
                        error: JSON.stringify(error),
                        payload: JSON.stringify({
                            address: this.relocationForm.addressDetails,
                            region: this.relocationForm.newRegion,
                            coordinates: this.relocationForm.geoCoordinates,
                        }),
                    },
                });
                this.hasError = true;
                this.isLoading = false;
            }
        );
    }
}

import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { EnvironmentService } from '../../../shared/environment-service/environment.service';
import relocationEvent, { relocationEvents } from '../../../shared/google-tag-manager/events/relocationGTM';
import { Country } from '../../../shared/models/country';
import {
    RelocationFormDataService,
    RelocationType,
    step,
} from '../../services/subscription-relocation-form-service/subscription-relocation-form-service';

@Component({
    selector: 'app-relocation-type',
    templateUrl: './relocation-type.component.html',
    styleUrls: ['./relocation-type.component.scss'],
})
export class RelocationTypeComponent implements OnInit {
    relocationType: RelocationType;
    relocationForm: RelocationFormDataService;
    country: Country;

    constructor(relocationFormDataService: RelocationFormDataService, private environmentService: EnvironmentService) {
        this.relocationType = relocationFormDataService.relocationType;
        this.relocationForm = relocationFormDataService;
        this.environmentService = environmentService;
    }

    ngOnInit(): void {
        this.country = this.environmentService.getCountry();
        this.relocationForm.gtm(relocationEvents.bikeTypeStep);
    }

    onNewBikeSelection() {
        this.relocationForm.gtm(relocationEvents.newBikeDesired);
    }

    onKeepBikeSelection() {
        this.relocationForm.gtm(relocationEvents.customerMovesBike);
    }

    goBack(): void {
        this.relocationForm.goBack(step.type);
    }

    submitForm(form: NgForm) {
        this.relocationForm.proceed({ stepArg: step.type });
    }
}

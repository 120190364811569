import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-loader',
    templateUrl: './icon-loader.component.html',
    styleUrls: ['./icon-loader.component.scss'],
})
export class IconLoaderComponent implements OnInit {
    // @ts-ignore
    iconSprite = require('!svg-inline-loader!./../../../assets/icons/sprite.svg');

    constructor() {}

    ngOnInit() {}
}

<div class="container-fluid margin-extralarge">
    <h1>{{ 'relocation.page.title' | translate }}</h1>
    <h3>{{ 'relocation.date.new_address' | translate }}</h3>
    <p>{{ addressDetails.street }} {{ addressDetails.houseNumber }}</p>
    <p>{{ addressDetails.postalCode }}</p>
    <p>{{ addressDetails.city }}</p>
    <p>{{ 'relocation.date.current_bike' | translate }}</p>
    <form class="mt-4" #dateForm="ngForm" (ngSubmit)="submitForm(dateForm)">
        <h3>{{ 'relocation.date.new_date_of_relocation' | translate }}</h3>
        <input [disabled]="isLoading" type="date" name="date" [(ngModel)]="relocationDate.date" required />

        <div class="row error" *ngIf="hasError">
            {{ 'relocation.error.general' | translate }}
        </div>

        <div class="row col-6 mt-2">
            <button type="submit" class="btn btn-green" [disabled]="!dateForm.form.valid || isLoading">
                {{ 'relocation.button.proceed' | translate }}
                <i *ngIf="isLoading" class="fa fa-spin fa-spinner"></i>
            </button>
            <button class="btn btn-secondary" (click)="goBack()">{{ 'relocation.button.back' | translate }}</button>
        </div>
    </form>
</div>

<div class="container-fluid" style="padding-left: 0">
    <div class="row">
        <div class="col-12">
            <h2 class="voucher__title--card">
                {{ 'account.subscription.stores_title' | translate }}
                <span *ngIf="subscription">{{ this.subscription.businessUnitName }}</span>
            </h2>
            <hr class="d-lg-none" />
            <div *ngFor="let store of storeList; let i = index" class="store-container">
                <p>
                    {{ store.street }} {{ store.houseNumber
                    }}<a href="{{ getMapUrl(store.addressLine) }}" target="_BLANK" class="store-route-link">Route</a>
                </p>
                <p *ngIf="store.isOpen">
                    <i class="fa fa-circle" style="color: #7ed321" aria-hidden="true"></i>
                    {{ 'account.subscription.stores.today_open_till' | translate }} {{ store.openinghours[today] }}.
                </p>
                <p *ngIf="!store.isOpen">
                    <i class="fa fa-circle" style="color: #ff1414" aria-hidden="true"></i>
                    {{ 'account.subscription.stores.closed' | translate }}
                </p>
                <p class="store__unfold-link" (click)="openClose(i)">
                    {{ 'account.subscription.stores.openingtimes' | translate }}
                    <i
                        class="fa fa-chevron-down"
                        [ngClass]="{ 'icon-open': businessHoursOpen == i, 'icon-closed': businessHoursOpen != i }"
                        aria-hidden="true"
                        style="color: #00b8fb"
                    ></i>
                </p>
                <div>
                    <div
                        [ngClass]="{ 'hours-open': businessHoursOpen == i, 'hours-item-open': businessHoursOpen == i }"
                    >
                        <div
                            class="store__business-hours__wrapper"
                            [@slideOpen]="businessHoursOpen == i ? 'open' : 'closed'"
                        >
                            <table>
                                <tr>
                                    <td class="store__hours-open__label">
                                        {{ 'weekDays.1' | translate }}
                                    </td>
                                    <td>
                                        {{
                                            store.openinghours.monday_start
                                                ? store.openinghours.monday_start +
                                                  ' - ' +
                                                  store.openinghours.monday_close
                                                : '-'
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {{ 'weekDays.2' | translate }}
                                    </td>
                                    <td>
                                        {{
                                            store.openinghours.tuesday_start
                                                ? store.openinghours.tuesday_start +
                                                  ' - ' +
                                                  store.openinghours.tuesday_close
                                                : '-'
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {{ 'weekDays.3' | translate }}
                                    </td>
                                    <td>
                                        {{
                                            store.openinghours.wednesday_start
                                                ? store.openinghours.wednesday_start +
                                                  ' - ' +
                                                  store.openinghours.wednesday_close
                                                : '-'
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {{ 'weekDays.4' | translate }}
                                    </td>
                                    <td>
                                        {{
                                            store.openinghours.thursday_start
                                                ? store.openinghours.thursday_start +
                                                  ' - ' +
                                                  store.openinghours.thursday_close
                                                : '-'
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {{ 'weekDays.5' | translate }}
                                    </td>
                                    <td>
                                        {{
                                            store.openinghours.friday_start
                                                ? store.openinghours.friday_start +
                                                  ' - ' +
                                                  store.openinghours.friday_close
                                                : '-'
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {{ 'weekDays.6' | translate }}
                                    </td>
                                    <td>
                                        {{
                                            store.openinghours.saturday_start
                                                ? store.openinghours.saturday_start +
                                                  ' - ' +
                                                  store.openinghours.saturday_close
                                                : '-'
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {{ 'weekDays.0' | translate }}
                                    </td>
                                    <td>
                                        {{
                                            store.openinghours.sunday_start
                                                ? store.openinghours.sunday_start +
                                                  ' - ' +
                                                  store.openinghours.sunday_close
                                                : '-'
                                        }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

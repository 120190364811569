import { Component, HostListener, ViewChild, ElementRef } from '@angular/core';
import { environment } from '../../../environments/environment';
import { EnrollmentService } from '../shared/enrollment-service/enrollment.service';

@Component({
    selector: 'app-payment-expired',
    templateUrl: './payment-expired.component.html',
    styleUrls: ['./payment-expired.component.scss'],
})
export class PaymentExpiredComponent {
    @ViewChild('cardSvg', { static: true }) svgElement: ElementRef;

    constructor(private enrollmentService: EnrollmentService) {}

    @HostListener('document:mousemove', ['$event'])
    onMouseMove($event) {
        let mousex = $event.clientX;
        let mousey = $event.clientY;

        let eyes = document.getElementsByClassName('black_eye');
        for (let i = 0; i < eyes.length; i++) {
            let offset = eyes[i].getBoundingClientRect();
            let dx = mousex - (offset.left + document.body.scrollLeft);
            let dy = mousey - (offset.top + document.body.scrollTop);

            let dist = Math.sqrt(dx * dx + dy * dy);
            if (dist > 4) {
                dx = (dx * 4) / dist;
                dy = (dy * 4) / dist;
            }

            dx += Number(eyes[i].previousElementSibling.getAttribute('cx'));
            dy += Number(eyes[i].previousElementSibling.getAttribute('cy'));

            eyes[i].setAttribute('cx', dx.toString());
            eyes[i].setAttribute('cy', dy.toString());
        }
    }

    navigateToSite() {
        let enrollment = this.enrollmentService.LoadEnrollmentInfo();
        let key = enrollment.country || 'NL';
        let country = environment.countries.find((c) => c.key == key) || environment.countries[0];
        window.location.href = country.website;
    }
}

<form-field-container>
    <input
        form-field
        type="password"
        [(ngModel)]="newPassword"
        name="newPassword"
        placeholder="{{ 'account.password.new_password' | translate }}"
        autocomplete="section-changepassword new-password"
    />
</form-field-container>
<form-field-container>
    <input
        form-field
        type="password"
        [(ngModel)]="repeatPassword"
        name="repeatPassword"
        placeholder="{{ 'account.password.repeat_password' | translate }}"
        autocomplete="section-changepassword repeat -password"
    />
</form-field-container>
<div class="password-validator" [ngClass]="{ enabled: newPassword.length > 3 }">
    <p>{{ 'account.password.must_contain' | translate }}:</p>
    <ul>
        <li [ngClass]="{ valid: isNumberValid() }">
            {{ 'account.password.one_number' | translate }}
        </li>
        <li [ngClass]="{ valid: isCaseValid() }">
            {{ 'account.password.upper_lower' | translate }}
        </li>
        <li [ngClass]="{ valid: isLengthValid() }">
            {{ 'account.password.at_least_characters' | translate }}
        </li>
        <li [ngClass]="{ valid: isSpecialValid() }">
            {{ 'account.password.special_character' | translate }}
        </li>
    </ul>
</div>

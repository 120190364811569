import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Modules
import { AccountModule } from './account/account.module';
import { RegistrationModule } from './registration/registration.module';

// Component
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
    {
        path: 'registration',
        loadChildren: () => import('./registration/registration.module').then((m) => m.RegistrationModule),
    },
    {
        path: ':locale/ConfirmSwap',
        loadChildren: () => import('./confirm-swap/confirm-swap.module').then((m) => m.ConfirmSwapModule),
    },
    { path: '', loadChildren: () => import('./account/account.module').then((m) => m.AccountModule) },
    { path: '**', component: PageNotFoundComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: 'reload',
            scrollPositionRestoration: 'top',
            relativeLinkResolution: 'legacy',
        }),
        AccountModule,
        RegistrationModule,
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}

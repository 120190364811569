import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

export const overlayPop = trigger('overlayPop', [
    state(
        '0',
        style({
            opacity: 0,
            display: 'none',
        })
    ),
    state(
        '1',
        style({
            opacity: 1,
            display: 'flex',
        })
    ),
    transition('0 => 1', [
        animate(
            '0.2s',
            keyframes([
                style({
                    display: 'flex',
                    opacity: 0,
                }),
                style({
                    opacity: 1,
                }),
            ])
        ),
    ]),
    transition('1 => 0', [
        animate(
            '0.2s 0.1s',
            keyframes([
                style({
                    opacity: 1,
                    display: 'flex',
                    pointerEvents: 'none',
                }),
                style({
                    opacity: 0,
                }),
                style({
                    opacity: 0,
                    display: 'none',
                }),
            ])
        ),
    ]),
]);

import { Injectable } from '@angular/core';
import { TranslateService } from '../../../shared/translate-service/translate-service';

@Injectable()
export class CodiceFiscaleService {
    constructor(private translateService: TranslateService) {}
    /**
     * Checks if a code fiscale is valid.
     * @param code The code to check
     * @param surname The surname to validate against. (optional)
     * @param name The name to validate against. (optional)
     * @param birthday The birthday to validate against. If provided, the gender is required too. (optional)
     * @param gender The gender to validate against. If provided, the birthday is required too. (optional)
     */
    public isValidCodiceFiscale(
        code: string,
        surname: string,
        name: string,
        birthday: Date,
        gender: 'F' | 'M',
        invalidProperties: string[]
    ): boolean {
        if (!invalidProperties) {
            invalidProperties = [];
        }

        if (typeof code !== 'string') {
            invalidProperties.push(this.translateService.translate('account.fiscalcode.missing'));
            return false;
        }
        if (code.length !== 16) {
            invalidProperties.push(this.translateService.translate('account.fiscalcode.incorrect_length'));
            return false;
        }

        let validCode = true;
        code = code.toUpperCase();

        const expectedCheckCode = code.charAt(15);
        const cf = code.slice(0, 15);
        if (this.checkCode(cf) !== expectedCheckCode) {
            invalidProperties.push(this.translateService.translate('account.fiscalcode.incorrect_last_char'));
            validCode = false;
        }

        let codiceFiscaleSubstr = '';
        let surnameCode = '';
        if (surname) {
            surnameCode = this.surnameCode(surname);
            codiceFiscaleSubstr += surnameCode;
        }

        let nameCode = '';
        if (name) {
            nameCode = this.nameCode(name);
            codiceFiscaleSubstr += nameCode;
        }

        // both birthday and gender are needed
        if ((birthday && !gender) || (!birthday && gender)) {
            if (!birthday) {
                invalidProperties.push(this.translateService.translate('account.fiscalcode.missing_birthdate'));
            }
            if (!gender) {
                invalidProperties.push(this.translateService.translate('account.fiscalcode.missing_gender'));
            }

            validCode = false;
        }

        if (!validCode) {
            return false;
        }

        let dateGenderCode = '';
        if (birthday && gender) {
            dateGenderCode = this.dateGenderCode(
                birthday.getDate(),
                birthday.getMonth(),
                birthday.getFullYear(),
                gender
            );
            codiceFiscaleSubstr += dateGenderCode;
        }

        if (!code.startsWith(codiceFiscaleSubstr)) {
            if (code.substring(0, 3) != surnameCode) {
                invalidProperties.push(this.translateService.translate('account.fiscalcode.incorrect_last_name'));
            }
            if (code.substring(3, 6) != nameCode) {
                invalidProperties.push(this.translateService.translate('account.fiscalcode.incorrect_first_name'));
            }

            if (dateGenderCode && dateGenderCode.length == 5) {
                if (code.substring(6, 8) != dateGenderCode.substring(0, 2)) {
                    invalidProperties.push(this.translateService.translate('account.fiscalcode.incorrect_birthyear'));
                }
                if (code.substring(8, 9) != dateGenderCode.substring(2, 3)) {
                    invalidProperties.push(this.translateService.translate('account.fiscalcode.incorrect_birthmonth'));
                }
                if (code.substring(9, 11) != dateGenderCode.substring(3, 5)) {
                    invalidProperties.push(
                        this.translateService.translate('account.fiscalcode.incorrect_birthdate_gender')
                    );
                }
            } else {
                invalidProperties.push(
                    this.translateService.translate('account.fiscalcode.incorrect_birthdate_gender')
                );
            }

            return false;
        }

        return true;
    }

    private surnameCode(surname: string): string {
        const surnameCode = extractConsonants(surname) + extractVowels(surname) + 'XXX';
        return surnameCode.substr(0, 3).toUpperCase();
    }

    private nameCode(name: string): string {
        let nameCode = extractConsonants(name);
        if (nameCode.length >= 4) {
            nameCode = nameCode.charAt(0) + nameCode.charAt(2) + nameCode.charAt(3);
        } else {
            nameCode += extractVowels(name) + 'XXX';
            nameCode = nameCode.substr(0, 3);
        }
        return nameCode.toUpperCase();
    }

    private dateGenderCode(day: number, month: number, year: number, gender: 'F' | 'M'): string {
        let yearStr = '0' + year;
        yearStr = yearStr.substr(yearStr.length - 2, 2);
        const monthStr = MONTH_CODES[month];
        if (gender.toUpperCase() === 'F') {
            day += 40;
        }
        let dayStr = '0' + day;
        dayStr = dayStr.substr(dayStr.length - 2, 2);
        return yearStr + monthStr + dayStr;
    }

    private checkCode(code: string): string {
        code = code.toUpperCase();
        let val = 0;
        for (let i = 0; i < 15; i++) {
            const c = code[i];
            val += i % 2 !== 0 ? CHECK_CODE_EVEN[c] : CHECK_CODE_ODD[c];
        }
        val = val % 26;
        return String.fromCharCode(65 + val);
    }
}

function extractConsonants(str) {
    return str.replace(/[^BCDFGHJKLMNPQRSTVWXYZ]/gi, '');
}

function extractVowels(str) {
    return str.replace(/[^AEIOU]/gi, '');
}

const MONTH_CODES = ['A', 'B', 'C', 'D', 'E', 'H', 'L', 'M', 'P', 'R', 'S', 'T'];
const CHECK_CODE_ODD = {
    0: 1,
    1: 0,
    2: 5,
    3: 7,
    4: 9,
    5: 13,
    6: 15,
    7: 17,
    8: 19,
    9: 21,
    A: 1,
    B: 0,
    C: 5,
    D: 7,
    E: 9,
    F: 13,
    G: 15,
    H: 17,
    I: 19,
    J: 21,
    K: 2,
    L: 4,
    M: 18,
    N: 20,
    O: 11,
    P: 3,
    Q: 6,
    R: 8,
    S: 12,
    T: 14,
    U: 16,
    V: 10,
    W: 22,
    X: 25,
    Y: 24,
    Z: 23,
};
const CHECK_CODE_EVEN = {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    A: 0,
    B: 1,
    C: 2,
    D: 3,
    E: 4,
    F: 5,
    G: 6,
    H: 7,
    I: 8,
    J: 9,
    K: 10,
    L: 11,
    M: 12,
    N: 13,
    O: 14,
    P: 15,
    Q: 16,
    R: 17,
    S: 18,
    T: 19,
    U: 20,
    V: 21,
    W: 22,
    X: 23,
    Y: 24,
    Z: 25,
};

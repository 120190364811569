import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GiftCardService } from '../../shared/giftcard-service/giftcard.service';
import { gsap, Power1, Power2, Power3 } from 'gsap';

@Component({
    selector: 'app-voucher',
    templateUrl: './voucher.component.html',
    styleUrls: ['./voucher.component.scss'],
})
export class VoucherComponent implements OnInit {
    subscriptionId: string;
    validatedCredit: number;
    invalidvoucher: boolean = false;
    vouchercode: string;
    redeemedCredit: number;
    animationEnd: boolean = false;
    tl = gsap.timeline({
        paused: true,
        repeat: -1,
        repeatDelay: 0.6,
        onRepeat: () => {
            if (this.animationEnd) {
                this.tl.pause();
                this.tl2.play();
            }
        },
    });
    tl2 = gsap.timeline({ paused: true });
    debounceSubject: Subject<string> = new Subject();

    @ViewChild('animationTop', { static: true }) animationTop: ElementRef;
    @ViewChild('animationBottom', { static: true }) animationBottom: ElementRef;
    @ViewChild('animationBike', { static: true }) animationBike: ElementRef;
    @ViewChild('animationBox', { static: true }) animationBox: ElementRef;

    constructor(private route: ActivatedRoute, private giftCardService: GiftCardService) {}

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.subscriptionId = params['id'];
        });

        let top = this.animationTop.nativeElement;
        let bottom = this.animationBottom.nativeElement;
        let bike = this.animationBike.nativeElement;
        let box = this.animationBox.nativeElement;

        this.tl
            .set(box, { transformOrigin: 'bottom left' })
            .to(box, { duration: 0.3, rotation: -20, scaleY: 1.15, ease: Power2.easeOut })
            .to(box, { duration: 0.3,rotation: 0, scaleY: 1, ease: Power2.easeIn })
            .set(box, { transformOrigin: 'bottom right' }, '+=0.1')
            .to(box, { duration: 0.3, rotation: 20, scaleY: 1.15, ease: Power2.easeOut })
            .to(box, { duration: 0.3, rotation: 0, scaleY: 1, ease: Power2.easeIn });

        this.tl2
            .set(bottom, { transformOrigin: 'bottom left' }, '+=0.1')
            .to(bottom, { duration: 0.2, rotation: -20, scaleY: 1.15, ease: Power2.easeOut })
            .to(bottom,  { duration: 0.2, rotation: 0, scaleY: 1, ease: Power2.easeIn })
            .set(top, { transformOrigin: 'center center' }, '-=0.4')
            .to(top,  { duration: 0.15, top: '-=30px', ease: Power2.easeOut }, '-=0.4')
            .to(top,  { duration: 0.3, left: '-=80px', ease: Power2.easeOut }, '-=0.4')
            .to(top,  { duration: 0.6, rotation: -180, ease: Power2.easeOut }, '-=0.4')
            .to(top,  { duration: 0.35, top: '+=82px', ease: Power2.easeIn }, '-=0.45')
            .set(bottom, { transformOrigin: 'bottom right' })
            .to(bottom,  { duration: 0.4, rotation: 47, transformOrigin: 'right bottom', ease: Power3.easeOut }, '+=-0.1')
            .to(bottom,  { duration: 0.2, scaleY: 1, skewX: 0, ease: Power2.easeInOut }, '-=0.4')
            .to(bottom,  { duration: 0.4, rotation: 43, transformOrigin: 'right bottom', ease: Power1.easeInOut })
            .to(bottom,  { duration: 0.4, rotation: 90, transformOrigin: 'right bottom', ease: Power3.easeIn })
            .to(bike,  { duration: 0.8, left: '70px', ease: Power2.easeOut }, '+=0.5');

        this.tl2.timeScale(0.75);
    }

    voucherCodeChange(event) {
        this.debounceSubject.next(event);

        this.validatedCredit = null;
        this.invalidvoucher = false;

        this.animateFirst();
    }

    redeem() {
        this.giftCardService.RedeemGiftCard(this.vouchercode, this.subscriptionId).subscribe(
            (data) => {
                this.redeemedCredit = data.credit;
                this.animateSecond();
            },
            (error) => {
                this.validatedCredit = null;
                this.invalidvoucher = true;
            }
        );
    }

    private animateFirst() {
        this.tl.play();
    }

    private animateSecond() {
        this.animationEnd = true;
        window.scrollTo(0, 0);
    }
}

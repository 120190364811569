import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { captureException, Severity } from '@sentry/angular';
import { Subscription } from 'rxjs';
import { pairwise } from 'rxjs/operators';
import { EnvironmentService } from '../../../shared/environment-service/environment.service';
import { relocationEvents } from '../../../shared/google-tag-manager/events/relocationGTM';
import {
    AddressDetails,
    RelocationFormDataService,
    step,
} from '../../services/subscription-relocation-form-service/subscription-relocation-form-service';

@Component({
    selector: 'app-address-details',
    templateUrl: './address-details.component.html',
    styleUrls: ['./address-details.component.scss'],
})
export class AddressDetailsComponent implements OnInit {
    @ViewChild('addressForm', { static: true }) addressForm: NgForm;

    addressFormSubscription: Subscription;
    addressDetails: AddressDetails;
    relocationFormService: RelocationFormDataService;

    isSubmitted: boolean = false;
    isInsideServiceArea: boolean = false;
    isSameCity: boolean = false;
    isLoading: boolean = false;
    hasInvalidAddressError: boolean = false;
    hasRequestError: boolean = false;
    isAddressChecked: boolean = false;
    isSubInsideServiceArea: boolean = false;
    newRegion: string = undefined;
    geoCoordinates: { latitude: string; longitude: string } = undefined;
    closestMatchingNewRegion: string = undefined;

    constructor(relocationFormDataService: RelocationFormDataService, private environmentService: EnvironmentService) {
        this.relocationFormService = relocationFormDataService;
        this.environmentService = environmentService;
        this.addressDetails = relocationFormDataService.addressDetails;
    }

    ngOnInit() {
        this.relocationFormService.gtm(relocationEvents.changeAddress);

        this.addressFormSubscription = this.addressForm.form.valueChanges
            .pipe(pairwise())
            .subscribe(([prevFormValue, nextFormValue]) => {
                if (this.isSubmitted && !this.addressForm.pristine) {
                    // When the user edits the form after previously submitting we want to reset the service area check/submitted state
                    // UNLESS the user specifically changes the tickbox acknowledging the address is outside of the new service area
                    if (nextFormValue.serviceArea !== prevFormValue.serviceArea) {
                        return;
                    } else {
                        this.isAddressChecked = false;
                        this.isSubmitted = false;
                    }
                }
            });
    }

    ngOnDestroy() {
        this.addressFormSubscription.unsubscribe();
    }

    convertAddressToGeocode() {
        this.hasRequestError = false;
        this.hasInvalidAddressError = false;
        this.isAddressChecked = false;
        this.isLoading = true;

        this.relocationFormService.geocodeAddress(this.addressDetails).subscribe(
            (response) => {
                if (response.length === 1) {
                    this.getSubAndGeocodeIsInsideServiceArea({
                        lat: response[0].latitude,
                        long: response[0].longitude,
                    });
                } else if (response.length === 0) {
                    // no adress results found
                    this.hasInvalidAddressError = true;
                    this.relocationFormService.gtm(relocationEvents.addressNotFound);
                    this.isLoading = false;
                } else {
                    // multiple results edge case here
                    if (response.length > 1) {
                        // log to sentry and monitor how often this occurs
                        captureException(new Error('Multiple addresses returned from geocoding/v1/Geocode endpoint'), {
                            level: Severity.Warning,
                            extra: {
                                address: JSON.stringify(this.addressDetails),
                                response: JSON.stringify(response),
                            },
                        });
                    }
                }
            },
            (error) => {
                this.isLoading = false;
                this.hasRequestError = true;
                console.error({ error });
            }
        );
    }

    getSubAndGeocodeIsInsideServiceArea({ lat, long }: { lat: string; long: string }) {
        this.relocationFormService.checkGeocodeWithinServiceArea({ lat, long }).subscribe(
            (geoCodeResponse) => {
                if (geoCodeResponse.geoLocationIsWithinSwapfietsServiceAreas) {
                    this.isInsideServiceArea = true;
                }
                if (!geoCodeResponse.geoLocationIsWithinSwapfietsServiceAreas) {
                    this.isInsideServiceArea = false;
                }
                if (geoCodeResponse.geoLocationIsWithinRegionServiceArea) {
                    this.isSameCity = true;
                }
                if (geoCodeResponse.regionCodeOfClosestRegionForGeolocation) {
                    this.closestMatchingNewRegion = geoCodeResponse.regionCodeOfClosestRegionForGeolocation;
                }

                this.relocationFormService
                    .checkSubscriptionAvailableInRegion({
                        regionCode:
                            geoCodeResponse.regionCodeForGeoLocation ??
                            geoCodeResponse.regionCodeOfClosestRegionForGeolocation,
                    })
                    .subscribe(
                        (res) => {
                            if (res.isSubscriptionTypeAvailableInRegion) {
                                this.isSubInsideServiceArea = true;
                                this.relocationFormService.gtm(relocationEvents.addressInServiceArea);
                            }
                            if (!res.isSubscriptionTypeAvailableInRegion) {
                                this.isSubInsideServiceArea = false;
                                this.relocationFormService.gtm(relocationEvents.subscriptionNotSupported);
                            }
                            this.newRegion =
                                geoCodeResponse.regionCodeForGeoLocation ??
                                geoCodeResponse.regionCodeOfClosestRegionForGeolocation;
                            this.geoCoordinates = { latitude: lat, longitude: long };

                            this.isAddressChecked = true;
                            this.isLoading = false;
                        },
                        (err) => {
                            console.error({ err });
                            this.hasRequestError = true;
                            this.isLoading = false;
                        }
                    );
            },
            (err) => {
                console.error({ err });
                this.hasRequestError = true;
                this.isLoading = false;
            }
        );
    }

    /**
     * Get regex string for validating postal code
     */
    get postalCodePattern(): string {
        return this.environmentService.getCountry().postalCodeValidator;
    }

    submitForm(addressDetailsForm: NgForm) {
        this.isSubmitted = true;

        console.log({
            isAddresChecked: this.isAddressChecked,
            isInsideServiceArea: this.isInsideServiceArea,
            newRegion: this.newRegion,
        });

        if (this.isAddressChecked && this.isSubInsideServiceArea && this.newRegion) {
            this.relocationFormService.setNewRegion(this.newRegion);
            this.relocationFormService.setGeocoordinates({
                latitude: this.geoCoordinates.latitude,
                longitude: this.geoCoordinates.longitude,
            });
            this.relocationFormService.proceed({ stepArg: step.address, isSameCity: this.isSameCity });
        } else {
            this.relocationFormService.gtm(relocationEvents.checkAddress);
            addressDetailsForm.form.markAsPristine();
            this.convertAddressToGeocode();
        }
    }
}

<div
    class="payment-button"
    *ngFor="let item of paymentMethodList"
    (click)="setPaymentMethod(item)"
    [ngClass]="{
        selected: isPaymentButtonSelected(item),
        expandable: item.constructor.name == 'Array',
        idealbox: isIdeal(selectedPaymentMethod),
        'no-text': bottomText == null
    }"
    [hidden]="!hasPaymentMethod(item)"
>
    <img
        class="mini-icon"
        src="/assets/paymentmethods/{{ item.constructor.name == 'Array' ? 'debit' : getPaymentItemString(item) }}.png"
    />
    <div class="title">
        <h4>
            {{ getPaymentItemName(item) }}
        </h4>
        <p>
            {{ bottomText }}
        </p>
    </div>
    <div
        class="content"
        *ngIf="
            isPaymentButtonSelected(item) &&
            (item.constructor.name == 'Array' ||
                isIdeal(selectedPaymentMethod) ||
                isSepaDirectDebit(selectedPaymentMethod))
        "
    >
        <div *ngIf="item.constructor.name == 'Array'">
            <p class="margin-small" *ngIf="hasMultiplePaymentMethods(item)">
                {{ 'enroll.payment.select_payment_method' | translate }}
            </p>
            <div class="mini-icon-container margin-medium">
                <img
                    class="mini-icon"
                    *ngFor="let subitem of item"
                    src="/assets/paymentmethods/{{ getPaymentItemString(subitem) }}.png"
                    (click)="setPaymentMethod(subitem)"
                    [ngClass]="{ selected: isPaymentButtonSelected(subitem) }"
                    [hidden]="!hasPaymentMethod(subitem)"
                />
            </div>
        </div>

        <select *ngIf="isIdeal(selectedPaymentMethod)" [(ngModel)]="issuerBIC">
            <option value="">- {{ 'enroll.payment.chooseyourbank' | translate }} -</option>
            <option *ngFor="let issuerBic of issuerBics" value="{{ issuerBic }}">
                {{ issuers[issuerBic] }}
            </option>
        </select>

        <div class="ibanContainer" *ngIf="isSepaDirectDebit(selectedPaymentMethod)">
            <form #ibanForm="ngForm">
                <iban-field-container [countryName]="countryName">
                    <input
                        form-field
                        type="text"
                        placeholder="{{ 'enroll.payment.account_iban' | translate }}"
                        [(ngModel)]="sepaDirectDebitIban"
                        pattern="{{ lettersNumbersDashQuoteDotAndWhiteSpace }}"
                        name="sepaDirectDebitIban"
                        validateIban
                        required
                    />
                </iban-field-container>
                <form-field-container>
                    <input
                        form-field
                        type="text"
                        placeholder="{{ 'enroll.payment.account_name' | translate }}"
                        [(ngModel)]="sepaDirectDebitName"
                        pattern="{{ lettersNumbersDashQuoteDotAndWhiteSpace }}"
                        name="sepaDirectDebitName"
                        required
                    />
                </form-field-container>
                <span class="iban-message">{{ 'enroll.payment.iban_message' | translate }}</span>
            </form>
        </div>
    </div>
</div>

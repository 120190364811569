import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Location } from '../models/location';

@Injectable()
export class LocationService {
    constructor(private http: HttpClient) {}

    Get(businessUnitCode: string, isIntroweekSubscription: boolean = false): Observable<Location[]> {
        const url = environment.apiRoot + 'v2/locations/' + businessUnitCode + '?introweek=' + isIntroweekSubscription;

        return this.http.get<Location[]>(url).pipe(
            map((data: any) => {
                let list = [];
                for (let i = 0; i < data.result.length; i++) {
                    list.push(new Location(data.result[i]));
                }
                return list;
            })
        );
    }

    private Log(...params: any[]) {
        if (!environment.production && console.log) {
            let args = Array.prototype.slice.call(params);
            args.unshift('[LOCATION_SERVICE]');
            console.log.apply(console, args);
        }
    }
}

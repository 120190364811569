<img *ngIf="userDetails" class="bike-background-mobile" src="/assets/bikes/account/{{ bikeImageString }}.png" />
<img *ngIf="userDetails" class="bike-background-desktop" src="/assets/bikes/account-flip/{{ bikeImageString }}.png" />
<div class="container-fluid">
    <div class="row">
        <h1 class="col-6 offset-6 col-sm-5 offset-sm-7 col-lg-12 offset-lg-0">
            Hi,<br />
            {{ userDetails?.firstName }}&nbsp;
        </h1>
        <a routerLink="/subscriptions" class="col-3 d-none d-lg-block" data-met="Home - My Subscription - CTA">
            <h2>{{ 'account.home.my_subscription' | translate }}</h2>
            <p>{{ 'account.home.show_bikes' | translate }}</p>
        </a>
    </div>
</div>

<div class="container-fluid mobile-nav d-block d-lg-none">
    <div class="row">
        <div class="col-10 col-md-8 m-auto">
            <a routerLink="/profile" routerLinkActive="active">{{ 'account.header.profile' | translate }}</a>
            <a routerLink="/subscriptions" routerLinkActive="active">{{ 'account.header.subscription' | translate }}</a>
            <a routerLink="/referral" routerLinkActive="active">
                <img class="menu-link-icon" src="/assets/icons/gift.svg" class="link-icon" />
                {{ 'account.header.invitefriends_experiment' | translate }}
            </a>
            <a routerLink="/changepassword" routerLinkActive="active">{{ 'account.header.password' | translate }}</a>
            <a [href]="appLink">{{ 'enroll_success.download_the_app' | translate }}</a>
        </div>
    </div>
</div>

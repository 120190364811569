import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from '../../../../shared/models/subscription';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { EnvironmentService } from '../../../../shared/environment-service/environment.service';
import { Router } from '@angular/router';
import { PopupServiceDirective } from '../../../../shared/popup-service/popup-service.directive';
import { GetBikeImageString } from '../../subscription';
import { TranslateService } from '../../../../shared/translate-service/translate-service';
import { usabillaSurveys, usabillaCallbackActions, UsabillaCallbackParams } from '../../../../shared/models/usabilla';
import { BookedSwapblock } from '../../../../shared/models/booked-swapblock';
import { SwapType } from '../../../../shared/models/swap-type';

@Component({
    selector: 'app-subscription-info',
    templateUrl: './subscription-info.component.html',
    styleUrls: ['./subscription-info.component.scss'],
})
export class SubscriptionInfoComponent implements OnInit, OnChanges {
    /***
     * @description Components needs an active subscription to render
     */
    @Input() subscription: Subscription;

    /***
     * @description Active swapblock on a subscription (such as first delivery)
     */
    @Input() subscriptionSwapblock: BookedSwapblock | null;

    endDateReached = false;
    isSubscriptionCancellable = false;
    isContractViewable = false;
    movingFAQLink = null;

    constructor(
        private http: HttpClient,
        private popupService: PopupServiceDirective,
        private router: Router,
        private environmentService: EnvironmentService,
        private localeService: TranslateService
    ) {}

    ngOnInit() {
        this.getMovingFAQLink(this.subscription);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.subscription?.currentValue) {
            this.hasEndDateBeenReached(changes.subscription.currentValue);
            this.getSubscriptionCancellableState(changes.subscription.currentValue);
            this.getContractViewableState(changes.subscription.currentValue);
        }
    }

    downloadContract(subscriptionId) {
        this.http
            .get(this.environmentService.apiRoot + 'v1/subscription/' + subscriptionId + '/contract', {
                responseType: 'blob',
                observe: 'response',
            })
            .subscribe(function (response: HttpResponse<Blob>) {
                const data = window.URL.createObjectURL(response.body);
                let link = document.createElement('a');
                link.href = data;
                link.download = response.headers.get('Content-Disposition').replace('attachment; filename=', ''); // TODO: Make better
                link.click();
                setTimeout(function () {
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(data);
                }, 100);
            });
    }

    hasEndDateBeenReached(sub) {
        if (!sub.end) {
            return;
        }
        const today: any = new Date();
        this.endDateReached = Date.parse(sub.end) - Date.parse(today) < 0;
    }

    get getHasFirstDeliveryPlanned() {
        const swap = this.subscriptionSwapblock;
        return swap?.type === SwapType.FirstDelivery;
    }

    getSubscriptionCancellableState(sub: Subscription) {
        console.log({ sub });
        if (sub.start && !sub.end) {
            this.isSubscriptionCancellable = true;
        } else this.isSubscriptionCancellable = false;
    }

    getContractViewableState(sub: Subscription) {
        if (sub.start) {
            this.isContractViewable = true;
        } else this.isContractViewable = false;
    }

    getMovingFAQLink(sub: Subscription) {
        let lang = this.localeService.getLocale().language;

        if (sub.businessUnitCountryCultureInfo.includes('NL')) {
            if (lang === 'nl') {
                this.movingFAQLink =
                    'https://help.swapfiets.nl/hc/nl/articles/360000658054-Ik-verhuis-naar-een-andere-stad-Kan-ik-mijn-Swapfiets-meenemen-';
            } else
                this.movingFAQLink =
                    'https://help.swapfiets.nl/hc/en-gb/articles/360000658054--I-am-moving-to-another-city-Can-I-bring-my-Swapfiets-';
        }
        if (sub.businessUnitCountryCultureInfo.includes('BE')) {
            if (lang === 'nl') {
                this.movingFAQLink =
                    'https://help.swapfiets.com/nl/verhuizen-naar-een-andere-stad';
            }
            if (lang === 'fr') {
                this.movingFAQLink =
                    'https://help.swapfiets.com/fr/je-d%C3%A9m%C3%A9nage-dans-une-autre-ville';
            } else
                this.movingFAQLink =
                    'https://help.swapfiets.com/i-am-moving-to-a-different-city';
        }
        if (sub.businessUnitCountryCultureInfo.includes('DE')) {
            if (lang === 'de') {
                this.movingFAQLink =
                    'https://help.swapfiets.com/de/ich-ziehe-in-eine-andere-stadt';
            } else {
                this.movingFAQLink =
                    'https://help.swapfiets.com/i-am-moving-to-a-different-city';
            }
        }
        if (sub.businessUnitCountryCultureInfo.includes('DK')) {
            if (lang === 'da') {
                this.movingFAQLink =
                    'https://help.swapfiets.com/da/jeg-flytter-til-en-anden-by';
            } else {
                this.movingFAQLink =
                    'https://help.swapfiets.com/i-am-moving-to-a-different-city';
            }
        }
        if (sub.businessUnitCountryCultureInfo.includes('FR')) {
            if (lang === 'fr') {
                this.movingFAQLink =
                    'https://help.swapfiets.com/fr/je-d%C3%A9m%C3%A9nage-dans-une-autre-ville';
            } else {
                this.movingFAQLink =
                    'https://help.swapfiets.com/i-am-moving-to-a-different-city';
            }
        }
    }

    handleMovingLink() {
        if (this.subscription.businessUnitCountryCultureInfo.includes('NL')) {
            this.router.navigate(['/relocation/address']);
            return;
        }

        const usabillaCampaign = this.subscription.businessUnitCountryCultureInfo.includes('DE')
            ? usabillaSurveys.movingDE
            : usabillaSurveys.movingNL;

        // Usabilla keeps track of whether a user has already seen a campaign in localStorage
        // We can make use of this, to ensure that customers that have already seen the campaign
        // get redirected to the faq page.
        // See: https://support.usabilla.com/hc/en-us/articles/115003780472-GetFeedback-Digital-cookies-and-localStorage
        const campaignKey = Object.keys(localStorage).find((key) => key.includes(usabillaCampaign.analyticsID));
        const campaignValues = localStorage.getItem(campaignKey);
        const skipCampaign = campaignValues?.[0] === 'T' || !campaignKey;

        if (
            (!this.subscription.businessUnitCountryCultureInfo.includes('DE') &&
                !this.subscription.businessUnitCountryCultureInfo.includes('NL')) ||
            !window.usabilla_live ||
            skipCampaign
        ) {
            window.location.href = this.movingFAQLink;
        } else {
            window.usabilla_live('data', {
                custom: {
                    subscriptionId: this.subscription.uuid,
                    bike: this.subscription.assets[0]?.assetCategory ?? 'unknown',
                    subCountry: this.subscription.cultureInfo,
                },
            });

            window.usabilla_live('trigger', usabillaCampaign.manualTrigger);

            const faqLink = this.movingFAQLink;
            window.usabilla_live(
                'setEventCallback',
                function (
                    category: UsabillaCallbackParams['category'],
                    action: UsabillaCallbackParams['action'],
                    label: UsabillaCallbackParams['label'],
                    value: UsabillaCallbackParams['value']
                ) {
                    if (Object.values(usabillaCallbackActions).includes(action)) {
                        setTimeout(() => (window.location.href = faqLink), 2000);
                        return;
                    } else return;
                }
            );
        }
    }

    cancelSubscription() {
        this.router.navigate(['subscriptions', this.subscription.uuid, 'cancel']);
    }

    undoCancelledSubscription() {
        this.popupService.showUndoCancelledSubscription(this.subscription);
    }

    getBikeImageString(subscription: Subscription) {
        return GetBikeImageString(subscription);
    }
}

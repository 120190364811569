<div class="steps container col-12">
    <div
        class="step"
        *ngFor="let step of steps; index as i"
        [ngClass]="{ current: currentStep == i, passed: currentStep > i }"
        (click)="clickStep(i)"
    >
        <span class="label">{{ 'enroll_steps.step_' + step | translate }}</span>
        <div class="circle">
            <span>{{ i + 1 }}</span>
        </div>
    </div>
</div>

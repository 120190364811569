import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { EnvironmentService } from '../../../shared/environment-service/environment.service';
import { UserDetails } from '../../../shared/models/userdetails';
import { Country } from '../../../shared/models/country';
import { AvailableLocale } from '../../../shared/models/available-locale';
import { ToastService } from '../../../shared/toast-service/toast.service';
import { Toast } from '../../../shared/toast-component/toast';
import { UserDetailsModelService } from '../../services/user-details-model-service/user-details-model-service';
import { FormFieldContainerComponent } from '../../../shared/form-field-container/form-field-container.component';
import { TranslateService } from '../../../shared/translate-service/translate-service';
import { Locale } from '../../../shared/models/CountryLanguage';
import { ReactiveComponent } from '../../../shared/reactive-component/reactive.component';

@Component({
    selector: 'app-profile-old',
    templateUrl: './profile-old.component.html',
    styleUrls: ['./profile-old.component.scss'],
})
export class ProfileComponentOld extends ReactiveComponent implements OnInit {
    @ViewChild('personalDetailsForm', { static: true }) personalDetailsForm: HTMLFormElement;
    @ViewChild('zipCodeContainer', { static: true }) zipCodeContainer: FormFieldContainerComponent;

    userDetails: UserDetails = {} as UserDetails;
    currentLocale = null;
    response: string;
    missingFields: Array<Object> = [];
    errorFields: Array<Object> = [];
    saving: boolean = false;
    isStaging: boolean;
    lettersNumbersDashQuoteDotAndWhiteSpace = "[A-Za-zÀ-ÿ0-9-.'\\s]*";

    constructor(
        private environmentService: EnvironmentService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private userDetailsModelService: UserDetailsModelService
    ) {
        super();
    }

    ngOnInit() {
        this.reactiveXSubs.add(
            this.userDetailsModelService.getModel().subscribe((userDetails) => {
                this.userDetails = userDetails;
                this.currentLocale = this.userDetails.locale;
            })
        );
    }

    /**
     * Get regex string for validating postal code
     */
    get postalCodePattern(): string {
        if (!this.userDetails.country) {
            return this.countries[0].postalCodeValidator;
        }
        return (
            this.countries.find((c) => c.key == this.userDetails.country).postalCodeValidator ||
            this.countries[0].postalCodeValidator
        );
    }

    countryChanged() {
        this.personalDetailsForm.form.controls.zipCode.markAsTouched();

        setTimeout(() => {
            this.zipCodeContainer.runValidation();
        }, 0);
    }

    /**
     * Scroll the page to the HTMLElement
     * @param element
     */
    scrollTo(element: HTMLElement) {
        element.scrollIntoView({ behavior: 'smooth' });
    }

    /**
     * All countries
     */
    get countries(): Country[] {
        return this.environmentService.countries;
    }

    /**
     * All locales
     */
    get locales(): AvailableLocale[] {
        return this.translateService.getLocales(this.userDetails.country);
    }

    updateUser(form: NgForm) {
        this.missingFields = [];
        this.errorFields = [];
        if (!form.valid) {
            Object.keys(form.controls).forEach((key) => {
                let control = form.controls[key];
                control.markAsTouched();
                if (control.invalid) {
                    let element = document.querySelector('[name=' + key + ']');
                    if (control.value == null || control.value == '') {
                        this.missingFields.push(element);
                    } else {
                        this.errorFields.push(element);
                    }
                }
            });
            return;
        }

        this.saving = true;
        this.userDetailsModelService.saveModel(this.userDetails).subscribe(
            (response) => {
                let toastText;
                let toastTime;
                let toastStyle;
                if (this.currentLocale != this.userDetails.locale) {
                    this.translateService.changeLocale(Locale.fromString(this.userDetails.locale));
                    this.currentLocale = this.userDetails.locale;
                    toastText = this.translateService.translate('account.profile.profile_saved_language_changed');
                    toastTime = 6000;
                    toastStyle = 'medium';
                } else {
                    toastText = this.translateService.translate('account.profile.profile_saved');
                    toastTime = 4000;
                    toastStyle = 'success';
                }
                this.saving = false;
                this.response = JSON.stringify(response);
                this.toastService.showToast(
                    new Toast({
                        text: toastText,
                        icon: 'check',
                        time: toastTime,
                        style: toastStyle,
                    })
                );
                this.personalDetailsForm.form.markAsPristine();
            },
            (error) => {
                this.saving = false;
                this.toastService.showToast(<Toast>{
                    text: this.translateService.translate('account.password.error_unknown'),
                    icon: 'times',
                    time: 6000,
                    style: 'error',
                });
                this.personalDetailsForm.form.markAsPristine();
            }
        );
    }
}

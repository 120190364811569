import { Injectable } from '@angular/core';
import { AuthService } from '../auth-service/auth.service';
import { EnvironmentService } from '../environment-service/environment.service';
import { TranslateService } from '../translate-service/translate-service';

// extend window for gtm dataLayer
declare global {
    interface Window {
        dataLayer: Record<string, any>[];
    }
}

@Injectable()
export class GtmService {
    constructor(
        private environmentService: EnvironmentService,
        private authService: AuthService,
        private translateService: TranslateService
    ) {}

    /**
     * Load Google Tag Manager by creating a script element in the body
     * This is a modified version of the minified snippet google provides.
     * Note that gtm's 2nd snippet is not included/needed because it is <noscript> and then this code can't run to begin with
     */
    init(distinctId: string) {
        let config = this.environmentService.gtmConfig;
        let s = 'script';
        let l = 'dataLayer';
        window[l] = window[l] || [];

        window[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
            mp_distinctid: distinctId,
        });

        let f = document.getElementsByTagName(s)[0];
        let j = document.createElement(s);
        let dl = l != 'dataLayer' ? '&l=' + l : '';

        j.setAttribute('async', 'true');
        j.setAttribute(
            'src',
            'https://www.googletagmanager.com/gtm.js?' +
                'id=' +
                config.id +
                dl +
                '&gtm_auth=' +
                config.auth +
                '&gtm_preview=' +
                config.preview +
                '&gtm_cookies_win=x'
        );

        f.parentNode.insertBefore(j, f);
    }
    initialDataLayer() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            userId: '',
            pageType: '',
            pageCountry: '',
            pageLanguage: this.translateService.getLocale().language,
        });
    }
    event(gtmEvent) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(gtmEvent);
    }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Locale } from '../../shared/models/CountryLanguage';
import { accountTranslateServiceFixer, getLocaleLanguageTag } from '../../shared/translate-service/account-translate-service-fixer';

@Component({
    selector: 'app-start',
    templateUrl: './start.component.html',
    styleUrls: ['./start.component.scss'],
})
export class StartComponent implements OnInit {
    constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

    ngOnInit() {
        let businessUnit: string;
        let subscriptionCode: string;
        let language: string;
        let label: string;
        let gaUrl: string = '';

        this.activatedRoute.queryParamMap.subscribe((params) => {
            businessUnit = params.get('region') || '';
            subscriptionCode = params.get('subscription') || '';
            language = params.get('lang');
            label = params.get('label') || '';
            if (params.get('_gl')) {
                gaUrl = `?_gl=${params.get('_gl')}`;
            }
            console.log(language);
            const fixedLocale = accountTranslateServiceFixer(language).toString();
            // If both are not null, continue.
            if (businessUnit == '' || subscriptionCode == '') {
                if (document.referrer) {
                    window.location.href = document.referrer;
                } else {
                    window.location.href = environment.countries[0].website;
                }
            } else {
                localStorage.setItem('rawLocale', language);
                localStorage.setItem('language', Locale.fromString(fixedLocale).toString());
                localStorage.setItem('localeLanguageTag', getLocaleLanguageTag(fixedLocale));
                localStorage.setItem('businessUnit', businessUnit.toLowerCase());
                localStorage.setItem('subscriptionCode', subscriptionCode);
                localStorage.setItem('label', label);
                localStorage.setItem('referrer', document.referrer);
                this.router.navigateByUrl('/registration/enroll' + gaUrl, { replaceUrl: true });
            }
        });
    }
}

import { LocationSwapblock } from './location-swapblock';

export class LocationInfo {
    closed: boolean;
    swapblocks: LocationSwapblock[];
    result: any;

    constructor(data: Partial<LocationInfo>) {
        data.swapblocks = [];
        for (let i = 0; i < data.result.length; i++) {
            data.swapblocks.push(new LocationSwapblock(data.result[i]));
        }
        delete data.result;
        Object.assign(this, data);
    }
}

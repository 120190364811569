import { Subscription } from 'rxjs';
import { Directive, OnDestroy } from '@angular/core';

/**
 * An abstract class that offers Subscriptions and destroys them after lifecycle
 */
@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class ReactiveComponent implements OnDestroy {
    /**
     * Keep track of Class subscribed Subscriptions
     */
    reactiveXSubs: Subscription = new Subscription();

    /**
     * After lifecycle remove all current subscriptions
     */
    ngOnDestroy(): void {
        this.reactiveXSubs.unsubscribe();
    }
}

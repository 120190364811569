import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractPopupDirective } from '../abstract-popup/abstract-popup.directive';
import { EnvironmentService } from '../environment-service/environment.service';
import { SubscriptionCancelService } from '../../account/services/subscription-cancel-service/subscription-cancel-service';
import { UserDetailsModelService } from '../../account/services/user-details-model-service/user-details-model-service';

@Component({
    selector: 'app-popup-undo-cancelled-subscription',
    templateUrl: './popup-undo-cancelled-subscription.component.html',
    styleUrls: ['./popup-undo-cancelled-subscription.component.scss'],
})
export class PopupUndoCancelledSubscriptionComponent extends AbstractPopupDirective {
    OGRouter: string;
    loading = false;
    error = false;

    constructor(
        public environmentService: EnvironmentService,
        private userDetailsModelService: UserDetailsModelService,
        private subscription: SubscriptionCancelService,
        public router: Router
    ) {
        super();

        this.OGRouter = this.router.url;
        window.history.pushState({}, '', `${this.router.url}/cancel-termination`);
    }

    confirm() {
        this.loading = true;
        this.subscription.undoCancelledSubscription(this.params.subscription.uuid).subscribe(
            (success) => {
                this.loading = false;
                const newSubscription = {
                    ...this.params.subscription,
                    subscriptionId: this.params.subscription.uuid,
                    end: null,
                };
                window.history.pushState({}, '', `${this.router.url}/cancel-termination/confirmed`);
                this.userDetailsModelService.setSubscription(newSubscription);
                this.closeDialog.emit(true);
                window.history.pushState({}, '', this.OGRouter);
            },
            (error) => {
                this.loading = false;
                this.error = true;
            }
        );
    }
}

import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

import { appearDropdown, appearDropdownInner, dropdown } from './dropdown.animation';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    animations: [dropdown, appearDropdown, appearDropdownInner],
    styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit, OnChanges {
    /***
     * @description provide parent element, this is the trigger needed to calculate placement
     */
    @Input() parentRef: any;

    /***
     * @description private boolean used to measure open and close state
     */
    @Input() open = false;

    /***
     * @description Optional padding inside the inner card
     */
    @Input() padding: boolean;

    /***
     * @description Optional override of width. Default = 400px
     */
    @Input() width: number;

    /***
     * @description Provide an height offset. This is the space between the trigger and the dropdown below
     */
    @Input() heightOffset: number = 0;

    resizeWindow$: Subscription;

    @ViewChild('dropdown', { static: true }) dropdownRef: ElementRef;

    offsetLeft: number;
    offsetTop: number;

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        this.listenToResize(changes.open.currentValue);
    }

    ngOnInit() {
        if (this.parentRef) {
            this.calculateOffset();
        } else {
            console.warn(`please provide a trigger to set dropdown component for ${this.parentRef}`);
        }
    }

    listenToResize(open: boolean) {
        this.calculateOffset();
        if (open) {
            this.resizeWindow$ = fromEvent(window, 'resize').subscribe(() => {
                this.calculateOffset();
            });
        } else {
            this.stopListeningResize();
        }
    }

    stopListeningResize() {
        if (this.resizeWindow$) {
            this.resizeWindow$.unsubscribe();
        }
    }

    calculateOffset() {
        setTimeout(() => {
            if (!this.parentRef) {
                return;
            }
            const boundingClientRect = this.parentRef.getBoundingClientRect();
            this.offsetLeft =
                boundingClientRect.left + boundingClientRect.width / 2 - this.dropdownRef.nativeElement.clientWidth / 2;
            this.offsetTop = boundingClientRect.top + this.heightOffset;
        });
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../../../../shared/auth-service/auth.service';
import { EnvironmentService } from '../../../../shared/environment-service/environment.service';
import { menuAppear } from './mobile-menu.animation';
import { UserDetails } from '../../../../shared/models/userdetails';
import { UserDetailsModelService } from '../../../services/user-details-model-service/user-details-model-service';

/***
 * @description responsible for the mobile wrapper animation
 */

@Component({
    selector: 'app-mobile-menu',
    templateUrl: './mobile-menu.component.html',
    animations: [menuAppear],
    styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit {
    @Input() open: boolean;
    @Input() appLink: string;

    @Output() animationDone: EventEmitter<void> = new EventEmitter<void>();

    userCountry: UserDetails['country'] = '';

    constructor(
        private authService: AuthService,
        private userDetailsModelService: UserDetailsModelService,
        private environmentService: EnvironmentService
    ) {}

    ngOnInit() {
        this.userDetailsModelService.getModel().subscribe((userDetails) => {
            this.userCountry = userDetails.country;
        });
    }

    animationComplete(e) {
        this.animationDone.emit();
    }

    signout() {
        this.authService.signOut().then(() => {
            return;
        });
    }

    getSwapdesk(): string {
        return this.environmentService.getCountry().swapdesk;
    }
}

import { EnrollmentInfo } from '../../../registration/shared/models/enrollment-info';
import { SubscriptionType } from '../../../registration/shared/models/subscription-type';
import { AssetCategory } from '../../models/asset-category';
import { Country } from '../../models/CountryLanguage';

type AssetPrice = {
    [key in AssetCategory]: number;
};

const REVENUE_COUNTRY_ASSSET_CATEGORY_MAP: Record<Country, AssetPrice> = {
    AT: {
        'org-one-plus': 53,
        org: 53,
        dlu: 56,
        ebike: 150,
        'pwr-one': 150,
        ekick: 112,
        escooter: 330,
    },
    BE: {
        'org-one-plus': 71,
        org: 71,
        dlu: 37,
        ebike: 150,
        'pwr-one': 150,
        ekick: 112,
        escooter: 330,
    },
    DK: {
        'org-one-plus': 400,
        org: 400,
        dlu: 420,
        ebike: 1115,
        'pwr-one': 1115,
        ekick: 830,
        escooter: 2500,
    },
    FR: {
        'org-one-plus': 53,
        org: 53,
        dlu: 56,
        ebike: 150,
        'pwr-one': 150,
        ekick: 112,
        escooter: 330,
    },
    DE: {
        'org-one-plus': 57,
        org: 57,
        dlu: 69,
        ebike: 150,
        'pwr-one': 150,
        ekick: 112,
        escooter: 330,
    },
    NL: {
        'org-one-plus': 55,
        org: 55,
        dlu: 46,
        ebike: 150,
        'pwr-one': 150,
        ekick: 112,
        escooter: 330,
    },
    IT: {
        'org-one-plus': 53,
        org: 53,
        dlu: 56,
        ebike: 150,
        'pwr-one': 150,
        ekick: 112,
        escooter: 330,
    },
    ES: {
        'org-one-plus': 53,
        org: 53,
        dlu: 56,
        ebike: 150,
        'pwr-one': 150,
        ekick: 112,
        escooter: 330,
    },
    GB: {
        'org-one-plus': 45,
        org: 45,
        dlu: 47,
        ebike: 130,
        'pwr-one': 130,
        ekick: 95,
        escooter: 280,
    },
};

export const getRevenueFromCountryAndBike = ({
    country,
    assetCategory,
}: {
    country: Country;
    assetCategory: AssetCategory;
}) => {
    return REVENUE_COUNTRY_ASSSET_CATEGORY_MAP[country][assetCategory];
};

interface Purchase {
    enrollment: EnrollmentInfo;
    subscription: SubscriptionType;
}

export default function purchaseConfirmationGTM({ enrollment, subscription }: Purchase) {
    const subscriptionCountry = subscription.country as Country;

    return {
        event: 'purchase',
        pageCountry: subscriptionCountry,
        ecommerce: {
            currencyCode: subscription.currencyISOCode,
            purchase: {
                actionField: {
                    id: enrollment.subscriptionId,
                    revenue: getRevenueFromCountryAndBike({
                        country: subscriptionCountry,
                        assetCategory: subscription.assetCategoryCode,
                    }),
                    tax: 0,
                    shipping: 0,
                },
                products: [
                    {
                        name: subscription.bikeType,
                        id: subscription.subscriptionCode,
                        price: getRevenueFromCountryAndBike({
                            country: subscriptionCountry,
                            assetCategory: subscription.assetCategoryCode,
                        }),
                        quantity: 1,
                        monthlyPrice: subscription.finalRate,
                        brand: 'Swapfiets',
                        category: `Bikes/${subscription.bikeType}`,
                        city: subscription.businessUnit,
                        membership: subscription.isFlexible ? 'flexible' : 'loyal',
                        student: subscription.isStudentOffer ? 'yes' : 'no',
                    },
                ],
            },
        },
    };
}

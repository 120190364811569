import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserDetails } from '../../shared/models/userdetails';
import { PopupServiceDirective } from '../../shared/popup-service/popup-service.directive';
import { ToastService } from '../../shared/toast-service/toast.service';
import { UserDetailsModelService } from '../services/user-details-model-service/user-details-model-service';
import { Toast } from '../../shared/toast-component/toast';
import { TranslateService } from '../../shared/translate-service/translate-service';
// Env
import { ReactiveComponent } from '../../shared/reactive-component/reactive.component';
import { ReferralService } from '../../shared/referral-service/referral.service';
import { shareReplay } from 'rxjs/operators';

@Component({
    templateUrl: './referral.component.html',
    styleUrls: ['./referral.component.scss'],
})
export class ReferralComponent extends ReactiveComponent implements OnInit {
    @ViewChild('conditions', { static: true }) conditionsEl: ElementRef;
    referrerCode: string;
    errorGettingCode: boolean;
    userDetails: UserDetails;

    constructor(
        private userDetailsModelService: UserDetailsModelService,
        private popupService: PopupServiceDirective,
        private toastService: ToastService,
        private referralService: ReferralService,
        private translateService: TranslateService
    ) {
        super();
    }

    ngOnInit() {
        this.reactiveXSubs.add(
            this.referralService
                .getRefferCode()
                .pipe(shareReplay(1))
                .subscribe(
                    ({ code }) => {
                        return (this.referrerCode = code);
                    },
                    (error) => {
                        this.errorGettingCode = true;
                    }
                )
        );

        this.userDetailsModelService.getModel().subscribe((userDetails: UserDetails) => {
            this.userDetails = userDetails;
        });
    }

    openModal() {
        this.popupService.showPopupEmail();
    }

    copyText(code) {
        const el = document.createElement('textarea');
        el.value = code;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        this.toastService.showToast(<Toast>{
            text: this.translateService.translate('referral.copied'),
            icon: 'check',
            time: 3000,
            style: 'success',
        });
    }

    scrollToConditions() {
        this.conditionsEl.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
}

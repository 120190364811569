import { Component } from '@angular/core';
import { EnvironmentService } from '../environment-service/environment.service';
import { AbstractPopupDirective } from '../abstract-popup/abstract-popup.directive';

@Component({
    templateUrl: './popup-registration-exists.component.html',
    styleUrls: ['./popup-registration-exists.component.scss'],
})
export class PopupRegistrationExistsComponent extends AbstractPopupDirective {
    constructor(public environmentService: EnvironmentService) {
        super();
    }
}
